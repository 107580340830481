import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap';
import { things } from '../../Componets/Images';
import DatePicker from 'react-datepicker';
import { ActionModal, FeedbackModal, GridCategories, OpenImageModal, ShareModal, TagsSection } from '../../Componets/Modal';
import { tstError, tstSuccess } from '../../Componets/HotToast';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CommonAPI } from '../../Componets/CommonAPI';
import { dec, enc } from '../../Componets/SecRes';
import { HTMLRender } from '../../Componets/HTMLRender';
import parse from 'html-react-parser';
import { dateFormat, openInNewTab } from '../../Componets/CommonFs';
import Carousel from 'react-multi-carousel';
import { CustomLeftArrow } from '../../Componets/CustomArrow';
import SignInModal from '../Auth/SignInModal';
import { Modal, Spin } from 'antd';
import RatingStars from '../../Componets/RatingStars';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../../Componets/Loader';
import { useLocalStorage } from 'react-fu-hooks';
import { svReceiverDetails } from '../../ContextRedux/Action';
import { io } from 'socket.io-client';
import Counter from '../Hotels/Counter';
import { useChatPopup } from '../../ContextRedux/ChatPopupContext';
import { Helmet } from 'react-helmet';
import { TiredOfSearching } from '../../Componets/Recommended';

const img_base_url = process.env.REACT_APP_IMG_BASE_URL;
const key = process.env.REACT_APP_KEY;
const socket_url = process.env.REACT_APP_SOCKET_URL;
// const socket_url = process.env.REACT_APP_SOCKET_LOCAL;

const responsive_8 = {
    desktop1: {
        breakpoint: { max: 2000, min: 1150 },
        items: 4,
        slidesToSlide: 4
    },
    desktop: {
        breakpoint: { max: 1150, min: 828 },
        items: 3,
        slidesToSlide: 3
    },
    mobile: {
        breakpoint: { max: 828, min: 560 },
        items: 2,
        slidesToSlide: 2
    },
    mobilex: {
        breakpoint: { max: 560, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};

const responsive = {
    desktop1: {
        breakpoint: { max: 2000, min: 1150 },
        items: 5,
        slidesToSlide: 4
    },
    desktop: {
        breakpoint: { max: 1150, min: 828 },
        items: 4,
        slidesToSlide: 3
    },
    mobile: {
        breakpoint: { max: 828, min: 560 },
        items: 3,
        slidesToSlide: 2
    },
    mobilex: {
        breakpoint: { max: 560, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};

const responsive_7 = {
    desktop1: {
        breakpoint: { max: 2000, min: 1150 },
        items: 7,
        slidesToSlide: 5
    },
    desktop: {
        breakpoint: { max: 1150, min: 828 },
        items: 5,
        slidesToSlide: 3
    },
    mobile: {
        breakpoint: { max: 828, min: 560 },
        items: 4,
        slidesToSlide: 2
    },
    mobilex: {
        breakpoint: { max: 560, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};

const responsive_pkg = {
    xdesktop1: {
        breakpoint: { max: 2560, min: 2000 },
        items: 11,
        slidesToSlide: 8
    },
    desktop1: {
        breakpoint: { max: 2000, min: 1200 },
        items: 9,
        slidesToSlide: 7
    },
    desktop: {
        breakpoint: { max: 1200, min: 1024 },
        items: 7,
        slidesToSlide: 5
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 5,
        slidesToSlide: 3
    },
    mobile: {
        breakpoint: { max: 767, min: 559 },
        items: 4,
        slidesToSlide: 3
    },
    xmobile: {
        breakpoint: { max: 559, min: 449 },
        items: 3,
        slidesToSlide: 2
    },
    xsmobile: {
        breakpoint: { max: 449, min: 0 },
        items: 3,
        slidesToSlide: 2
    },
};


function PkgDetails() {
    const { slug, id } = useParams();
    const navigate = useNavigate();
    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const url = window.location.href;


    const [open2, setOpen2] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [faqOpen, setFaqOpen] = useState(0);
    const [selected, setSelected] = useState(0);
    const [value, setValue] = useState('');
    const [email, setEmail] = useState('');
    const [vendorId, setVendorId] = useState('');
    const [num, setNum] = useState('');
    const [description, setDescription] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [inputDate, setInputDate] = useState('');
    const [isOpen, setIsOpen] = useState(0);
    const [isExpanded1, setIsExpanded1] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false);
    const [data, setData] = useState([]);
    const [tagsList, setTagsList] = useState([]);
    const [err, setErr] = useState({})
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [list2, setList2] = useState([])
    const [hideBtn, setHideBtn] = useState(false);
    const userDetails = useSelector(reducer => reducer.data.info)
    const [isShow, setIsShow] = useState(false);
    const [hotelId, setHotelId] = useLocalStorage('hotelType', '')
    const socketRef = useRef(null);
    const dispatch = useDispatch();
    const user_token = useSelector(reducer => reducer.data?.info?.tkn)
    const [open, setOpen] = useState(false);
    const [adultChild, setAdultChild] = useState({
        adults: 1,
        children: 0
    });
    const { isChatOpen, toggleChatPopup } = useChatPopup();
    const [renderUseEffect, setRenderUseEffect] = useState(false);
    const [switchTab, setSwitchTab] = useState('Inclusions');



    const months = [
        'October', 'November', 'December'
    ];

    // useEffect(() => {
    //     if (user_token) {
    //         socketRef.current = io(socket_url, {
    //             query: {
    //                 token: user_token
    //             }
    //         });
    //     }
    // }, [renderUseEffect])

    useEffect(() => {
        getDetails();
    }, [id]);

    const getDetails = () => {
        const data = {
            id: Number(id),
            slug: slug
        }

        const enc1 = enc(data, key);

        setIsLoading1(true)
        CommonAPI(enc1, 'POST', `packageDetails`, responseBack)
    }

    const responseBack = useCallback(res => {
        if (res.status === true) {
            const dec1 = dec(res?.data, key);
            setData(dec1)

            setVendorId(dec1?.details?.[0]?.vendor)
            setTagsList([])
            CommonAPI({}, 'GET', `reviewList?typeId=${dec1?.details[0]?._id}`, responseBack3)
            CommonAPI({}, 'GET', `topTagsList?type=country&typeId=${Number(dec1?.details?.[0]?.country.id) || ''}`, responseBack2)

        } else {
            navigate('/not-found', { replace: true });
        }
        setIsLoading1(false)
    })


    const responseBack3 = useCallback(res => {
        if (res.status === true) {
            const dt = res.data && res?.data;
            const dec1 = dec(dt, key);
            setList2(dec1?.data)

            // if (dec1?.packages == undefined || dec1?.packages == null) {
            //   setList2(dec1?.packages)
            // } else {
            //   setList2(prevData => {
            //     const newData = dec1?.packages?.filter(item => !prevData.some(existingItem => existingItem._id === item._id));
            //     return [...prevData, ...newData];
            //   });
            // }

            // if (dec1?.packages?.length < 10) {
            //   setLoadMore(false);
            // }
        } else {
            if (res.message == 'Invalid token') {
                setIsModalOpen(true)
            }
        }
        setIsLoading(false)
    })

    const responseBack2 = useCallback(res => {
        if (res.status === true) {
            const dec1 = dec(res?.data, key)
            setTagsList(dec1)
        }
    })


    const toggleFaq = (index, ty) => {
        {
            ty == 'faq' ?
                setFaqOpen(faqOpen === index ? -1 : index)
                :
                setIsOpen(isOpen === index ? -1 : index)
        }
    }

    const handleDate = (dt) => {
        const date = dateFormat(dt, 'dmy')
        setSelectedDate(date.replace(/\//g, '-'))
        setInputDate(dt)
    }

    const val = () => {
        const err = {};

        if (!value.trim()) {
            err.name = 'Enter valid name!'
        }
        if (!/^[^\s@]+@gmail\.com$/.test(email)) {
            err.mail = 'Invalid email format!'
        }

        if (num.trim().length < 10 || num.trim().length > 12) {
            err.num = 'Enter valid number!'
        }

        if (selectedDate == '') {
            err.date = 'Invalid date!'
        }

        setErr(err)
        return Object.keys(err).length == 0;
    }

    const details = data.details && data.details


    const handleSubmit = async (e, vId) => {
        e.preventDefault();

        const data = {
            name: value,
            email: email,
            phone: num,
            message: description,
            type: 'Package',
            type_id: details[0]?._id,
            travelerCount: Number(adultChild.adults) + Number(adultChild.children),
            adult: adultChild.adults,
            child: adultChild.children || '0',
            travelDate: selectedDate,
            vendorId: vendorId?._id
        }
        const enc1 = enc(data, key)

        if (val()) {
            setIsLoading(true)
            CommonAPI(enc1, 'POST', 'requestCall', responseBack1)
        }
    };

    const responseBack1 = useCallback(res => {
        if (res.status === true) {
            setValue('')
            setEmail('')
            setNum('')
            setDescription('')
            setAdultChild({ adults: 1, children: 0 })
            setSelectedDate('')
            setInputDate('')
            setData(prev => ({
                ...prev,
                details: prev.details.map((item, index) =>
                    index === 0 ? { ...item, status: 1 } : item // Update status for the first item (index 0)
                )
            }));

            const msg = { receiver_id: vendorId?._id, type: 'Package', sender_id: userDetails?.user?._id }
            const enc1 = enc(msg, key)
            socketRef.current.emit('send_callback', enc1, (res) => {
            });

            tstSuccess('Callback request sent successfully')
        } else {
            if (res.message == 'Invalid token') {
                setIsModalOpen(true)
            }
        }
        setIsLoading(false)
    })


    const today = new Date();
    const minDate = new Date();
    minDate.setDate(today.getDate() + 1);

    const data2 = data?.details?.[0]
    const lowPrice = data2?.discountPrice
    const final = (lowPrice == null || lowPrice == 0) ? data2?.price : lowPrice

    const reloadPage = (updated, type) => {
        if (type == 'update') {
            setList2(prev =>
                prev.map(item =>
                    item._id === updated._id ?
                        { ...item, message: updated.message, rating: updated.rating } : item
                )
            )
        } else if (type == 'add') {
            list2.push(
                updated
            )
        } else {
            const filter = list2.filter(item => item._id !== updated)
            setList2(filter)
        }
    }

    const goToPkgList = (category, type) => {
        const pkgHome = localStorage.getItem('searchParams');
        const pkgHomeParams = JSON.parse(pkgHome)

        if (type == 'category') {

            openInNewTab(`/packages/${category}`, pkgHomeParams)
        } else {
            openInNewTab('/packages/list', pkgHomeParams)
        }

    }

    const handleSave = (value, type) => {
        const newWindow = window.open(`/hotels`, '_blank')
        if (newWindow) { newWindow.opener = null; newWindow.rel = 'noopener noreferrer'; }
        setHotelId(value);
    };


    const inclusions = data?.details && data?.details[0]?.inclusions?.filter((item) => item !== '')
    const exclusions = data?.details && data?.details[0]?.exclusions?.filter(item => item !== '')


    const contentRef = useRef(null);
    const [isTruncated, setIsTruncated] = useState(true);


    useEffect(() => {

        const checkTruncation = () => {
            if (contentRef.current) {
                const isOverflowing =
                    contentRef.current.scrollHeight > contentRef.current.clientHeight;
                setIsTruncated(isOverflowing);
            }
        };

        checkTruncation();
        window.addEventListener('resize', checkTruncation); // Check on window resize
        return () => window.removeEventListener('resize', checkTruncation); // Cleanup listener
    }, []);


    const handleOk = (ty) => {
        setTimeout(() => {
            setOpen(false);
        }, 1000);
    };

    const vendor = data?.details?.[0]?.vendor

    return (
        <div className='d-flex flex-column align-items-center mt-12 pt-3 p-0 pb-5 bg-gray'>
            {!isLoading1 && data?.details?.[0]?.image ? (
                <>
                    <div className='w_95 mt-4 d-flex align-items-center flex-column w-100'>

                        <div className=" position-relative bg-primary1 rd_10 w-100">
                            <img className="rd_10 w-100 h_400px h-250px-sm" src={`${img_base_url + data?.details?.[0]?.image}`} alt={details[0]?.name} />

                            <div className='position-absolute top-0 h-100 flex-column rd_6  w-100'>
                                <div className='bg1-success d-flex justify-content-end'>
                                    <div className=' pt-0 mt-3 z-1 me-3 bg_thm pointer h_35px d-inline-flex px-3 rd_6 justify-content-center align-items-center' onClick={() => setIsModalOpen1(true)}>
                                        <p className='Rubik-Regular fs_12 text-white m-0 fa-fade'><i className="fa-solid fa-share-nodes me-1 fs_13"></i> Share</p>
                                    </div>
                                </div>

                                {/* text at image */}
                                <div className='bg1-warning d_flex_align_justify_center h-75'>
                                    <div className='w_86 h_200px rd_10 d_flex_align_justify_center flex-column' style={{ background: 'linear-gradient(to top, rgba(0,0,0,0.30), rgba(0,0,0,0.30)) ' }}>
                                        <span className='Inter-Bold text-center fs_26 fs-sm-22 text-white text-capitalize px-1 text-shadow mt-20px--xs'>{details[0]?.name}</span>

                                        <div className={`px-3 w-85p w-97p--vs-g scrollable-h scrollable position-relative rd_10 d-flex align-items-start  mt-4 ${details?.[0]?.facilities?.length >= 5}`} >
                                            <div className='w-100 '>
                                                <Carousel
                                                    swipeable={true}
                                                    draggable={true}
                                                    responsive={responsive_pkg}
                                                    ssr={true}
                                                    infinite
                                                    keyBoardControl={true}
                                                    customTransition="transform 1000ms ease-in-out"
                                                    containerClass="carousel-container d-flex align-center justify-center"
                                                    itemClass="carousel-item1 "
                                                    customLeftArrow={
                                                        <CustomLeftArrow
                                                            direction="left"
                                                        />
                                                    }
                                                    customRightArrow={
                                                        <CustomLeftArrow
                                                            direction="right"
                                                        />
                                                    }
                                                    removeArrowOnDeviceType={["desktop1", "desktop",]}
                                                    dotListClass="custom-dot-list-style"
                                                >
                                                    {details && details?.[0]?.facilities.map((img) => (
                                                        <div key={img._id} className='Source_Regular w_100px mb-1 mt-2-5 flex-shrink-0 px-2 d-flex align-items-center flex-column'>
                                                            <div className='rounded-circle h-70px w-70px h-55px--sm-g w-55px--sm-g d-flex justify-content-center align-items-center bg-white shadow-slowclean1' >
                                                                <img className="hw_35px hw_30px-sm" src={`${img_base_url + img.image}`} alt={img.name} />
                                                            </div>
                                                            <div className='m-0 mt-1 text-capitalize fs_11 text-white text-wrap text-center'>{img.name}</div>
                                                        </div>
                                                    )
                                                    )}
                                                </Carousel>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Helmet>
                                    <title>{details[0]?.name} | Travellinglark</title>
                                </Helmet>
                            </div>
                        </div>

                        <Row className='w-100 bg-warning1'>
                            {data?.details && data?.details?.length > 0 && (
                                data?.details?.map((item) => {


                                    return (
                                        <Col key={item._id} lg={8} className="bg-warning1">
                                            <div className='flex-wrap d-flex align-items-center bg-warning1 mt-4-5 Rubik-Regular'>
                                                <p className='m-0 Source_Medium mb-2 d-flex align-items-center d-flex text_thmdark bg_thmlightdark px-3 p-1 rd_6 fs_12 mr_10'>
                                                    <i className="fa-solid fa-earth-americas fs_12 me-1"></i>
                                                    {item?.country?.name}
                                                </p>
                                                <p className='m-0 Source_Medium mb-2 d-flex align-items-center d-flex text_thmdark bg_thmlightdark px-3 p-1 rd_6 fs_12 mr_10'>
                                                    <i className="fa-regular fa-clock fs_12 me-1"></i>
                                                    {item?.duration}
                                                </p>
                                                <p className='m-0 Source_Medium mb-2 d-flex align-items-center d-flex scroll-h hide-scroll-indi text_thmdark bg_thmlightdark px-2 rd_6 fs_12 mr_10'>
                                                    <i className="far fa-list-alt fs_12 me-1"></i>
                                                    <p className='m-0 text-nowrap flex-wrap Source_Medium d-flex text_thmdark bg_thmlightdark px-2 p-1 rd_6 fs_12'>
                                                        {item?.category.map(item => item?.name).join(', ')}
                                                    </p>
                                                </p>
                                            </div>

                                            <h1 className='Inter-Bold fs-22px fs-20px--sm text-gray text-capitalize mt-50px text-center-sm'>{details[0]?.name}</h1>


                                            {item?.about && (
                                                <div className='p-3 rd_6 bg-white mt-4 w-100 overflow-hidden'>
                                                    <div className={`content-wrapper Source_Regular  ${isExpanded1 ? '' : 'mini-line-1 ten'}`} ref={contentRef}>
                                                        <HTMLRender htmlContent={item?.about || ''} />
                                                    </div>
                                                    {isTruncated && (
                                                        <div className='text_thmdark pointer fs_12 Inter-Medium mt-1 text-end w-100' onClick={() => setIsExpanded1(!isExpanded1)}>{isExpanded1 ? ' See Less' : ' See More'}</div>
                                                    )}
                                                </div>
                                            )}


                                            {/* Itinerary */}
                                            {item?.itinerary?.length > 0 && (
                                                <>
                                                    <p className='m-0 fw-bold fs_18 mt-5 mb-1'>Itinerary</p>
                                                    {item?.itinerary?.map((it, index) => {
                                                        const parsedHtml = parse(it);
                                                        return (
                                                            <div key={index} className={`card mt-2 pointer rounded-0 rd_tl_tr_6 ${isOpen === index ? 'border_thm' : ''}`}>
                                                                <div className={`card-header d-flex h_40px align-items-center ${isOpen === index ? 'bg-gradient-thm text-white' : 'bg-white'}`} onClick={() => toggleFaq(index, 'it')}>
                                                                    <div className="border-0 bg-transparent flex-grow-1 fs_13 Rubik-Medium">
                                                                        Day {index + 1}
                                                                    </div>
                                                                    {isOpen === index ?
                                                                        <i className="fa fa-angle-up fs_14 text-white"></i> :
                                                                        <i className="fa fa-angle-down fs_14 text_gray2"></i>
                                                                    }
                                                                </div>
                                                                <div className={`collapse-content ${isOpen === index ? 'expanded' : ''}`}>
                                                                    <p className="card-body m-0 Rubik-Regular text-secondary fs_13">
                                                                        {parsedHtml}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </>
                                            )}

                                            {/* Tabs Summary */}
                                            <div className='bg-warning1 d-flex mt-4 scrollable overflow-x-auto white-space'>
                                                {/* {Summary?.slice(0,)?.map((it, index) => (
                                                        <div
                                                            key={index}
                                                            onClick={() => setSwitchTab(it)}
                                                            className={`mt-2 pointer p-1 bg_graylg mx-1 px-3 d-flex align-items-center rd_tl_tr_6 ${it == switchTab ? 'Source_SemiBold current-month text-white bg-gradient-thm' : 'Sansation_Regular text_gray2 fs_14'}`}
                                                        >
                                                            {it}
                                                        </div>
                                                    ))} */}
                                                {/* {inclusions?.length > 0 && ( */}
                                                <div
                                                    onClick={() => setSwitchTab('Inclusions')}
                                                    className={`mt-2 pointer p-1 bg_graylg mx-1 px-3 d-flex align-items-center rd_tl_tr_6 ${'Inclusions' == switchTab ? 'Source_SemiBold current-month text-white bg-gradient-thm' : 'Sansation_Regular text_gray2 fs_14'}`}
                                                >
                                                    Inclusions
                                                </div>
                                                {/* )} */}

                                                {/* {exclusions?.length > 0 && ( */}

                                                <div
                                                    onClick={() => setSwitchTab('Exclusions')}
                                                    className={`mt-2 pointer p-1 bg_graylg mx-1 px-3 d-flex align-items-center rd_tl_tr_6 ${'Exclusions' == switchTab ? 'Source_SemiBold current-month text-white bg-gradient-thm' : 'Sansation_Regular text_gray2 fs_14'}`}
                                                >
                                                    Exclusions
                                                </div>
                                                {/* )} */}
                                            </div>

                                            <div className='bg-white br_thm border_gray pt-2 p-3 rd_6'>
                                                {switchTab == 'Inclusions' ? (
                                                    <>
                                                        {inclusions.map((name, i) => (
                                                            <p key={i} className='Source_Regular fs_14 m-0 mt-2 text-capitalize d-flex align-items-center'><i className='bi bi-check2-circle pt-1 text-success fs_15 mr_10'></i>{name}</p>
                                                        ))}

                                                    </>
                                                ) : switchTab == 'Exclusions' ? (
                                                    <>
                                                        {exclusions.map((name, i) => (
                                                            <p key={i} className='Source_Regular fs_14 m-0 mt-2 text-capitalize'><i className="fa-regular fa-circle-xmark text-danger mr_10"></i>{name}</p>
                                                        ))}
                                                    </>
                                                ) : switchTab == 'Batches' ? (
                                                    <>
                                                        <p className='fw-bold fs_14 text_20 m-0'>Batches</p>
                                                        <div className='bg-warning1 d-flex flex-wrap gap-2 mt-3'>
                                                            {months?.map((it, i) => (
                                                                <p key={i} className={`m-0 border_gray px-2-5 fs_14 p-1-5 rd_50 Source_Regular pointer ${i == selected ? 'bg_thm text-white' : 'text_gray'}`} onClick={() => setSelected(it)}>{it}</p>
                                                            ))}
                                                        </div>

                                                        <div className='bg-warning1 d-flex flex-wrap gap-2 mt-3'>
                                                            {[1, 2, 3]?.map((it, i) => (
                                                                <p className={`m-0 border_gray text_gray px-2-5 fs_13 p-1-5 rd_50 Source_Regular bg_lightblue`}>3rd Feb (Saturday Night) to 11th Feb (Sunday Morning) 2024</p>
                                                            ))}
                                                        </div>
                                                    </>
                                                ) : switchTab == 'Other Details' ? (
                                                    <>
                                                        <div className='bg-warning1 d-flex flex-wrap gap-3'>

                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <p className='fw-bold fs_14 text_20'>Things of Pack</p>
                                                        <div className='bg-warning1 d-flex gap-2 flex-wrap mt-3'>
                                                            {things?.map((item, index) => (
                                                                <li key={index} className='Source_Regular fs_12 text_gray'>{item}</li>
                                                            ))}
                                                        </div>
                                                    </>
                                                )}
                                            </div>

                                            {/* FAQ */}
                                            {item?.faq?.length > 0 && (
                                                <>
                                                    <p className='m-0 fw-bold fs_18 mt-5 '>FAQ</p>
                                                    {
                                                        item?.faq.map((it, i) => (
                                                            <div key={i} className={`card mt-2 pointer rounded-0 rd_tl_tr_6 ${faqOpen === i ? 'border_thm' : ''}`}>
                                                                <div className={`card-header d-flex align-items-center h_40px ${faqOpen === i ? 'bg-gradient-thm text-white' : 'bg-white text_gray'}`} onClick={() => toggleFaq(i, 'faq')}>
                                                                    <div className="border-0 bg-transparent flex-grow-1 fs_13 Rubik-Medium">
                                                                        {it.title}
                                                                    </div>
                                                                    {faqOpen === i ?
                                                                        <i className="fa fa-angle-up fs_14 text-white"></i> :
                                                                        <i className="fa fa-angle-down fs_14 text_gray2"></i>
                                                                    }
                                                                </div>
                                                                <div className={`collapse-content ${faqOpen === i ? 'expanded' : ''}`}>
                                                                    <p className="card-body m-0 Rubik-Regular fs_13 text-muted">
                                                                        {it.description}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </>
                                            )}
                                        </Col>
                                    )
                                })
                            )}
                            <Col lg={4} className=''>
                                <div className='rd_6 bg-white p-3 px-3 mt-5 shadow-slowclean'>
                                    <div className='Rubik-Medium fs_15'>Organized By</div>

                                    <div className='d-flex align-items-center mt-3'>
                                        <img src={vendor?.image ? img_base_url + vendor?.image : '/icons/blank_profile.png'} className='img-fluid hw_50px' />
                                        <div className='ms-3'>
                                            <div className='Rubik-Regular fs_15'>{vendor?.business_name}</div>
                                            {/* <div className='Rubik-Light fs_13'>Member Since </div> */}
                                            <div className='Rubik-Light fs_13 text-capitalize'>{vendor?.services?.join(', ')}</div>
                                        </div>
                                    </div>

                                    <div className='d-flex align-center justift-between'>
                                        <Link className='mt-3 bg-gradient-thm flex-grow-1 h_35px d-inline-flex px-3 mr_10 rd_6 justify-content-center align-items-center' to={`/profile/${vendor?.slug}/${vendor?.id}`}>
                                            <div className='Rubik-Regular fs_12 text-white pointer m-0 text-decoration-none'>
                                                <i className="fas fa-user fa-fade me-1 fs_13"></i> View Profile
                                            </div>
                                        </Link>
                                        {/* <div className='mt-3 bg-gradient-danger h_35px d-inline-flex flex-grow-1 pointer px-3 mr_10 rd_6 justify-content-center align-items-center' onClick={() => handleStartChat(vendorId?.business_name, vendorId?._id)}>
                                            <p className='Rubik-Regular fs_12 text-white  m-0'><i className="fa-regular fa-comment-dots fa-fade me-1 fs_13"></i> Chat Now</p>
                                        </div> */}
                                    </div>
                                </div>


                                <form onSubmit={handleSubmit} className='rd_6 bg-white p-3 px-4 mt-5 shadow-slowclean'>
                                    <div className='d-flex justify-between'>

                                        <div className='text-center w-100'>
                                            <p className='m-0 fw-bold text-muted fs-16px'>Get Callback</p>
                                            <div className='d-flex align-center justify-center mt-2 w-100'>
                                                <p className='Rubik-Regular fs-10px text_gray2 mini-line-1 m-0px mr-10px'>Starting Price</p>
                                                <p className='m-0 Rubik-Medium text-secondary text_thm text-center fs_14'>{details[0]?.currency?.code}{final}</p>
                                                {lowPrice != null && lowPrice > 0 && (
                                                    <p className='Rubik-Regular m-0 ms-2 fs_12 TxtCut text-danger toext-secondary'>{details[0]?.currency?.code}{data2?.price}</p>
                                                )}
                                            </div>
                                        </div>

                                    </div>

                                    <div className="form-group flex-grow-1 mt-3">
                                        <input type="text" className={`form-control fs_13 h_38px rd_10 border_gray Rubik-Regular text_dark_medium ${err.name ? 'mb-1' : 'mb-3'}`} placeholder="Enter Your Name" value={value} onChange={(event) => setValue(event.target.value)} />
                                        {err.name && <p className='fs_13 mb-2 pt-0 mt-0 text-danger'>{err.name}</p>}
                                    </div>

                                    <div className="form-group flex-grow-1 mt-3">
                                        <input type="email" className={`form-control fs_13 h_38px rd_10 border_gray Rubik-Regular text_dark_medium ${err.mail ? 'mb-1' : 'mb-3'}`} placeholder="Enter Your Email" value={email} onChange={(event) => setEmail(event.target.value)} />
                                        {err.mail && <p className='fs_13 mb-2 pt-0 mt-0 text-danger'>{err.mail}</p>}
                                    </div>

                                    <Row className=''>
                                        <Col xs={12} sm={5} md={12} lg={6} className="pe-2 pr-15px--sm">
                                            <input type="number" className={`form-control fs_13 h_38px rd_10 border_gray Rubik-Regular text_dark_medium ${err.num ? 'mb-1' : 'mb-3'}`} placeholder="Phone number" value={num} onChange={(event) => setNum(event.target.value)} />
                                            {err.num && <p className='fs_13 mb-2 pt-0 mt-0 text-danger'>{err.num}</p>}
                                        </Col>

                                        <Col xs={12} sm={6} md={12} lg={6} className="bg-warning1 mb-md-3 ps-2 pl-15px--sm">
                                            <div className='bg-primary1'>
                                                <DatePicker
                                                    selected={inputDate}
                                                    placeholderText='Travel Date'
                                                    className='date-picker w-100 border_gray rd_10 outline-none text_gray fs_14 p-1 ps-2 h_38px m-0'
                                                    onChange={handleDate}
                                                    minDate={minDate}
                                                    dateFormat='dd/MM/yy'
                                                />
                                            </div>
                                            {err.date && <p className='fs_13 mb-2 pt-0 mt-0 text-danger'>{err.date}</p>}
                                        </Col>
                                    </Row>

                                    <div className="form-group flex-grow-1 mt-1">
                                        <div className='form-control fs_13 h_38px rd_10 border_gray d-flex align-items-center pointer text_dark_medium' onClick={() => setOpen(true)}>{adultChild.adults} Adults • {adultChild.children} Childrens</div>
                                        {err.adult && <p className='fs_13 mb-2 pt-0 mt-0 text-danger'>{err.adult}</p>}
                                    </div>

                                    <div className="form-group mb-1">
                                        <textarea name="description" placeholder='message' rows={4} className="form-control fs_14 h_38px rd_10 border_gray Rubik-Regular text_dark_medium" value={description} onChange={(event) => setDescription(event.target.value)} />
                                    </div>

                                    <button type='submit' className='bg-gradient-thm btn w-100 mt-3 d-flex flex-grow-1 justify-content-center align-items-center rd_6 text-white pointer h_40px fs_14 Source_Medium p-1-5 px-2' disabled={isLoading}>
                                        {isLoading ? <Spinner size='sm' /> : 'Request Callback'}
                                    </button>
                                </form>

                                <TiredOfSearching />
                            </Col>
                        </Row>

                        {details && details?.[0]?.gallery?.length > 0 && (
                            <>
                                <div className='Rubik-SemiBold fs_18 mt-5 ms-2 text-start w-100'>Gallery</div>
                                <div className='w-100'>
                                    <Carousel
                                        swipeable={true}
                                        draggable={true}
                                        responsive={responsive_8}
                                        ssr={true}
                                        autoPlay
                                        autoPlaySpeed={2500}
                                        keyBoardControl={true}
                                        customTransition="transform 1000ms ease-in-out"
                                        containerClass="carousel-container"
                                        itemClass="carousel-item1"
                                        customLeftArrow={
                                            <CustomLeftArrow
                                                direction="left"
                                            />
                                        }
                                        customRightArrow={
                                            <CustomLeftArrow
                                                direction="right"
                                            />
                                        }
                                        dotListClass="custom-dot-list-style"
                                    >
                                        {details[0]?.gallery.map((img, index) => {
                                            return (
                                                <div className={`h_200px h-250px-sm border_white br-2 mt-3 mb-1 rd_6 ms-2 me-2 pointer w-xxs-90 bg-white ${index === 0 ? 'ms-0px' : ''} `}>
                                                    <img src={`${img_base_url + img}`} alt='Package Gallery Images' className='h-100  w-100 rd_4'
                                                        onClick={() => {
                                                            setSelectedImage(img)
                                                            setOpen2(true)
                                                        }} />
                                                </div>
                                            )
                                        })}
                                    </Carousel>
                                </div>
                            </>
                        )}

                        {/* Reviews */}
                        <div className='d-flex align-items-center w-100 justify-content-between'>
                            <div className='Rubik-Medium text-start1 mt-5 ms-2 mb-2'>Reviews</div>
                            {list2 && list2.length > 0 && (
                                !hideBtn &&
                                details[0]?.leadStatus == 1 && (
                                    details[0]?.reviewStatus == 0 &&
                                    <div className='mt-3 bg-gradient-thm h_35px d-inline-flex px-3 mr_10 rd_6 justify-content-center align-items-center' onClick={() => setOpen3(!open3)}>
                                        <p className='Rubik-Regular fs_12 text-white pointer m-0'><i className="fa-regular fa-star me-1"></i> Submit Review</p>
                                    </div>
                                )
                            )}

                        </div>

                        {list2 && list2.length > 0 ? (
                            <>
                                <Row className='w-100 mt-2 '>
                                    {list2 && list2.map((item, i) => {
                                        const user = item?.userId
                                        const fName = user?.first_name || ''
                                        const lName = user?.last_name || ''

                                        const date = dateFormat(item.createdAt, 'dmy')
                                        const sendTo = {
                                            ...item,
                                            detailsId: details && details[0]?._id,
                                            vendorId: vendorId?._id
                                        }

                                        const dots = lName && lName.length > 7 ? '...' : ''

                                        return (
                                            <Col xs={12} sm={12} md={6} lg={4} xl={4} className='bg-warning1'>
                                                <div className='bg-white shadow-slowclean rd_4 p-3 bg-warning1 mb-4'>
                                                    <div className='d-flex align-items-center '>
                                                        <img src={`/icons/user.png`} alt='User Image' className='img-fluid mty-image hw_35px mr_10' />
                                                        <div className='w-100'>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='Rubik-Medium fs_15 flex-grow-1 text-capitalize'>{fName} {lName.slice(0, 7)}{dots} <p className='m-0 Source_Regular fs_13 text-secondary d-inline-block'>({user?.countryId?.name})</p></div>
                                                                {userDetails?.user?._id == user._id && (
                                                                    <ActionModal isVisible={isShow} onClose={setIsShow} data={sendTo} callback={setHideBtn} reload={reloadPage} type={'review'} />
                                                                )}
                                                            </div>
                                                            <div className='d-flex align-items-center bg1-warning mt-1'>
                                                                <RatingStars rating={item.rating} />
                                                                <div className='flex-grow-1' />
                                                                <div className='Source_Regular fs_14 text-end text-secondary d-none-on-xs'>{date}</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='border_gray bg-light p-2 mt-3 rd_6'>
                                                        <div className='Source_Regular fs_14'>{item.message}</div>
                                                    </div>
                                                    <div className='Source_Regular fs_14 mt-2 text-end text-secondary d-show-on-xs'>{date}</div>
                                                </div>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </>
                        ) : (
                            <div className={`tab-pane bg-warning1 mb-2 d-flex align-items-center flex-column`}>
                                <img src='/images/not_data.webp' alt='Review Not Found' className='img-fluid h_180px' />
                                <p className='Source_Medium text_gray mr_10'>Reviews not found</p>

                                {!hideBtn &&
                                    details[0]?.leadStatus == 1 && (
                                        details[0]?.reviewStatus == 0 &&
                                        <div className='mt-1 bg-gradient-thm h_35px d-inline-flex px-3 rd_6 justify-content-center align-items-center' onClick={() => setOpen3(!open3)}>
                                            <p className='Rubik-Regular fs_12 text-white pointer m-0'><i className="fa-regular fa-star me-1"></i> Submit Review</p>
                                        </div>
                                    )
                                }
                            </div>
                        )}

                        {tagsList && tagsList?.locations?.length > 0 && (
                            <>
                                <h2 className="mb-2 Rubik-SemiBold fs_17 mb-4 ms-1 mt-5 w-100">Popular Locations in {details[0]?.country?.name}</h2>
                                <div className='w-100 mt-1'>
                                    {isLoading1 ? (
                                        <Loader type={'country'} />
                                    ) : (
                                        <Carousel
                                            swipeable={true}
                                            draggable={true}
                                            responsive={responsive}
                                            ssr={true}
                                            autoPlay
                                            infinite
                                            autoPlaySpeed={2500}
                                            keyBoardControl={true}
                                            customTransition="transform 1000ms ease-in-out"
                                            containerClass="carousel-container"
                                            itemClass="carousel-item1"
                                            customLeftArrow={
                                                <CustomLeftArrow
                                                    direction="left"
                                                    mt={'mt-30px'}
                                                />
                                            }
                                            customRightArrow={
                                                <CustomLeftArrow
                                                    direction="right"
                                                    mt={'mt-30px'}
                                                />
                                            }
                                            dotListClass="custom-dot-list-style"
                                        >
                                            {tagsList?.locations?.map((item, index) => {
                                                return (
                                                    <div key={item._id} className="mb-4 position-relative bg-white rd_30 mx-2-5 ">
                                                        <Link to={`/trip/${item.slug}/${item.id}`} target='_blank' rel="noopener noreferrer" className='text-decoration-none w-100'>
                                                            <img src={img_base_url + item.image} alt={item.name} className='rd_15 w-100 h_250px h-xxs-200px pointer' />
                                                            <div className='rd_15 p-3 pb-2-5 h-100 w-100 hoverthis d-flex position-absolute flex-column align-items-center justify-content-end pointer' style={{
                                                                top: 0,
                                                                background: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,0),rgba(255,255,255,0), rgba(41, 38, 38,0.9)) ',
                                                            }}>
                                                                <div className='Inter-SemiBold fs_15 text-white text-capitalize px-1 text-center'>{item.name}</div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                )
                                            })}

                                        </Carousel>
                                    )}
                                </div>
                            </>
                        )}

                        {/* <div className='Inter-Bold fs_18 mb-2 ms-2 flex-grow-1 mt-4 fs-xs-16'>Explore Packages by Theme</div>

                        {isLoading1 ? (
                            <Loader type={'category'} mt={'mt-2'} />
                        ) : (
                            <GridCategories handleChange={goToPkgList} tripCate={data && data.category || []} />
                        )} */}

                        {/* {data && data?.hoteltype.length && (
                            <>
                                <div className='Inter-Bold fs_16 flex-grow-1 mt-3 fs-xs-14 ms-2 w-100'>Looking for Hotels</div>

                                <div className='w-100 mt-4'>
                                    {isLoading1 ? (
                                        <Loader type={'country'} />
                                    ) : (
                                        <Carousel
                                            swipeable={true}
                                            draggable={true}
                                            responsive={responsive_7}
                                            ssr={true}
                                            autoPlay
                                            infinite
                                            autoPlaySpeed={2500}
                                            keyBoardControl={true}
                                            customTransition="transform 1000ms ease-in-out"
                                            containerClass="carousel-container"
                                            itemClass="carousel-item1"
                                            customLeftArrow={
                                                <CustomLeftArrow
                                                    direction="left"
                                                    mt={'mt-30px'}
                                                />
                                            }
                                            customRightArrow={
                                                <CustomLeftArrow
                                                    direction="right"
                                                    mt={'mt-30px'}
                                                />
                                            }
                                            dotListClass="custom-dot-list-style"
                                        >
                                            {data && data?.hoteltype?.map((item, index) => {

                                                return (
                                                    <div key={item._id} className="mb-4 position-relative bg-white rd_30 mx-2-5 " onClick={() => handleSave(item._id, '')}>
                                                        <img src={img_base_url + item.image} alt={item.name} className='rd_15 w-100 h_180px h-xxs-200px pointer' />
                                                        <div className='rd_15 p-3 pb-2-5 h-100 w-100 hoverthis d-flex position-absolute flex-column align-items-center justify-content-end pointer' style={{
                                                            top: 0,
                                                            background: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,0),rgba(255,255,255,0), rgba(41, 38, 38,0.9)) ',
                                                        }}>
                                                            <div className='NunitoSans-Medium fs_15 text-white text-capitalize px-1 text-center'>{item.name}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })}

                                        </Carousel>
                                    )}
                                </div>
                            </>
                        )} */}
                    </div>

                    {/* ===========================Tags section================================ */}
                    <TagsSection tags={tagsList} cName={details[0]?.country?.name} hideIs={'location'} />
                </>
            ) : (
                <div className='d-flex align-items-center justify-content-center mb-4 vh-100 w-100' style={{ background: 'rgba(0,0,0,0.2)' }}>
                    <Spin className='bg-white rd_10 hw_80px d-flex align-items-center justify-content-center thm-spin' />
                </div>
            )}


            <Modal
                title="Select adult and child"
                className='Source_Medium text_gray w-xs-auto mx-xs-4'
                styles={{
                    body: {
                        padding: '0 20px', height: 'auto'
                    },
                    header: {
                        padding: '15px 0px 0 15px',
                    },
                    footer: {
                        padding: '10px'
                    }
                }}
                open={open}
                onOk={() => { handleOk(); }}
                onCancel={() => setOpen(false)}
            >
                <Counter details={adultChild} setDetails={setAdultChild} type={'package'} />
            </Modal>

            {/* Modal Sigin */}
            <SignInModal isOpen={isModalOpen} onClose={setIsModalOpen} />

            {/* For Submit Feedback */}
            <FeedbackModal isVisible={open3} onClose={setOpen3} type={'package'} id={details && details[0]?._id} vendorId={vendorId?._id} callback={setHideBtn} reload={reloadPage} />

            <OpenImageModal isVisible={open2} onClose={setOpen2} img={selectedImage} />
            {/* <MessagingPopup openPopup={isModalOpen2} closePopup={setIsModalOpen2} type={'query'} /> */}

            <ShareModal url={url} isVisible={isModalOpen1} onClose={setIsModalOpen1} />

        </div>
    )
}

export default PkgDetails