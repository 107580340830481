import React, { useContext, useState } from 'react';
import { Offcanvas, Nav } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import SignInModal from '../Auth/SignInModal';
import { MemContext } from '../../ContextRedux/Context';
import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { googleLogout } from '@react-oauth/google';
import { svChatCountStatus, svInfo, svModalStatus, svMsgId, svReceiverDetails, svTripCate } from '../../ContextRedux/Action';

const vendor_url = process.env.REACT_APP_VENDOR_URL;
const vendor_signup_url = process.env.REACT_APP_VENDOR__SIGNUP_URL;

const MenuPage = ({ show, handleClose }) => {
  const user1 = useSelector(state => state.data.info)
  const { isLoading, unId, logout } = useContext(MemContext);
  const dispatch = useDispatch();
  const activeWindow = window.location.pathname
  const [redirectionUrl, setRedirectionUrl] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const user = useSelector(state => state.data.info.user)

  const openModal = () => {
    setRedirectionUrl('/post-travel')

    dispatch(svModalStatus({ status: true }))
    setIsModalOpen(true);
    // handleClose()
  }

  const closeModal = () => {
    dispatch(svModalStatus({ status: false }))

    setIsModalOpen(false);
  }

  const outBro = async () => {
    try {
      dispatch(svInfo({}))
      dispatch(svTripCate([]))
      dispatch(svMsgId({}))
      dispatch(svReceiverDetails({}))
      dispatch(svChatCountStatus(''));

      await logout()
      await googleLogout()
      window.location.href = '/';
    } catch (error) {
    }
  }

  const typeIs = (!user1 && user1 == null || user1?.user?.role == 'vendor')
  const serv = user1 && user1?.user?.services


  const Links = [
    {
      id: 0,
      title: 'Home',
      Link: '/',
      activeWindow: activeWindow === '/',
      icon: 'bi bi-house'
    },
    {
      id: 1,
      title: 'Post Your Travel',
      Link: '/post-travel',
      activeWindow: activeWindow.startsWith('/post'),
      icon: 'bi bi-pencil-square'
    },
    {
      id: 2,
      title: 'Travel Leads',
      Link: '/travel-leads',
      activeWindow: activeWindow.startsWith('/travel'),
      icon: 'bi bi-luggage'
    }
  ]


  const Links1 = [
    {
      id: 1,
      title: 'Profile Section',
      Link: '/profile',
      icon: 'bi bi-person'
    },
    {
      id: 2,
      title: 'My Travels',
      Link: '/my-travels',
      icon: 'bi bi-passport'
    },
    {
      id: 3,
      title: 'My Offers',
      Link: '/my-proposals',
      icon: 'bi bi-list-check'
    }
  ]

  return (
    <Offcanvas show={show} className='offcanvas-parent-auth' onHide={handleClose} placement="end">
      <div className='bg-primary1 h-100 d-flex flex-column align-items-centerpt-3 p-0px pb-15px px-30px position-relative scroll-h'>

        <div className="w-100p h-100 mt-4 d-flex align-items-start flex-column w-10 position-relative">
          <div className={`w-100 scroll-h ${unId !== null ? 'h-87p mb-3' : 'h-80p'}`}>
            {unId !== null &&
              <div className='d-flex rd_6 mb-30px'>
                <div className='border_gray bg-warning1 br-2 h-60px w-60px rd_50 p-1'>
                  <img src='/icons/blank_profile.png' alt='User Profile' className='rd_50 img-fluid' />
                </div>
                <div className='d-flex justify-content-center flex-column ms-3'>
                  <p className='NunitoSans-SemiBold fs-16px m-0 text-capitalize min-line'>{user && user.first_name || ''} {user?.last_name || ''}</p>
                  <p className='Source_Regular text_gray2 fs_14 m-0 min-line'>{user && user.email || ''}</p>
                </div>
              </div>}

            <div className='text_gray fs-15px Inter-Medium mb-10px'>Menu Options</div>


            {Links?.map((item, index) => (
              <Link key={index} to={item.Link} onClick={() => handleClose()} className={`d-flex NunitoSans-Medium fs_14 mb-10px text-decoration-none menu-tabs ${item.activeWindow ? 'active' : 'disable'}`}>
                <i className={`fs-16px ${item.icon}`}></i>
                <div className='flex-grow-1 ml-10px'>{item.title}</div>
              </Link>
            ))}

            {user1.tkn && (
              Links1?.map((item, index) => (
                <Link key={index} onClick={handleClose} className={`d-flex NunitoSans-Medium fs_14 mb-10px text-decoration-none menu-tabs ${item.activeWindow ? 'active' : 'disable'}`} to={item.Link}>
                  <i className={`fs-16px ${item.icon}`}></i>
                  <div className='flex-grow-1 ml-10px'>{item.title}</div>
                </Link>
              )))}


            <Link to={`${vendor_signup_url}`} target='_blank' onClick={handleClose} rel="noopener noreferrer" className={`d-flex NunitoSans-Medium fs_14 mb-10px text-decoration-none menu-tabs disable`}>
              <i className="bi bi-person fs-16px"></i>
              <div className='flex-grow-1 ml-10px'>Travel Agent Zone</div>
            </Link>

            <Link to={user1?.tkn && typeIs ? (serv?.includes('packages') ? `${vendor_url}redirect/view-packages/${user1?.tkn}` : vendor_signup_url) : !typeIs ? vendor_signup_url : null} onClick={() => user1.tkn ? null : openModal()} target={user1.tkn ? `_blank` : ''} rel="noopener noreferrer" className='d-flex menu-tabs disable NunitoSans-Medium fs_14 mb-10px text-decoration-none'>
              <i className="bi bi-box fs-16px"></i>
              <div className='flex-grow-1 ml-10px'>List Your Packages</div>
            </Link>
            <Link to={user1?.tkn && typeIs ? (serv?.includes('hotels') ? `${vendor_url}redirect/hotels/${user1?.tkn}` : vendor_signup_url) : !typeIs ? vendor_signup_url : null} onClick={() => user1.tkn ? null : openModal()} target={user1.tkn ? `_blank` : ''} rel="noopener noreferrer" className='d-flex menu-tabs disable NunitoSans-Medium fs_14 mb-10px text-decoration-none'>
              <i className="bi bi-building fs-16px"></i>
              <div className='flex-grow-1 ml-10px'>List Your Hotel</div>
            </Link>

            <Link to={'/blogs'} rel="noopener noreferrer" onClick={handleClose} className='d-flex menu-tabs disable NunitoSans-Medium fs_14 mb-10px text-decoration-none'>
              <i className="fas fa-blog fs-16px"></i>
              <div className='flex-grow-1 ml-10px'>Blogs</div>
            </Link>

            <Link to={'/contact-us'} rel="noopener noreferrer" onClick={handleClose} className='d-flex menu-tabs disable NunitoSans-Medium fs_14 mb-10px text-decoration-none'>
              <i className="fas fa-phone-volume fs-16px"></i>
              <div className='flex-grow-1 ml-10px'>Contact Us</div>
            </Link>
          </div>


          {isLoading == true ? (
            <li className="nav-item bg-warning1 pt-0-5">
              <Spin className='thm-spin' />
            </li>
          ) : (
            unId == null ? (
              <div className='position-absolute w-100' style={{ bottom: 0 }}>
                <div className='divider mb-20px w-100' />
                <div className='text_gray fs-15px Inter-Medium mb-10px'>Get Started With</div>


                <div className='w-100p '>
                  <div onClick={() => { openModal(); }} className=' mb-10px Inter-Medium d-flex justify-center align-center fs_14 text_thm w-100p menu-tabs-auth'>
                    <div className='flex-grow-1'>Login/ Register</div>
                    <i className="bi bi-chevron-right"></i>
                  </div>

                  {/* <div onClick={() => { openModal(); }} className=' mb-10px Inter-Medium d-flex justify-center align-center fs_14 text-white menu-tabs-auth'>
                    <div className='flex-grow-1'>Signup</div>
                    <i className="bi bi-chevron-right"></i>
                  </div> */}
                </div>
              </div>
            ) : (
              <div onClick={() => { outBro(); }} className=' mb-10px Inter-Medium d-flex justify-center align-center fs_14 text_thm menu-tabs-logout'>
                <div className='flex-grow-1'>Log Out</div>
                <i className="bi bi-box-arrow-in-left"></i>
              </div>
            ))}
        </div>


        <SignInModal isOpen={isModalOpen} onClose={closeModal} redirect={redirectionUrl ? redirectionUrl : ''} />
      </div>
    </Offcanvas>
  );
};

export default MenuPage;
