import React, { useContext, useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { MenuModal } from '../Componets/Modal';
import { MemContext } from '../ContextRedux/Context';
import SignInModal from '../Pages/Auth/SignInModal';
import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { svModalStatus } from '../ContextRedux/Action';
import "../CSS/IndexVendor.css"

const vendor_url = process.env.REACT_APP_VENDOR_URL;
const vendor_signup_url = process.env.REACT_APP_VENDOR__SIGNUP_URL;

function Nav(props) {
    const user1 = useSelector(state => state.data.info)
    const { isLoading, unId } = useContext(MemContext);
    const type = props?.type

    const [isSticky, setIsSticky] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
    const dispatch = useDispatch()
    // const { isOnline, isSlow } = useContext(NetworkChecking); // Access network status from context


    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            const isScrollingUp = prevScrollPos > currentScrollPos;

            if (isScrollingUp && currentScrollPos > 100) { // Add a threshold if needed
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }

            setPrevScrollPos(currentScrollPos);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);

    const handleMailClick = (e) => {
        e.preventDefault()
        window.location.href = 'mailto:support@travellinglark.com';
    };

    const openModal = () => {
        dispatch(svModalStatus({ status: true }))
        setIsModalOpen(true);
    }

    const closeModal = () => {
        dispatch(svModalStatus({ status: false }))

        setIsModalOpen(false);
    }


    const serv = user1 && user1?.user?.services;

    const pathname = window.location.pathname

    // useEffect(() => {
    //     const removeSession = () => {
    //         const navigateType = sessionStorage.getItem('navigateType');

    //         if (JSON.parse(navigateType) !== 'details' || pathname !== '/travel-leads' || pathname !== '/travel-lead/details/') {
    //             sessionStorage.removeItem('currentPage');
    //             sessionStorage.removeItem('list');
    //             sessionStorage.removeItem('details');
    //             sessionStorage.removeItem('totalPage');
    //         }
    //     }

    //     removeSession()
    // }, [])




    return (
        <>
            <nav className={`navbar-expand-md bg-dark z-2 navbar-light p-0 navbar d-flex flex-column bg-white ${type == 'queryDetails' ? '' : isSticky ? 'sticky' : ''}`}>
                {pathname !== '/travel-agents' && (<nav className={`custom-nav z-3 text-white d-flex align-items-center h_45px w-100 bg_thm ${type == 'queryDetails' ? '' : isSticky ? 'd-none' : ''}`}>
                    <i className="fa-solid fa-envelope ps-20px d-none-on-md"></i>
                    <div className='NunitoSans-Regular fs_14 ms-2 pointer d-none-on-md' onClick={handleMailClick}>support@travellinglark.com</div>

                    <div className='flex-grow-1 d-none-on-md' />
                    <Link to={'/travel-agents'} target='_blank' className='Inter-Regular text-white pointer fs_14 ms-3'>Travel Agent Zone</Link>
                    {serv?.includes('packages') && <Link to={user1?.tkn ? `${vendor_url}redirect/view-packages/${user1?.tkn}` : `/signin`} target={user1.tkn ? `_blank` : ''} rel="noopener noreferrer" className='Inter-Regular text-white pointer fs_14 d-none-on-md ms-4'><i className="fa-solid fa-plus fs_12"></i> List Your Package</Link>}
                    {serv?.includes('hotels') && <Link to={user1?.tkn ? `${vendor_url}redirect/hotel/${user1?.tkn}` : `/signin`} target={user1.tkn ? `_blank` : ''} rel="noopener noreferrer" className='Inter-Regular text-white pointer fs_14 d-none-on-md ms-4'><i className="fa-solid fa-plus fs_12"></i> List Your Hotels</Link>}
                </nav>)}
                <nav className={`custom-nav navbar-expand-md z-3 navbar-light p-0 navbar h_65px position-relative ${pathname == '/travel-agents' ? 'mix-three-gradient' : pathname == '/post-travel' ? 'bg-white' : 'bg-gray'}`}>
                    <Link to="/" className="d-flex align-items-center justify-content-center px-4  px-10px--xs px-lg-1">
                        <img src="/icon1.png" alt="TravellingLark" className="logo_footer me-1 hw_55px " />
                        <div className={`m-0 fs-15px thm NunitoSans-ExtraBold ${pathname == '/travel-agents' ? 'text-white' : 'text_thm'}`}>TravellingLark</div>
                    </Link>
                    {!unId && unId == null && (
                        <div className='d-show-on-md'>
                            <li className="nav-item d-flex align-items-center bg_thm rd-25px px-15px py-5px mr-25px" >
                                <p onClick={openModal} className='m-0 Inter-Medium fs-13px text-white pointer'>Login</p>
                            </li>
                        </div>)}
                    {unId && unId !== null && (
                        <div className="nav-item bg-warning1 pt-0-5 pr-10px d-show-on-md">
                            <MenuModal />
                        </div>
                    )}
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav ms-auto p-4 p-lg-0">
                            <li className="nav-item d-flex align-items-center">
                                <NavLink to='/' className={(a) => a.isActive ? 'nav-link active' : pathname == '/travel-agents' ? 'nav-link text-white' : 'nav-link'}>
                                    <p className='m-0 NunitoSans-Bold fs_15'>Home</p>
                                </NavLink>
                            </li>

                            {/* {typeIs && ( */}
                            <li className="nav-item d-flex align-items-center">
                                <NavLink to='/travel-leads' className={(a) => a.isActive || window.location.pathname.startsWith('/travel') ? 'nav-link active' : pathname == '/travel-agents' ? 'nav-link text-white' : 'nav-link'}>
                                    <p className='m-0 NunitoSans-Bold fs_15'>Travel Leads</p>
                                </NavLink>
                            </li>
                            {/* )} */}

                            {(user1?.user?.role == 'user') && (
                                <li className="nav-item d-flex align-items-center">
                                    <NavLink to='/my-travels' className={(a) => a.isActive || window.location.pathname.startsWith('/my-travels') ? 'nav-link active' : pathname == '/travel-agents' ? 'nav-link text-white' : 'nav-link'}>
                                        <p className='m-0 NunitoSans-Bold fs_15'>My Travels</p>
                                    </NavLink>
                                </li>
                            )}

                            {/* <li className="nav-item d-flex align-items-center bg-warning1 w-100px">
                                <NavLink to='/packages' className={(a) => a.isActive || window.location.pathname.startsWith('/package') ? 'nav-link active' : 'nav-link text-white'}>
                                    <p className='m-0 NunitoSans-Bold fs_15'>Packages</p>
                                </NavLink>
                                <div className={'nav-link'}>
                                    <NavDropdown className={''} fontFamily={'OpenSans-Bold '} />
                                </div>
                            </li> */}
                            {/* <li className="nav-item bg-warning1 d-flex align-items-center">
                                <NavLink to='/hotels' className={(a) => a.isActive || window.location.pathname.startsWith('/hotel') ? 'nav-link active' : 'nav-link'}>
                                    <p className='m-0 NunitoSans-Bold fs_15'>Hotels</p>
                                </NavLink>
                            </li>
                            <li className="nav-item d-flex align-items-center">
                                <NavLink to='/vehicle-search' className={(a) => a.isActive || window.location.pathname.startsWith('/vehicle') ? 'nav-link active' : 'nav-link'}>
                                    <p className='m-0 NunitoSans-Bold fs_15'>Vehicle</p>
                                </NavLink>
                            </li> */}
                            {/*
                            <li className="nav-item bg-warning1 d-flex align-items-center">
                                <NavLink to='/guiders' className={(a) => a.isActive || window.location.pathname.startsWith('/guider') ? 'nav-link active' : 'nav-link'}>
                                    <p className='m-0 NunitoSans-Bold fs_15'>Guider</p>
                                </NavLink>
                            </li> */}

                            {isLoading == true ? (
                                <li className="nav-item bg-warning1 pt-0-5">
                                    <Spin className='thm-spin' />
                                </li>
                            ) : (
                                !unId && unId == null && (
                                    <li className="nav-item d-flex align-items-center" >
                                        <p onClick={openModal} className='m-0 NunitoSans-Bold fs_15 text_gray pointer'>Login</p>
                                    </li>
                                ))}
                        </ul>

                        {pathname !== '/travel-agents' && (<li className="nav-item d-flex align-items-center ms-3" >
                            <NavLink to={'/post-travel'} className={(a) => a.isActive || window.location.pathname.startsWith('/quer') ? 'nav-link active' : 'nav-link'} >
                                <div className={`nav-link login-nav-link p-1 px-3 NunitoSans-Regular fs_13 d_flex_align_justify_center h_35px rd_50 pointer ${pathname !== '/travel-agents' ? 'bg_thm text-white' : 'bg-white text_thm'}`}>Post Your Travel</div>
                            </NavLink>
                        </li>)}

                        {pathname == '/travel-agents' && (<li className="nav-item d-flex align-items-center ms-3" >
                            <NavLink to={vendor_url} className={'nav-link'} >
                                <div className={`nav-link login-nav-link p-1 px-3 NunitoSans-Regular fs_13 d_flex_align_justify_center h_35px rd_50 pointer bg-white text_thm`}>Login/ Signup</div>
                            </NavLink>
                        </li>)}

                        {unId && unId !== null && pathname !== '/travel-agents' && (
                            <div className="nav-item bg-warning1 pt-0-5">
                                <MenuModal />
                            </div>
                        )}
                    </div>
                </nav>

            </nav >

            {/* Modal Sigin */}
            <SignInModal isOpen={isModalOpen} onClose={closeModal} redirect={'/post-travel'} />
        </>

    )
}

export default Nav