import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import toast, { Toaster } from 'react-hot-toast'
import { Link } from 'react-router-dom'
import RegisterFormModal from '../Auth/SignInModal'
import { AthModal } from '../../Componets/Modal'

function About() {

    return (
        <div className='d-flex flex-column align-items-center bg-white mt-12 pt-4 p-4'>

            <p className='Inter-Bold fs-3 mt-2 m-0 text-center' style={{ color: '#0d0a3a' }}>About Us</p>

            <div className='w_95 mt-4 mb-4 bg-success1'>
                {/* <p className='Inter-Regular fs_14 text-secondary m-0 mt-3'>
                    <strong className='text-dark'>TravellingLark: Your Partner in Adventure</strong>
                    <br /><br />
                    Fed up with the overwhelming task of planning a trip? <span className='text-dark Inter-SemiBold fs_14'>TravellingLark</span>, your personalised travel planning services saves you the worry of preparing any travel arrangements. All you need to do is upload the holiday of your dreams, and we will find verified vendors for you. These vendors will then compete for your services by providing tailored estimates based on your budget, preferences, and adventure.
                    With <span className='text-dark Inter-SemiBold fs_14'>TravellingLark</span>, you will be able to view all the available quotes, choose the best one, and worry free go on the holiday of your dreams.
                    This is why <span className='text-dark Inter-SemiBold fs_14'>TravellingLark</span> is the best solution for the forthcoming adventure:
                    <br /><br />

                    <strong className='text-dark Inter-SemiBold fs_14'>Easy to find :</strong> No more endless internet searching and tiring schedules scrubbing. There will be no need for spending long hours crafting adorable schedules. Ours travel planners will be understanding of your interests, budget, and your mode of travel. A suitable itinerary that will cater for every member in the group will be designed.
                    Supreme Level of Assistance: With the appropriate know-how on various destinations across the globe, there are places that we will take you to – those that other sources will not offer. Whether it is a thrilling adventure or a warm sunny beach tucked away from the bustling towns, there is something for everyone.
                    <br /><br />

                    <strong className='text-dark Inter-SemiBold fs_14'>Reasonable Rates:</strong> You are, indeed, welcome to the most competitive prices in the market for travel. The reliable vendor will get you  the best possible rates for airfare, accommodation, and activities so that you can get value for your travel.
                    <br /><br />

                    <strong className='text-dark Inter-SemiBold fs_14'>Trusted Partnerships:</strong> We connect you with reputable vendors, ensuring a reliable and enjoyable experience.
                    <br /><br />

                    <strong className='text-dark Inter-SemiBold fs_14'>Creative Solutions:</strong> We're always looking for innovative ways to enhance your travel adventures and provide a personalized experience.
                    Sustainable Travel environment: We're committed to responsible travel practices that minimize our impact on the
                    There’s Nothing Better than This: Do whatever you want for as long as we know everything has been taken cared of. And we did the detailed including every step of the way, so there’s no other travel plan going awry.
                    Never Ending Fun Experiences
                </p> */}

                <Container className='mt-5'>
                    <Row className='flex-wrap row-gap-3 bg-succes1s'>
                        <Col sm={12} md={6} lg={5} className='bg-primary1 h-auto' style={{}}>
                            {/* <img src='https://img.freepik.com/premium-vector/flat-style-vector-abstract-concept-travelling-tourist_1332465-324.jpg?w=2000' className='img-fluid' /> */}
                            <img src='https://img.freepik.com/premium-vector/man-with-backpack-camera-with-picture-man-carrying-backpack-camera_1291954-57.jpg?w=996' alt='TravellingLark About Us' className='img-fluid w_86 w-100-xs' />
                        </Col>

                        <Col className='bg-warning1' style={{}}>
                            <p className='Inter-Regular fs_14 text-secondary m-0'>
                                <strong className='text-dark'>TravellingLark: Your Partner in Adventure</strong>
                                <br /><br />
                                Fed up with the overwhelming task of planning a trip? <span className='text-dark Inter-SemiBold fs_14'>TravellingLark</span>, your personalised travel planning services saves you the worry of preparing any travel arrangements. All you need to do is upload the holiday of your dreams, and we will find verified vendors for you. These vendors will then compete for your services by providing tailored estimates based on your budget, preferences, and adventure.
                                With <span className='text-dark Inter-SemiBold fs_14'>TravellingLark</span>, you will be able to view all the available quotes, choose the best one, and worry free go on the holiday of your dreams.
                                This is why <span className='text-dark Inter-SemiBold fs_14'>TravellingLark</span> is the best solution for the forthcoming adventure:
                                <br /><br />

                                <strong className='text-dark Inter-SemiBold fs_14'>Easy to find :</strong> No more endless internet searching and tiring schedules scrubbing. There will be no need for spending long hours crafting adorable schedules. Ours travel planners will be understanding of your interests, budget, and your mode of travel. A suitable itinerary that will cater for every member in the group will be designed.
                                Supreme Level of Assistance: With the appropriate know-how on various destinations across the globe, there are places that we will take you to – those that other sources will not offer. Whether it is a thrilling adventure or a warm sunny beach tucked away from the bustling towns, there is something for everyone.
                                <br /><br />

                            </p>
                        </Col>
                    </Row>

                    <Row className='d-flex flex-wrap row-gap-3 mt-7'>
                        <Col sm={12} md={6} lg={6} className='bg-warning1' style={{}}>
                            <p className='Inter-Regular fs_14 text-secondary m-0'>
                                <strong className='text-dark Inter-SemiBold fs_14'>Reasonable Rates:</strong> You are, indeed, welcome to the most competitive prices in the market for travel. The reliable vendor will get you  the best possible rates for airfare, accommodation, and activities so that you can get value for your travel.
                                <br /><br />

                                <strong className='text-dark Inter-SemiBold fs_14'>Trusted Partnerships:</strong> We connect you with reputable vendors, ensuring a reliable and enjoyable experience.
                                <br /><br />

                                <strong className='text-dark Inter-SemiBold fs_14'>Creative Solutions:</strong> We're always looking for innovative ways to enhance your travel adventures and provide a personalized experience.
                                Sustainable Travel environment: We're committed to responsible travel practices that minimize our impact on the
                                There’s Nothing Better than This: Do whatever you want for as long as we know everything has been taken cared of. And we did the detailed including every step of the way, so there’s no other travel plan going awry.
                                Never Ending Fun Experiences
                            </p>
                        </Col>
                        <Col sm={12} md={6} lg={6} className='bg-primary1' style={{}}>
                            {/* <img src='https://img.freepik.com/premium-photo/plane-travel-landmark-world-white-background-time-travel-banner-tourism-trip-concept-journey-vacation-vector-illustration-flat-design_1324913-1847.jpg?w=1380' className='img-fluid' /> */}
                            <img src='/images/travelling_airplane.webp' className='img-fluid' />
                        </Col>
                    </Row>

                </Container>

               
            </div>


        </div>
    )
}

export default About