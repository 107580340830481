import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import { DatePicker, Modal, Spin } from 'antd';
import moment from 'moment';
import Counter from './Counter';
import { CountriesDrop } from '../../Componets/Countries';
import { FilterHotels } from './FilterSection';
import { CommonAPI } from '../../Componets/CommonAPI';
import { dec } from '../../Componets/SecRes';
import { dateFormat } from '../../Componets/CommonFs';
import SignInModal from '../Auth/SignInModal';
import { useLocalStorage } from 'react-fu-hooks';
import { Autocomplete } from '@react-google-maps/api';
import InfiniteScroll from 'react-infinite-scroll-component';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet';
import { TiredOfSearching } from '../../Componets/Recommended';
const { RangePicker } = DatePicker;

const img_base_url = process.env.REACT_APP_IMG_BASE_URL;
const key = process.env.REACT_APP_KEY;


function HotelIndex() {
    const [hotelId, setHotelId] = useLocalStorage('hotelType', '')
    const [hotelLocation, setHotelLocation] = useLocalStorage('hotelLocation', '')
    const [hotelTypes, setHotelTypes] = useLocalStorage('hotelTypes', '')
    const [amenities, setAmenities] = useLocalStorage('amenities', '')


    const [dates, setDates] = useState([])
    const [dateValue, setDateValue] = useState([])
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [page, setPage] = useState(1)
    const [countryId, setCountryId] = useState('')
    const [countryName, setCountryName] = useState('')
    const [search, setSearch] = useState(hotelLocation || '');
    const [list, setList] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [hotelType, setHotelType] = useState(!hotelId[0] ? [] : [hotelId])
    const [selectedValues, setSelectedValues] = useState([]);
    const [selectedValues1, setSelectedValues1] = useState([]);
    const [roomDetails, setRoomDetails] = useState({
        room: 1,
        adults: 1,
        children: 0
    });
    const [latLng, setLatLng] = useState([]);
    const autocompleteRef = useRef(null);
    const inputRef = useRef(null);
    const [hasMore, setHasMore] = useState(true);


    useEffect(() => {
        if (dates.length === 0) {
            const currentDate = new Date();
            const nextDate = new Date()
            nextDate.setDate(currentDate.getDate() + 1)
            const makeArr = [currentDate, nextDate]
            const parsedDate1 = dayjs(currentDate || null)
            const parsedDate2 = dayjs(nextDate || null)
            setDateValue([parsedDate1, parsedDate2])

            const formatted = makeArr.map(item => dateFormat(item, 'dmy').replace(/\//g, '-'))
            setDates(formatted)
        }
    }, [dates?.length === 0]);

    const handleDateChange = (values) => {
        if (values) {
            const formattedDates = values.map(item => dateFormat(item, 'dmy').replace(/\//g, '-'))
            setDates(formattedDates)
        } else {
            setDates([]);
        }
    };


    useEffect(() => {
        setIsLoading(true)
        if (dates.length > 0 && countryId) {

            hotelList()
        }
    }, [page, countryId, dates, search <= 0, selectedValues, selectedValues1, hotelType, latLng,]);

    const hotelList = () => {
        setHotelId('')

        setIsLoading(true)
        CommonAPI({}, 'GET', `hotelList?countryId=${countryId}&location=${latLng.length > 0 ? '' : search}&coordinate=${latLng.length > 0 ? JSON.stringify(latLng) : []}&amenity=${selectedValues.length > 0 ? JSON.stringify(selectedValues) : []}&hotelType=${hotelType.length > 0 ? JSON.stringify(hotelType) : []}&pricefilter=${selectedValues1[1] == '' || selectedValues1.length == 0 ? JSON.stringify([100, 500000]) : JSON.stringify(selectedValues1)}&date=${JSON.stringify(dates)}&room=${roomDetails.room}&adult=${roomDetails.adults}&child=${roomDetails.children}&page=${page}`, responseBack)
    }

    const responseBack = useCallback(res => {
        if (res.status === true) {
            const dt = res.data && res?.data;
            const dec1 = dec(dt?.data, key)


            // if (dec1 == undefined || dec1 == null) {
            //     setList(dec1)
            // } else {
            setList(prevData => {
                const newData = dec1.filter(item => !prevData.some(existingItem => existingItem._id === item._id));
                return [...prevData, ...newData];
            });
            // }

            setHotelLocation('')

            if (dec1?.length === 0) {
                setHasMore(false);
            }

        } else {
            if (res.message == 'Invalid token') {
                setIsModalOpen(true)
            }
        }
        setIsLoading(false)
    })



    const handleOk = (ty) => {
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setOpen1(false);
            setOpen2(false);
            setConfirmLoading(false);
        }, 1000);
    };

    const onChange = (value, type) => {
        if (value == 'TL') {
            setList([])
            setPage(1)
            setHasMore(true);
            setCountryId('')
            return
        }
        if (countryId !== value) {
            setList([])
            setHasMore(true);
            setPage(1)
            setCountryId(value)
        }
    };

    const disbaleDate = (current) => {
        return current && current < moment().startOf('day');
    }

    const handleCheckboxChange = (value, type) => {
        setList([])
        setPage(1)
        setHasMore(true);
        if (type == 'rng') {
            setSelectedValues1(value)
        } else if (type == 'other') {
            setHotelType(prevValues => {
                if (prevValues.includes(value)) {
                    return prevValues.filter(item => item !== value);
                } else {
                    return [...prevValues, value];
                }
            });
        } else {

            setSelectedValues(prevValues => {
                if (prevValues.includes(value)) {
                    return prevValues.filter(item => item !== value);
                } else {
                    return [...prevValues, value];
                }
            });
        }
    };

    const imgStyle = {
        filter: 'grayscale(100%)',
    };

    const handlePlaceSelect = () => {
        const place = autocompleteRef.current.getPlace();
        if (place?.geometry) {

            const add = inputRef.current.value;

            setSearch(add)
            setCountryId('')
            setList([])
            setLatLng([place?.geometry?.location.lng(), place?.geometry?.location.lat()]);
        }
    };


    const handleChange = (e) => {
        if (e.target.value == '') {
            setSearch(e.target.value)
            setCountryId('')
            setList([])
            setLatLng([]);
        }
    };


    const fetchMoreData = () => {
        setIsLoading(true)
        setPage(page + 1);
    };

    const filterName = hotelTypes ? hotelTypes?.filter((item) => hotelType.includes(item._id)) : []
    const filterAmenities = amenities ? amenities?.filter((item) => selectedValues.includes(item._id)) : []


    return (
        <div className='d-flex flex-column align-items-center mt-12 pt-3 p-0 pb-5 bg-gray' style={{ backgroundColor: '#eef4fd' }}>
            <div className="w_92 mt-4 mt-0px--sm mb-5 d-flex align-items-center flex-column">

                {/* Filter */}
                <div className='mt-2 align-items-center w-100 w-97p--xs p-3 bg-white rd_6'>
                    <Row className='bg-warnin1g align-items-end d-none-on-lg'>
                        <Col lg={2} xs={2} className='px-1 bg1-warning'>
                            <CountriesDrop onChange={onChange} selected={countryId} sendCName={setCountryName} margin={'no'} type={'sign'} />
                        </Col>

                        <Col lg={3} xs={2} className='px-1 bg1-warning'>
                            <Autocomplete onLoad={(ref) => (autocompleteRef.current = ref)} onPlaceChanged={handlePlaceSelect}>
                                <input
                                    ref={inputRef}
                                    type='text'
                                    onChange={handleChange}
                                    placeholder='Search Pickup location'
                                    style={{ width: '100%', padding: '10px' }}
                                    className='form-control bg-warning1 fs_13 h_38px rd_10 border_gray Rubik-Regular text_dark_medium'
                                />
                            </Autocomplete>
                        </Col>
                        <Col lg={3} xs={2} className='px-1 bg1-danger'>
                            <RangePicker
                                className='fs_12 text_gray w-100 h_38px rd_10 border_gray'
                                format={'DD-MM-YYYY'}
                                disabledDate={disbaleDate}
                                value={dateValue}
                                onChange={handleDateChange}
                                placeholder={['Checkin Date', 'Checkout Date']}
                            />

                        </Col>
                        <Col className='px-1 bg1-success'>
                            <div className='form-control fs_13 h_38px rd_10 border_gray d-flex  align-items-center text_dark_medium' onClick={() => setOpen(true)}>{roomDetails.room} Room • {roomDetails.adults} Adults • {roomDetails.children} Child</div>
                        </Col>
                        <Col lg={1} className='px-1 bg1-warning lg-1-5 px-0'>
                            <div className='Source_Medium p-1 px-2 bg-gradient-thm flex-grow-1 text-white d-flex align-items-center justify-content-center rd_10 m-0 pointer custom_field' onClick={() => { setList([]); hotelList(); setLatLng([]) }}>
                                Search
                            </div>
                        </Col>
                    </Row>
                    <div className='flex-grow-1'></div>
                    <div className='d-flex align-items-end w_95 d-lg-none'>

                        <p className='Source_Medium text_thmdark px-3 rd_6 fs-13px bg_thmlightdark pointer h-35px  m-0 d-flex align-items-center d-lg-none me-3 ' onClick={() => setOpen2(true)}><i className='bi bi-search fs_13 mr_5'></i> SEARCH</p>
                        <p className='Source_Medium text_thmdark px-3 rd_6 fs-13px bg_thmlightdark pointer h-35px m-0 d-flex align-items-center d-sm-none' onClick={() => setOpen1(true)}>FILTER <i className='bi bi-filter '></i></p>
                    </div>

                    <div className='d-show-on-sm'>
                        {filterName?.length > 0 && (
                            <div className='d-flex gap-2 mt-3 scroll-h hide-scroll-indi'>
                                {filterName?.map((item) => (
                                    <div className='d_flex_align_justify_center text-muted px-2 p-1 rd_50 Inter-Regular bg_lightgray' onClick={() => handleCheckboxChange(item._id, 'other')}>
                                        <p className='m-0 fs_11'>{item.name}</p>
                                        <i className="fa-solid fa-xmark fs_11 ms-2"></i>
                                    </div>
                                ))}
                            </div>
                        )}
                        {filterAmenities?.length > 0 && (
                            <div className='d-flex gap-2 mt-3 scroll-h hide-scroll-indi'>
                                {filterAmenities?.map((item) => (
                                    <div className='d_flex_align_justify_center text-muted px-2 p-1 rd_50 Inter-Regular bg_lightgray' onClick={() => handleCheckboxChange(item._id, 'categoryId')}>
                                        <p className='m-0 fs_11'>{item.name}</p>
                                        <i className="fa-solid fa-xmark fs_11 ms-2"></i>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                <Helmet>
                    <meta charSet="UTF-8" />
                    <title>Hotels Near Me With Air Conditioning | Affordable Luxury Hotels for Travelers | Travellinglark</title>
                    <meta name='description' content='Book affordable luxury hotels for travelers with air conditioning. Explore deals in India, perfect for couples and tourist spots. Easy online booking near you!' />
                    <meta name="keywords" content="Affordable luxury hotels for travelers, Affordable hotels in tourist destinations" />
                    <link rel="canonical" href="https://www.travellinglark.com/hotels/" />
                </Helmet>


                <Row className='bg-primary1 mt-4 w-100'>
                    <Col xs={0} sm={0} md={4} lg={3} className='d-none-on-xs'>
                        <FilterHotels selectedValues={selectedValues} selectedValues1={selectedValues1} hotelType={hotelType} onChange={handleCheckboxChange} />
                        <TiredOfSearching/>

                        <h2 className='fs-16px Inter-SemiBold mt-40px'>Affordable Hotels in Tourist Destinations</h2>
                        <p className='fs-13px Inter-Regular mt-5px'>
                            Finding the perfect balance between comfort and cost can be challenging when planning a trip. At TravellingLark, we make it easy for you to discover affordable luxury hotels for travelers that offer top-notch amenities without breaking the bank. Whether you’re planning a weekend getaway or a long vacation, we connect you with the best options to suit your budget and preferences.
                            <br /><br />
                            Our platform is designed to help you find affordable hotels in tourist destinations, ensuring that your stay is as enjoyable as your journey. From scenic hill stations to vibrant city centers, we bring you a curated list of accommodations that deliver premium experiences at competitive rates.
                            <br /><br />
                            Whether you’re seeking a cozy retreat or a luxurious escape, TravellingLark helps you find the ideal place to stay. Experience the joy of travel without compromising on comfort or affordability.

                            <br /><br />
                            Start your journey today and explore our exclusive list of affordable hotels in tourist destinations and affordable luxury hotels for travelers!
                        </p>
                    </Col>

                    <Col xs={12} sm={12} md={8} lg={9} className="m-xxs-auto mb-4 bg-warning1">
                        <h1 className='fs-16px Inter-SemiBold'>Affordable Luxury Hotels for Travelers {countryName}</h1>
                        {isLoading && (
                            <div className='d-flex justify-content-center'>
                                <Spin className='thm-spin' />
                            </div>
                        )}

                        {list?.length > 0 && (
                            <InfiniteScroll
                                className='hide-scroll'
                                dataLength={list?.length}
                                next={fetchMoreData}
                                hasMore={hasMore}
                                loader={<div className='w-100 d-flex justify-content-center mt-3'>
                                    <Spin className='thm-spin' />
                                </div>}
                                endMessage={<p className='Rubik-Regular fs_11 m-0 p-0 text-secondary text-center mt-3'>No More data found</p>}
                            >

                                {/* Listing */}
                                {list.map((item, i) => {
                                    const add = item.address
                                    const address = add.length > 65 ? add.slice(0, 65) + '...' : add;

                                    const nm = item.name
                                    const name = nm.length > 30 ? nm.slice(0, 30) + '...' : nm

                                    const lowPrice = item.discountPrice
                                    const final = (lowPrice == null || lowPrice == 0) ? item.price : lowPrice

                                    const discount = final - item.price;
                                    const percentage = (discount / item.price) * 100

                                    return (
                                        <div key={item._id} className='mb-xs-10 bg-white rd_6 mb-4 p-0 overflow-hidden position-relative'>
                                            <Link to={`/hotel/${item?.slug}/${item.id}`} target='_blank' rel="noopener noreferrer" className=' p-2 d-flex flex-column-sm text-decoration-none hover-me'>
                                                <img src={`${img_base_url + item.image}`} alt={item.name} className='img-fluid h_180px w_340px rd_6 position-relative overflow-hidden w-md-100' />
                                                <div className="d-flex flex-column ms-2 p-1 w-100 mt-sm-10">
                                                    <h3 className='Rubik-SemiBold text_gray fs_15 fs-xs-14 mb-1 text-capitalize'>{name}</h3>
                                                    <div className='d-flex align-items-start bg1-warning'>
                                                        <i className="fa-regular fa-building fa-fade text_gray2 mt-1 fs_14 me-1"></i>
                                                        <p className='Source_Regular text_gray2 fs_14 m-0 pt-0 mini-line-1'>{address}</p>
                                                    </div>

                                                    <div className='d-flex'>
                                                        <div className='flex-grow-1'>
                                                            {item.totalReviews !== 0 && (
                                                                <div className='d-flex mt-2'>
                                                                    <div className='d-flex align-items-center p-0-5 px-2 rd_4 d-inline-flex' style={{ backgroundColor: '#e5fdea', color: '#13af35' }}>
                                                                        <div className='Rubik-Medium fs_13'>{item.avgRating}</div>
                                                                        <i className="bi bi-star-fill ms-1 fs_12"></i>
                                                                    </div>
                                                                    <p className='Source_Regular text_gray2 fs_14 m-0 ms-2'>({item.totalReviews} Ratings)</p>
                                                                </div>
                                                            )}

                                                            <div className='flex-grow-1'></div>

                                                            <div className=' show-on-small'>
                                                                <div className="d-flex aling-items-center mt-3">
                                                                    <p className='Source_Regular text_gray2 fs_14 m-0 me-3 bg1-success pt-0'><strong className='Inter-Regular fs_13'>Stars :</strong> {item.property}</p>
                                                                    <p className='Source_Regular text_gray2 fs_14 m-0 bg1-success pt-0'><strong className='Inter-Regular fs_13'>Type :</strong> {item?.type[0]?.name}+</p>
                                                                </div>
                                                            </div>

                                                            <div className='d-flex gap-2 mt-3 mb-2 scrollable-h scrollable'>
                                                                {item?.amenities?.slice(0, 4).map((amenity, i) => (
                                                                    <div key={i} className='d-flex bg-white  align-items-center text_gray px-2 p-1-5 rd_4 Source_Regular bg_lightblue'>
                                                                        <img className="rd_10 hw_15px me-2" src={`${img_base_url + amenity.image}`} alt={amenity.name} style={imgStyle} />
                                                                        <p className='m-0 fs_12'>{amenity.name}</p>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>

                                                        <div className='d-none-on-md'>
                                                            <div className='d-flex align-items-start flex-column  mt-3'>
                                                                <p className='Source_Regular text_gray2 fs_14 m-0 mb-1 me-3 bg1-success pt-0'><strong className='Inter-Regular fs_13'>Stars :</strong> {item.property}</p>
                                                                <p className='Source_Regular text_gray2 fs_14 m-0 bg1-success pt-0'><strong className='Inter-Regular fs_13'>Type :</strong> {item?.type[0]?.name}+</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='flex-grow-1'></div>

                                                    <div className='d-flex align-items-center'>
                                                        <div className='flex-grow-1'>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='Rubik-SemiBold fs_18 text_gray'>{item?.currencyData?.code}{final}</div>
                                                                {lowPrice != null && lowPrice > 0 && (
                                                                    <>
                                                                        <div className='Rubik-Regular fs_13 text_gray2 ms-2 mt-1 text-decoration-line-through'>{item?.currencyData?.code}{item.price}</div>

                                                                        <div className='Rubik-SemiBold fs_13 text_gold ms-2'>{percentage?.toFixed(2).replace('-', '')}%</div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className='btn border_thmdark rd_4 text_thmdark fs_12 d-none-519'>
                                                            Request Callback
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                })}
                            </InfiniteScroll>
                        )}

                        {/* {loadMore && !isLoading && list && list?.length > 0 && (
                                <div className='d_flex_align_justify_center  mt-4'>
                                    <button className='btn bg_thm text-white w_150px fs_12' disabled={isLoading1} onClick={handleShowMore}>{isLoading1 ? <Spinner size='sm' /> : 'Load More'}</button>
                                </div>
                            )}

                            {notMore && (
                                <p className='Rubik-Regular fs_11 m-0 p-0 text-secondary text-center mt-3'>{notMore}</p>
                            )} */}

                        {!isLoading && list && list?.length == 0 && (
                            <div className='d_flex_align_justify_center flex-column mt-5'>
                                <img src='/icons/not-found.png' alt='not found' className='img-fluid h_100px w_100px mt-5' />
                                <h5 className='Source_SemiBold mt-4'>no data found</h5>
                            </div>
                        )}
                    </Col>
                </Row>

            </div>

            <Modal
                title="Search"
                className='Source_Medium text_gray w_60 w-xs-auto mx-xs-4'
                styles={{
                    body: {
                        padding: '15px', height: 'auto'
                    },
                    header: {
                        padding: '10px 10px 0 10px',
                    },
                    footer: {
                        padding: '10px'
                    }
                }}
                open={open2}
                onOk={handleOk}
                footer={null}
                onCancel={() => setOpen2(false)}
            >
                <div className='bg-warnin1g  align-items-end mr_10 mt-3'>
                    <Row className='d-flex justify-content-between'>
                        <Col lg={3} md={6} sm={6} xs={12} className='bg1-warning mb-2'>
                            <CountriesDrop onChange={onChange} selected={countryId} margin={'no'} />
                        </Col>
                        <Col lg={3} md={6} sm={6} xs={12} className='mb-2 bg1-warning'>
                            <Autocomplete onLoad={(ref) => (autocompleteRef.current = ref)} onPlaceChanged={handlePlaceSelect}>
                                <input
                                    ref={inputRef}
                                    type='text'
                                    placeholder='Search Pickup location'
                                    style={{ width: '100%', padding: '10px' }}
                                    className='form-control bg-warning1 fs_13 h_38px rd_10 border_gray Rubik-Regular text_dark_medium'
                                />
                            </Autocomplete>
                        </Col>
                        <Col lg={3} md={6} sm={6} xs={12} className='mb-2 bg1-danger'>
                            <RangePicker
                                className='fs_12 text_gray w-100 h_38px rd_10 border_gray'
                                format={'DD-MM-YYYY'}
                                disabledDate={disbaleDate}
                                onChange={handleDateChange}
                                placeholder={['Checkin Date', 'Checkout Date']}
                            />

                        </Col>
                        <Col lg={3} md={6} sm={6} xs={12} className='mb-2 bg1-success'>
                            <div className='form-control fs_13 h_38px rd_10 border_gray d-flex  align-items-center text_dark_medium' onClick={() => setOpen(true)}>{roomDetails.room} Room • {roomDetails.adults} Adults • {roomDetails.children} Child</div>
                        </Col>
                        <Col lg={1} md={12} xs={12} className='d-flex mt-2 bg1-warning'>
                            <div className='Source_Medium p-1 px-2 bg-gradient-thm flex-grow-1 text-white d-flex align-items-center justify-content-center rd_10 m-0 pointer custom_field' onClick={() => { setList([]); hotelList() }}>
                                Search
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal>

            <Modal
                title="Room & Guest"
                className='Source_Medium text_gray w-xs-auto mx-xs-4'
                styles={{
                    body: {
                        padding: '0 20px', height: 'auto'
                    },
                    header: {
                        padding: '15px 0px 0 15px',
                    },
                    footer: {
                        padding: '10px'
                    }
                }}
                open={open}
                onOk={() => { hotelList(); handleOk(); setList([]) }}
                confirmLoading={confirmLoading}
                onCancel={() => setOpen(false)}
            >
                <Counter details={roomDetails} setDetails={setRoomDetails} />
            </Modal>

            <Modal
                title="FILTER"
                className='Source_Medium text_gray w-xs-auto mx-xs-4 '
                styles={{
                    body: {
                        padding: '0 20px', overflowY: 'auto', maxHeight: '65vh'
                    },
                    header: {
                        padding: '15px 0px 0 15px',
                    },
                    footer: {
                        padding: '10px'
                    }
                }}
                open={open1}
                onOk={handleOk}
                onCancel={() => setOpen1(false)}
            >
                <div className="scrollable-h">
                    <FilterHotels selectedValues={selectedValues} selectedValues1={selectedValues1} hotelType={hotelType} onChange={handleCheckboxChange} />
                </div>
            </Modal>

            {/* Modal Sigin */}
            <SignInModal isOpen={isModalOpen} onClose={setIsModalOpen} />
        </div>
    )
}

export default HotelIndex