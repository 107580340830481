import React, { useEffect, useState } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';
import { Link, useLocation, useParams } from 'react-router-dom';
import Select from 'react-select'
import { GradientText } from 'react-fdt-gradient';
import { Helmet } from 'react-helmet';
import "../../CSS/IndexVendor.css"
const vendor_url = process.env.REACT_APP_VENDOR_URL;


function HomeV() {
  const location = useLocation();
  const state = location.state

  const words = ["Agency", "Tour Operator", "Travel Business", "Vacation Service", "Adventure Company", "Travel Platform", "Tourism Service"];
  const colors = ["#FF5733", "#33FF57", "#3357FF", "#F3E54B", "#9B59B6"];
  const images = ['home.webp', 'offers.webp', 'chat.webp', 'lead.webp'];

  const [currentWord, setCurrentWord] = useState(words[0]);
  const [currentImage, setCurrentImage] = useState(images[0]);
  const [text, setText] = useState('');
  const [index, setIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(true);
  const [currentColor, setCurrentColor] = useState(getRandomColor());
  const [imageTransition, setImageTransition] = useState("fade-in");
  const [businessType, setBusinessType] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  const [err, setError] = useState({})
  const [isLoading, setIsLoading] = useState(false);


  const [inView, setInView] = useState(false);
  const { ref, inView: isInView } = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });

  React.useEffect(() => {
    setInView(isInView);
  }, [isInView]);

  useEffect(() => {
    let typingInterval;
    let eraseInterval;

    if (isTyping) {
      // Typing effect
      if (text.length < currentWord.length) {
        typingInterval = setInterval(() => {
          setText((prevText) => prevText + currentWord.charAt(text.length));
        }, 150);
      } else {
        // Wait a bit before starting to erase
        setTimeout(() => {
          setIsTyping(false); // Start erasing after 2 seconds
        }, 500);
      }
    } else {
      // Erasing effect
      if (text.length > 0) {
        eraseInterval = setInterval(() => {
          setText((prevText) => prevText.slice(0, -1));
        }, 100);
      } else {
        // After erasing the word, move to the next word
        setIsTyping(true);
        setIndex((prevIndex) => (prevIndex + 1) % words.length);
        setCurrentColor(getRandomColor());
        setImageTransition("fade-out");

        setTimeout(() => {
          setCurrentImage(images[(index + 1) % images.length]); // Update image based on next word
          setImageTransition("fade-in"); // Start fading in the new image
        }, 1000);
      }
    }

    // Cleanup intervals when component unmounts or when the word changes
    return () => {
      clearInterval(typingInterval);
      clearInterval(eraseInterval);
    };
  }, [text, currentWord, isTyping]);

  useEffect(() => {
    setCurrentWord(words[index]);
    setText(""); // Reset text when changing the word
  }, [index]);


  function getRandomColor() {
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  }

  const handleScroll = () => {
    const element = document.getElementById('callback');
    element.scrollIntoView({ behavior: 'smooth' });
  };

  React.useEffect(() => {
    if (state?.type) {
      handleScroll1();
    }
  }, [state?.type]);


  const handleScroll1 = () => {
    const element = document.getElementById(state?.type);
    element.scrollIntoView({ behavior: 'smooth' });
  };


  const list = ['Agency']

  const options = list.map((item) => ({
    value: item,
    label: item
  }))


  const signupFree = [
    { title: 'Free Travel Leads' },
    { title: '100% Earnings, 0% Commission' },
    { title: 'Create Your Profile in Minutes' },
    { title: 'Post Unlimited Packages' },
    { title: 'Travelers Come to You' },
  ]


  const directLeads = [
    { title: 'Complete your profile with your services and expertise.' },
    { title: 'Add details like location, services, and packages.' },
    { title: 'Get listed on TravellingLark’s platform to attract travelers.' },
  ]

  const sendOffers = [
    { title: 'Respond to leads with personalized offers and packages.' },
    { title: 'Use the platform to share detailed itineraries, pricing, and deals.' },
    { title: 'Tailor packages to meet the traveler’s preferences.' },
  ]


  const closeMoreBooking = [
    { title: 'Convert more leads into confirmed bookings.' },
    { title: 'Track and manage all bookings directly on the platform.' },
    { title: 'Expand your reach without extra marketing costs or commissions.' },
  ]



  return (
    <div className='mix-three-gradient h-100 mt-5 d-flex flex-column align-items-center'>
      {/* <Nav callback={handleScroll} /> */}

      {/* Hero Section */}
      <Row className="bg-warning1 w-85p w-90p--xs-g mt-60px">
        <Col xxl={7} xl={6} lg={6} md={5} sm={12} className="bg-warning1 d-flex align-items-center flex-column justify-content-center pb-2">
          <div className='d-flex align-items-start flex-column  justify-content-center'>
            <div className='Inter-Bold fs-40px w-95p fs-30px--lg fs-26px--md text-white'>India's First Free Travel Leads Platform</div>
            <div className='Rubik-Regular fs-16px text-white mt-2'>
            TravellingLark is India’s platform offering travel vendors free, direct leads, unlimited package listings, and 100% earnings. Expand your business effortlessly, attract more travelers, and close more bookings—all with zero extra costs or commissions. With TravellingLark, grow your presence and take your business to new heights, without any financial barriers.
            </div>

            <div className='d-flex align-items-center bg1-danger w-100 mt-3'>
              <Link to={vendor_url} target='_blank' className='mt-3 bg-gradient-dark h-38px pointer d-inline-flex px-3 mr-10px rd-6px justify-content-center align-items-center'>
                <p className='Rubik-Regular text-white m-0'>Signup for Free</p>
              </Link>

              <Link to={'/travel-leads'} target='_blank' className='mt-3 bg-gradient-danger h-38px pointer d-inline-flex px-3 mr-10px rd-6px justify-content-center align-items-center'>
                <p className='Rubik-Regular text-white m-0'>Get Free Leads</p>
              </Link>
            </div>
          </div>

        </Col>
        <Col className='bg-danger1 px-0 mt-30px--md d-flex flex-column align-center justify-end'>
          <img src={`/images/howitworks/${currentImage}`} alt={text} className={`z-0 h-450px h-380px--lg h-300px--xs-g animated-image ${imageTransition}`} />
        </Col>
      </Row>



      <div className=' bg-white position-relative w-100 d-flex align-center flex-column justify-center pt-30px pb-30px '>
        <div className="future-mix-gradient-layer position-absolute top-0 left-0 right-0 bottom-0 z-4 mt-100px"></div>

        <div className='w-95p d-flex justify-center future-box-layer rd-40px position-relative align-center flex-column bg-danger1 z-5'>

          <div className='text-dark Rubik-SemiBold fs-30px mt-60px'>Why Join Us?</div>


          {/* We are offering */}
          <Row id='Doctor' className='w-85p w-90p--xs-g mt-40px bg-gradient-pink-slow d-flex align-center justify-between future-box-layer pt-30px pb-30px'>
            <Col sm={12} md={7} lg={6} xl={5} xxl={5} className='bg1-danger mb-20px--md hover-in-section px-0px--xs px-0px--sm'>
              <div className='text-dark Rubik-Bold fs-26px fs-22px--md fs-18px--xs'>Earn 100%, Post Unlimited Packages, Attract Travelers Easily</div>

              {signupFree.map((item, i) => (
                <div className='d-flex align-center mt-20px'>
                  <img src={`/images/howitworks/tick.png`} alt='tick' className='h-20px' />
                  <div className='ml-10px text-gray Rubik-Regular fs-18px fs-15px--xs'>{item.title}</div>
                </div>
              ))}

              <Link to={'/travel-leads'} className='text-decoration-none hover-know-more mt-20px bg-white h-35px pointer gradient-border d-inline-flex px-3 rd-40px justify-content-center align-items-center'>
                <p className='Rubik-Medium fs-12px m-0'>Get Free Leads</p>
              </Link>
            </Col>

            <Col sm={12} md={5} lg={6} xl={6} xxl={6} className='bg-warning1 p-0 d-flex justify-end justify-center--sm px-0px--xs px-0px--sm'>
              <img src={`https://img.freepik.com/premium-vector/marketing-strategy-concept-businessman-shout-giant-megaphone-promotion_138260-730.jpg`} alt='Transform Your Practice Digitally' className='h-410px rd-10px h-250px--lg h-200px--md-g' />
            </Col>
          </Row>

          {/* <div className='text-dark Rubik-SemiBold fs-30px mt-60px'>How It Works?</div>

          <Row id='Diagnostic' className='diagnostic-home mt-40px bg-danger1 w-85p w-95p--xs-g d-flex align-center justify-between future-box-layer pt-30px pb-30px'>
            <Col sm={12} lg={7} xl={6} className='mmd-12 pt-0 mb-30px--lg order-2--sm'>
              <div className='bg-white shadow-pink rd-20px h-350px h-300px--xs-g pt-10px pl-10px pr-10px pb-0px overflow-hidden position-relative'>
                <div className=' h-50 pl-5px'>
                  <div className='text-muted Inter-Medium w-70p w-60p--md-g text-decoration-none fs-15px fs-14px--xs position-relative'><strong>Signup</strong> with simple steps and by enter basic info. Quick and easy profile complete process.</div>
                  <div className=' d-flex align-end flex-column  w-60p'>
                    <img src={`/images/howitworks/arrow.png`} alt='arrow' className='h-20px w-60px mt-20px' />
                  </div>
                </div>

                <img ref={ref} src={`/images/howitworks/image66.png`} alt='Diagnostic Dashboard 1' className={`h-150px mr-10px h-100px--xs-g diagnostic-1-home`} />

                <div className='d-flex h-50 bg-warning1'>
                  <div className='bg-danger1 d-flex justify-end flex-column '>
                    <img src={`/images/howitworks/image67.png`} alt='Diagnostic Dashboard 2' className={`h-180px h-100px--xs-g mr-10px diagnostic-2-home`} />
                  </div>
                  <div className=' d-flex justify-center flex-column'>
                    <div className='text-gray Inter-Medium fs-15px fs-13px--xs'>Dashboard to Quickly <strong>Add your services</strong> and direct manage.</div>
                    <img src={`/images/howitworks/arrow.png`} alt='arrow' className='h-15px w-60px w-40px--xs-g rotate-arrow mt-10px' />
                  </div>
                </div>
              </div>
            </Col>

            <Col sm={12} lg={5} xl={5} className='mmd-12 bg-danger1 mb-40px order-1--sm hover-in-section'>
              <div className='text-muted Rubik-Bold fs-16px'>For Signup</div>
              <div className='text-dark Rubik-Bold fs-26px fs-22px--md fs-20px--xs'>Sign Up for Free</div>

              <div className='d-flex align-start mt-20px'>
                <img src={`/images/howitworks/tick.png`} alt='tick' className='h-20px pt-5px' />
                <div className='ml-10px text-gray Rubik-Regular fs-18px fs-15px--xs'>Visit <strong>admin.travellinglark.com</strong> and register with basic details.</div>
              </div>

              <div className='d-flex align-center mt-20px'>
                <img src={`/images/howitworks/tick.png`} alt='tick' className='h-20px' />
                <div className='ml-10px text-gray Rubik-Regular fs-18px fs-15px--xs'>No upfront costs or hidden fees.</div>
              </div>

              <div className='d-flex align-center mt-20px'>
                <img src={`/images/howitworks/tick.png`} alt='tick' className='h-20px' />
                <div className='ml-10px text-gray Rubik-Regular fs-18px fs-15px--xs'>Quick and easy registration process.</div>
              </div>

              <Link to={vendor_url} className='text-decoration-none hover-know-more mt-20px bg-white h-35px pointer gradient-border d-inline-flex px-3 rd-40px justify-content-center align-items-center'>
                <p className='Rubik-Medium fs-12px m-0'>Sign Up Now</p>
              </Link>
            </Col>
          </Row>

          <Row id='Pharmacy' className='pharmacy-home mt-60px bg-gradient-pink-slow w-85p w-95p--xs-g d-flex justify-between future-box-layer pt-30px pb-30px'>
            <Col sm={12} md={5} lg={5} xl={5} className='bg-danger1  mt-20px hover-in-section'>
              <div className='text-muted Rubik-Bold fs-16px'>For Profile Creation</div>
              <div className='text-dark Rubik-Bold fs-26px fs-22px--md fs-18px--xs'>Create Your Profile & Get Listed</div>

              {directLeads.map((item, i) => (
                <div className='d-flex align-start justify-center mt-20px'>
                  <img src={`/images/howitworks/tick.png`} alt='tick' className='h-20px pt-5px' />
                  <div className='ml-10px text-gray Rubik-Regular fs-18px fs-14px--xs'>{item.title}</div>
                </div>
              ))}

              <Link to={vendor_url} className='text-decoration-none hover-know-more mt-20px bg-white h-35px pointer gradient-border d-inline-flex px-3 rd-40px justify-content-center align-items-center'>
                <p className='Rubik-Medium fs-12px  m-0'>Complete your profile</p>
              </Link>
            </Col>

            <Col sm={12} md={7} lg={7} xl={5} className='bg-danger1 pt-0 mt-20px mt-40px--xs'>
              <div className='bg-white shadow-sky rd-25px d-flex flex-column align-center h-300px h-250px--xs-g p-25px overflow-hidden'>
                <div className='text-gray Rubik-Regular w-70p text-center fs-15px position-relative'>Our <strong>Platform is</strong> offers <strong>free leads</strong> to drive your growth!</div>
                <div className='d-flex mt-0px gap-1'>
                  <img src={`/images/howitworks/how-1.webp`} alt='Boost Your 1' className='pharmacy-img-1 position-relative h-200px w-130px h-200px--md w-80px--xs-g h-100px--xs-g' />
                  <img src={`/images/howitworks/image69.png`} alt='Boost Your 2' className='pharmacy-img-2 position-relative h-200px w-130px h-200px--md w-80px--xs-g h-100px--xs-g' />
                </div>
              </div>
            </Col>
          </Row>

          <Row id='Hospital' className='mt-70px bg-gradient-pink-slow  w-85p w-93p--xs-g d-flex justify-between future-box-layer pt-20px pb-20px'>
            <Col xs={12} sm={12} md={6} lg={6} xl={5} className='mt-20px order-2--sm pt-0 position-relative'>
              <div className='bg-white shadow-blue rd-25px d-flex align-center justify-between h-300px p-25px overflow-hidden'>
                <img src={`/images/howitworks/offers.jpeg`} alt='Digitize Hospital Care' className='h-300px h-230px--xs h-200px--vs-g' />
                <img src={`/images/howitworks/send_offer.jpeg`} alt='Digitize Hospital Care' className='h-300px h-230px--xs h-200px--vs-g' />
              </div>
            </Col>

            <Col xs={12} sm={12} md={6} lg={6} xl={5} className='mt-20px order-1--sm hover-in-section'>
              <div className='text-muted Rubik-Bold fs-16px'>Submit Offers on Leads</div>
              <div className='text-dark Rubik-Bold fs-26px fs-22px--md fs-20px--xs'>Send Your Offers</div>

              {sendOffers.map((item, i) => (
                <div className='d-flex align-start justify-center mt-20px'>
                  <img src={`/images/howitworks/tick.png`} alt='tick' className='h-20px pt-5px' />
                  <div className='ml-10px text-gray Rubik-Regular fs-18px fs-15px--xs'>{item.title}</div>
                </div>
              ))}

            </Col>
          </Row>

          <Row id='Pharmacy' className='pharmacy-home mt-60px bg-gradient-pink-slow w-85p w-95p--xs-g d-flex justify-between future-box-layer pt-30px pb-30px'>
            <Col sm={12} md={5} lg={5} xl={5} className='bg-danger1  mt-20px hover-in-section'>
              <div className='text-muted Rubik-Bold fs-16px'>Grow Business</div>
              <div className='text-dark Rubik-Bold fs-26px fs-22px--md fs-20px--xs'>Close More Bookings & Grow Your Business</div>

              {closeMoreBooking.map((item, i) => (
                <div className='d-flex align-start mt-20px'>
                  <img src={`/images/howitworks/tick.png`} alt='tick' className='h-20px pt-5px' />
                  <div className='ml-10px text-gray Rubik-Regular fs-18px fs-14px--xs'>{item.title}</div>
                </div>
              ))}

            </Col>

            <Col sm={12} md={7} lg={7} xl={5} className='bg-danger1 pt-0 mt-20px mt-40px--xs'>
              <div className='bg-white shadow-sky rd-25px d-flex flex-column align-center h-300px h-250px--xs-g p-25px overflow-hidden'>
                <img src={`/images/howitworks/grow.webp`} alt='Boost Your Pharmacy 1' className=' position-relative h-280px w-100p h-200px--md' />
              </div>
            </Col>
          </Row> */}
        </div>

        <Helmet>
          <meta charSet="UTF-8" />
          <title>TravellingLark</title>
          {/* <meta name='keywords' content='MediFinder, MediFinder Healthcare, MF' /> */}
        </Helmet>

        <div className='w-95p d-flex justify-center bg-white  position-relative align-center flex-column bg-danger1 z-5'>

          {/* How to */}
          <div className='text-dark Rubik-Medium fs-26px mt-90px'>How to get started</div>

          <Row className='bg-warning1 w-100 mt-20px d-flex flex-wrap'>
            <Col xs={12} sm={4} md={4} lg={3} xl={3} className='mt-25px 1bg-danger d-flex align-center justify-start flex-column'>
              <div className="image-with-inner-shadow h-280px h-200px--sm w-200px w-150px--sm bg-warning1"></div>

              <div className='text-center mt-20px bg-warning1'>
                <GradientText text="Step 1" gradientColors={['#0cc6ff', '#94e6ff']} className="Rubik-Bold fs-15px" />
                <div className='text-gray Rubik-SemiBold fs-19px'>Create your account</div>
                <div className='text-gray Rubik-Regular fs-13px'>by enter basic details</div>
              </div>
            </Col>

            <Col xs={12} sm={4} md={4} lg={3} xl={3} className='mt-25px mt-50px--xs d-flex align-center justify-start flex-column 1bg-warning'>
              <div className="image-with-inner-shadow h-280px h-200px--sm w-200px w-150px--sm" style={{ backgroundImage: "url('/images/howitworks/how-2.webp')" }}></div>

              <div className='text-center mt-20px bg-warning1'>
                <GradientText text="Step 2" gradientColors={['#0cc6ff', '#94e6ff']} className="Rubik-Bold fs-15px" />
                <div className='text-gray Rubik-SemiBold fs-19px'>Choose Services</div>
                <div className='text-gray Rubik-Regular fs-13px'>Create your profile with services</div>
              </div>
            </Col>

            <Col xs={12} sm={4} md={4} lg={3} xl={3} className='mt-25px mt-50px--xs d-flex align-center justify-start flex-column 1bg-danger'>
              <div className="image-with-inner-shadow h-280px h-200px--sm w-200px w-150px--sm" style={{ backgroundImage: "url('/images/howitworks/how-3.webp')" }} s></div>

              <div className='text-center mt-20px bg-warning1'>
                <GradientText text="Step 3" gradientColors={['#0cc6ff', '#94e6ff']} className="Rubik-Bold fs-15px" />
                <div className='text-gray Rubik-SemiBold fs-19px'>Send Your Offers</div>
                <div className='text-gray Rubik-Regular fs-13px'>Now you can send your offer to leads.</div>
              </div>
            </Col>

            <Col xs={12} sm={4} md={4} lg={3} xl={3} className='mt-25px mt-50px--xs d-flex align-center justify-start flex-column 1bg-danger'>
              <div className="image-with-inner-shadow h-280px h-200px--sm w-200px w-150px--sm" style={{ backgroundImage: "url('/images/howitworks/how-4.webp')" }} s></div>

              <div className='text-center mt-20px bg-warning1'>
                <GradientText text="Step 4" gradientColors={['#0cc6ff', '#94e6ff']} direction="to right" className="Rubik-Bold fs-15px" />
                <div className='text-gray Rubik-SemiBold fs-19px'>Connect with Travelers</div>
                <div className='text-gray Rubik-Regular fs-13px'>Chat directly with travelers.</div>
              </div>
            </Col>
          </Row>


          <Link to={vendor_url} target='_blank' className='mt-70px mix-three-gradient h-38px pointer d-inline-flex px-3 mr-10px rd-15px justify-content-center align-items-center'>
            <p className='Rubik-Regular text-white m-0'>Signup for Free</p>
          </Link>

          {/* why choose us  */}
          {/* <div className='text-dark Rubik-Medium fs-26px  mt-70px '>Why Choose Us?</div>

          <Row className='mt-50px w-100'>
            <Col>
              <div className='d-flex align-center flex-column h-280px bg-warning1 '>
                <img src='https://img.freepik.com/premium-vector/vector-digital-illustration-featuring-two-people-standing_990473-8113.jpg' alt='Simplify Your Work' className='h-100px animation-hover hover-to-change-color' />
                <div className='text-gray hover-to-change-color text-center Rubik-Bold fs-16px mt-10px'>1. Sign Up for Free </div>
              </div>
            </Col>

            <Col>
              <div className='d-flex align-center justify-end flex-column h-280px bg-warning1'>
                <img src='https://img.freepik.com/free-vector/id-card-concept-illustration_114360-1314.jpg' alt='Reach More Patients' className='h-100px animation-hover hover-to-change-color' />
                <div className='text-gray hover-to-change-color text-center Rubik-Bold fs-16px mt-10px'>2. Create your profile & get listed</div>
              </div>
            </Col>

            <Col>
              <div className='d-flex align-center flex-column h-280px bg-warning1'>
                <img src='https://img.freepik.com/free-vector/college-admission-concept-illustration_114360-10529.jpg?t=st=1743318365~exp=1743321965~hmac=391b0ffe0275c1d1507c12757539e4e9efed93864f1745c10e288280a02f868f&w=740' alt='Increase Your Earning' className='h-100px animation-hover hover-to-change-color' />
                <div className='text-gray hover-to-change-color text-center Rubik-Bold fs-16px mt-10px'>3. Receive Direct Leads</div>
              </div>
            </Col>

            <Col className=''>
              <div className='d-flex align-center justify-end flex-column h-280px h-200px--xs-g bg-warning1'>
                <img src='https://img.freepik.com/premium-vector/person-phone-illustration_990473-943.jpg' alt='Stay Future Ready' className='h-100px animation-hover hover-to-change-color' />
                <div className='text-gray hover-to-change-color text-center Rubik-Bold fs-19px mt-10px'>4. Send Your Offers</div>
              </div>
            </Col>

            <Col>
              <div className='d-flex align-center flex-column h-280px bg-warning1'>
                <img src='https://img.freepik.com/free-vector/digital-nomad-concept-illustration_114360-1082.jpg?t=st=1743331622~exp=1743335222~hmac=126cc71f31f2f881e58a5bccbc3962cdb101ea06994dcb043c1109b125415412&w=740' alt='Increase Your Earning' className='h-100px animation-hover hover-to-change-color' />
                <div className='text-gray hover-to-change-color text-center Rubik-Bold fs-16px mt-10px'>5. Connect with Travelers</div>
              </div>
            </Col>
          </Row> */}

          <div className=' bg-white position-relative w-100 d-flex align-center flex-column justify-center mt-7 pt-30px pb-30px '>
            <div className='w-95p d-flex justify-center position-relative align-center flex-column bg-danger1 z-5'>
              <div className='gradient-text Rubik-SemiBold fs-35px'>Who We Are?</div>
              <div className='text-muted Rubik-Regular fs-20px fs-18px--xs mt-20px w-80p text-center'>We are <strong>TravellingLark</strong>, a dynamic travel platform connecting travel vendors with travelers. Our mission is to empower agencies by offering 100% earnings, free leads, and unlimited package listings. With TravellingLark, vendors can easily grow their business, reach more customers, and close more bookings.</div>
              <Link to={'/about-us'} className='text-decoration-none read-more Rubik-Medium fs-17px mt-20px pointer text_thm'>
                Read More
                <i className="fas fa-angle-double-down fs-12px ms-2"></i>
              </Link>
            </div>
          </div>

        </div>
      </div>

    </div>
  )
}


export default HomeV