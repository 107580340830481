import React, { createContext, useState, useEffect } from 'react';

// Create context for network status
const NetworkChecking = createContext();

const NetworkProvider = ({ children }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isSlow, setIsSlow] = useState(false); // Optional: for slow connection

  // Update network status
  const updateNetworkStatus = () => {
    setIsOnline(navigator.onLine);
  };

  // Optional: check for slow network
  const checkForSlowNetwork = () => {
    const timeout = 3000; // Timeout in ms
    const testUrl = 'http://localhost:3000'; // A simple test URL

    const startTime = Date.now();

    fetch(testUrl)
      .then(() => {
        const endTime = Date.now();
        if (endTime - startTime > timeout) {
          setIsSlow(true);
        } else {
          setIsSlow(false);
        }
      })
      .catch(() => {
        setIsSlow(false); // If fetch fails, assume not slow, just offline
      });
  };

  // Listen for online/offline events
  useEffect(() => {
    window.addEventListener('online', updateNetworkStatus);
    window.addEventListener('offline', updateNetworkStatus);

    return () => {
      window.removeEventListener('online', updateNetworkStatus);
      window.removeEventListener('offline', updateNetworkStatus);
    };
  }, []);

  // Check for slow network every 5 seconds (optional)
  useEffect(() => {
    const interval = setInterval(() => {
      if (navigator.onLine) {
        checkForSlowNetwork();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, []);


  const setNetworkStatus = (status) => {
    setIsOnline(status.isOnline);
    setIsSlow(status.isSlow);
  };

  return (
    <NetworkChecking.Provider value={{ isOnline, isSlow, setNetworkStatus }}>
      {children}
    </NetworkChecking.Provider>
  );
};

export { NetworkChecking, NetworkProvider };
