import React from 'react'
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PostQueryCommon from './PostQueryCommon';


function AddQuery() {
    const location = useLocation();

    const editItem = location?.state;

    return (
        <div className='bg-primary1 min-vh-100 d-flex flex-column align-items-center mt-12 pt-3 p-0 pb-5'>

            <div className="w_92 mt-4 d-flex align-items-center flex-column w-10 position-relative">
                {/* scrollable content */}
                {/* Hey Travellers Just Enter  */}
                <h1 className="text-center gradient-text Inter-Bold fs-20px">Hey Travellers, </h1>
                <h1 className="text-center Inter-Bold fs-22px">Just enter your <span className='text_thmdark'>upcoming travel</span> requirement</h1>
                    <PostQueryCommon rowClass={'mt-5'} editItem={editItem}/>
            </div>

            <Helmet>
                <meta charSet="UTF-8" />
                <title>Quotation From Travel Vendors | Holiday Packages | Travellinglark</title>
                <meta name='description' content='Get Offers From Multiple Travel Agencies and Holiday Packages With Travellinglark. Tailored Trips Designed for Your Comfort, Convenience, and Unforgettable Experiences.' />
                <meta name="keywords" content="Quotation from Travel Vendors, Personalized Holiday Packages" />
                <link rel="canonical" href="https://www.travellinglark.com/post-travel/" />
            </Helmet>

            <div className='d-flex flex-wrap justify-center container mt-5 bg-warning1'>
                <p className='text_gray Source_Regular me-3'><i className='fa fa-check-circle mr_10 text_thmdark'></i>Post your travel requirement</p>
                <p className='text_gray Source_Regular me-3'><i className='fa fa-check-circle mr_10 text_thmdark'></i>Get quotation from multiple verified vendors</p>
                <p className='text_gray Source_Regular me-3'><i className='fa fa-check-circle mr_10 text_thmdark'></i>Choose suitable</p>
                <p className='text_gray Source_Regular'><i className='fa fa-check-circle mr_10 text_thmdark'></i>Enjoy your trip</p>
            </div>
            <h2 className="Rubik-SemiBold fs_16 mt-4 ms-2">Your Dream Holiday, Your Way – Personalized Packages with Vendor Quotes!</h2>

            <p className='fs-13px Inter-Regular mt-5px w-90p'>
                Get instant travel quotations from travel vendors and compare the best offers for your journey. Discover personalized holiday packages tailored to your preferences and budget. Explore travel best offers for flights, hotels, and complete vacation plans. Avail affordable travel services, including transportation, accommodations, and guided tours, without compromising quality. Whether you’re planning a solo trip, family vacation, or group adventure, our platform connects you with top vendors to ensure a hassle-free and memorable travel experience.
            </p>

            {/* Modal Sigin */}
            {/* <SignInModal isOpen={isModalOpen} onClose={setIsModalOpen} action={handleAutoSubmit}/> */}
        </div>
    )
}

export default AddQuery