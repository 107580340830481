import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import OffcanvasNav from '../Componets/Offcanvas';
import MenuPage from '../Pages/Menu/MenuPage';
import { useDispatch, useSelector } from 'react-redux';
import { svModalStatus } from '../ContextRedux/Action';
import SignInModal from '../Pages/Auth/SignInModal';
import { useChatPopup } from '../ContextRedux/ChatPopupContext';

const BottomNavigation = () => {
    const user1 = useSelector(state => state.data.info)
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const toggleOffcanvas = () => setShowOffcanvas(!showOffcanvas);
    const dispatch = useDispatch()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { isChatOpen, toggleChatPopup } = useChatPopup();
    const chatCount = useSelector(state => state.data.chatCount)

    const closeModal = () => {
        dispatch(svModalStatus({ status: false }))

        setIsModalOpen(false);
    }

    const openToggle = () => {
        if (!user1?.tkn) {
            setIsModalOpen(true)
            return
        }
        toggleChatPopup();
    }

    const home = window.location.pathname === '/'
    const post = window.location.pathname.startsWith('/post');
    const travel = window.location.pathname.startsWith('/travel');
    const menu = window.location.pathname.startsWith('/signin');


    return (
        <div className="bottom-nav">
            <NavLink onClick={()=> isChatOpen && toggleChatPopup()} to="/" className={`bottom-nav-link`}>
                <span className="icon">
                    <i className={`bi bi-house ${home ? 'text_thm' : 'text_gray2'}`}></i>
                </span>
                <span className={home ? 'label show' : 'label text_gray2'}>Home</span>
            </NavLink>

            <NavLink to={'/post-travel'} onClick={()=> isChatOpen && toggleChatPopup()} className={`bottom-nav-link`}>
                <span className="icon">
                    <i className={`bi bi-pencil-square  ${post ? 'text_thm' : 'text_gray2'}`}></i>
                </span>
                <span className={post ? 'label show' : 'label text_gray2'}>Post Your Travel</span>
            </NavLink>

            <div onClick={openToggle} className={`bottom-nav-link position-relative`}>
                <span className="icon">
                    <i className={`bi bi-chat-left-text fs-18px ${menu ? 'text_thm' : 'text_gray2'}`}></i>
                </span>
                <span onClick={openToggle} className={menu ? 'label show' : 'label text_gray2'}>Chat</span>
                {!isChatOpen && chatCount !== 0 && chatCount !== null && chatCount !== undefined && chatCount !== '' && (
                    <div onClick={openToggle} className='h-18px w-18px pointer d-align-justify-center fs-10px bg-danger position-absolute top-0 pr-0 rd-50p text-white'>
                        {chatCount}
                    </div>
                )}
            </div>


            <NavLink onClick={()=> isChatOpen && toggleChatPopup()} to="/travel-leads" className={`bottom-nav-link`}>
                <span className="icon">
                    <i className={`bi bi-list-check ${travel ? 'text_thm' : 'text_gray2'}`}></i>
                </span>
                <span className={travel ? 'label show' : 'label text_gray2'}>Travel Leads</span>
            </NavLink>

            <div onClick={toggleOffcanvas} className={`bottom-nav-link`}>
                <span className="icon">
                    <i className={`bi bi-menu-button-wide fs-18px ${menu ? 'text_thm' : 'text_gray2'}`}></i>
                </span>
                <span className={menu ? 'label show' : 'label text_gray2'}>Menu</span>
            </div>

            {/* Offcanvas Slider open when click on menu toggle button */}
            <MenuPage show={showOffcanvas} handleClose={toggleOffcanvas} />

            <SignInModal isOpen={isModalOpen} onClose={closeModal} redirect={'/post-travel'} />
        </div>
    );
};

export default BottomNavigation;
