import React, { CSSProperties } from 'react';

interface GradientBorderProps {
  children: React.ReactNode;
  gradientColors: string[];
  borderWidth?: number; // Default is 2px
  style?: CSSProperties;
  className?: string;
}

const GradientBorder: React.FC<GradientBorderProps> = ({
  children,
  gradientColors,
  borderWidth = 2,
  style,
  className,
}) => {
  const gradient = `linear-gradient(90deg, ${gradientColors.join(', ')})`;

  return (
    <div
      className={className}
      style={{
        padding: borderWidth,
        borderRadius: '8px', // Optional, for rounded edges
        background: gradient,
        ...style,
      }}
    >
      <div
        style={{
          background: '#fff', // Inner content background
          borderRadius: 'inherit',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default GradientBorder;