import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
// Css
import 'bootstrap/dist/css/bootstrap.min.css';
import 'future-tint/dist/common.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-multi-carousel/lib/styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-toastify/dist/ReactToastify.css';
import 'react-pagination-ultra/dist/style.css';

import './CSS/common.css'
import './CSS/Fonts.css'
import './CSS/Footer.css'
import './CSS/Index.css'

// Components
import RouterIndex from './Routes/RouterParent';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Context from './ContextRedux/Context';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { store } from './ContextRedux/Store';
import { NotificationProvider } from './ContextRedux/NotificationContext';
import { LoadScript } from '@react-google-maps/api';
import { ChatPopupProvider } from './ContextRedux/ChatPopupContext';
import ShowWlcmModal from './Componets/ShowWlcmModal';
import { NetworkProvider } from './ContextRedux/NetworkChecking';
import { ToastContainer } from 'react-toastify';
import { AccountBlockedProvider } from './ContextRedux/AccountBlockedContext';

const g_id = process.env.REACT_APP_G_ID;
const g_map_key = process.env.REACT_APP_G_MAP_KEY;

const root = ReactDOM.createRoot(document.getElementById('root'));

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register(`${process.env.PUBLIC_URL}/service-worker.js`)
    .then((registration) => {
    })
    .catch((error) => {
    });
}

// Request notification permission
// if (Notification.permission !== 'denied') {
//   Notification.requestPermission().then(permission => {
//     if (permission === 'granted') {
//       console.log('Notification permission granted.');
//     } else {
//       console.log('Notification permission denied.');
//     }
//   });
// }

// This is an example for a React app. Make sure this is in your index.js or App.js file.
// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker
//       .register('/service-worker.js')  // Path to your service worker file
//       .then(registration => {
//         console.log('Service Worker registered with scope:', registration.scope);
//       })
//       .catch(error => {
//         console.error('Service Worker registration failed:', error);
//       });
//   });
// }

const libraries = ['places'];


root.render(
  <NetworkProvider>
    <AccountBlockedProvider>
      <Provider store={store}>
        <Context>

          <NotificationProvider>
            <ChatPopupProvider>
              <GoogleOAuthProvider clientId={g_id}>
                <LoadScript googleMapsApiKey={g_map_key} libraries={libraries} loadingElement={<div style={{ display: 'none' }}></div>}>
                  <BrowserRouter>
                    <RouterIndex />
                  </BrowserRouter>
                  <Toaster />
                  <ToastContainer />
                  <ShowWlcmModal />
                </LoadScript>
              </GoogleOAuthProvider>
            </ChatPopupProvider>
          </NotificationProvider>
        </Context>
      </Provider>
    </AccountBlockedProvider>
  </NetworkProvider>
);

// {/* <UserDetail /> */}
// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// if (module.hot) {
//   module.hot.accept();
// }