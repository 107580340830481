import { createContext, useContext, useState } from "react"

const AccountBlockedContext = createContext()

// export const useAccountBlocked = () => {
//     return useContext(AccountBlockedContext)
// }

export const AccountBlockedProvider = ({ children }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    return (
        <AccountBlockedContext.Provider value={{ isModalOpen, openModal, closeModal }}>
            {children}
        </AccountBlockedContext.Provider>
    )
}

export const useAccountBlocked = () => useContext(AccountBlockedContext);