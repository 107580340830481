import React, { useCallback, useEffect, useState } from 'react'
import useTruncation, { HTMLRender } from '../../Componets/HTMLRender';
import { Col, Row } from 'react-bootstrap';
import { dec } from '../../Componets/SecRes';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { openInNewTab } from '../../Componets/CommonFs';
import { CommonAPI } from '../../Componets/CommonAPI';
import { Spin } from 'antd';
import SignInModal from '../Auth/SignInModal';
import { Helmet } from 'react-helmet';
import { TagsSection } from '../../Componets/Modal';
import { PkgContainer, TiredOfSearching } from '../../Componets/Recommended';

const key = process.env.REACT_APP_KEY;
const img_base_url = process.env.REACT_APP_IMG_BASE_URL;


function PkgByCategory() {
  const { parent, type_pkg, slug, id } = useParams();


  const navigate = useNavigate();
  const [isLoading1, setIsLoading1] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    getDetails();
  }, [slug]);

  const types = ['country', 'trip', 'state']

  const getDetails = (tagId) => {

    if (!(types.includes(parent) && type_pkg == 'packages')) {
      navigate('/not-found', { replace: true });
      return
    }

    setIsLoading1(true)
    CommonAPI({}, 'GET', `packageByCategory?id=${id}&type=${parent}`, responseBack)
    CommonAPI({}, 'GET', `topTagsList?type=${parent}&typeId=${id || ''}`, responseBack2)
  }

  const responseBack = useCallback(res => {
    if (res.status === true) {

      const dec1 = dec(res?.data, key);
      setData(dec1)


      if (dec1?.data?.slug !== slug) {
        navigate(`/${parent}/packages/${dec1?.data?.slug}/${id}`)
        setIsLoading1(false)
        return
      }

    } else {
      navigate('/not-found', { replace: true });
    }
    setIsLoading1(false)
  })

  const responseBack2 = useCallback(res => {

    if (res.status === true) {
      const dec1 = dec(res?.data, key)

      setTagsList(dec1)
    } else {

    }
  })

  const details = data?.data;

  const goToPkgList = (type, category) => {
    const pkgHome = localStorage.getItem('pkgHome');
    const pkgHomeParams = JSON.parse(pkgHome)

    const newState = {
      ...pkgHomeParams,
      location: parent == 'trip' ? id : '',
      month: []
    }

    if (type == 'category') {
      localStorage.setItem('searchParams', JSON.stringify(newState));

      openInNewTab(`/packages/${category}`, newState)

      return
    }

    if (newState?.cID) {
      openInNewTab('/packages/list', newState)
    }
  }

  const { contentRef, isTruncated } = useTruncation(details?.about);


  const currency = data?.currency
  const categories = data?.category


  return (
    <div className='d-flex flex-column align-items-center mt-12 pt-3 p-0 bg-gray' style={{ backgroundColor: '#eef4fd' }}>
      {!isLoading1 && details?.image ? (
        <>
          <div className='w_95 mt-4 mb-3 bg-success1'>
            <div className="position-relative rd_10 w-100 h_520px h-250px-sm" style={{ backgroundImage: `url(${img_base_url + details?.image?.replace(/\s+/g, '%20')})`, backgroundPosition: 'top', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }}>
              <div className='rd_10 hoverthis d-flex justify-content-center align-items-center flex-column' style={{
                position: 'absolute',
                top: 0,
                width: '100%',
                height: '100%',
                borderRadius: 10,
                background: 'linear-gradient(to top, rgba(0,0,0,0.10), rgba(0,0,0,0.10)) ',
              }}>

                <div className={`px-3 py-15px w-50p rd-10px scrollable-h scrollable flex-column text-white d-flex align-items-center justify-content-center mt-4`} style={{ background: 'linear-gradient(to top, rgba(0,0,0,0.30), rgba(0,0,0,0.30)) ' }}>
                  <span className='Inter-Bold text-center fs_26 text-white text-capitalize px-1 text-shadow'>{details?.name}</span>

                  <div className='mt-20px'>
                    <span className='Rubik-Light fs_13 mr-10px'>Country: {currency?.country_name}</span>

                    <span className='Rubik-Light fs_13'>Currency: {currency?.symbol} ({currency?.currencyName})</span>
                  </div>


                </div>
              </div>
            </div>

            <Row className='mt-4 w-100 bg-warning1 m-0'>
              <Col md={8} lg={9} className='mb-md-3'>
                <div className='bg-white rd_6 p-3 shadow-slowclean'>
                  <div className='d-flex justify-between align-center'>
                    <div className='Rubik-SemiBold'>About {details?.name}</div>

                      <Link className='Rubik-Regular bg-gradient-dark text-white fs-13px h_35px d-inline-flex px-3 mr_10 rd_6 justify-content-center align-items-center text-capitalize pointer m-0 text-decoration-none' target='_blank' rel='noopener noreferrer' to={`/${parent}/${slug}/${id}`}>
                        <i className="fas fa-location-arrow  me-2 fs_13"></i> View About This {parent == 'trip' ? 'location' : parent}
                      </Link>
                  </div>
                  <div className='divider h_05px mt-2 mb-3'></div>
                  <div className={`content-wrapper Source_Regular ${isExpanded ? '' : 'mini-line-1 ten'}`} ref={contentRef}>
                    <HTMLRender htmlContent={details?.about || ''} />
                  </div>
                  {isTruncated && (
                    <div className='w-100p text-center'>
                      <Link target='_blank' rel='noopener noreferrer' to={`/${parent}/${slug}/${id}`} className='text-decoration-none text_thmdark pointer w-100p text-center fs_12 Inter-Medium mt-1 text-end w-100' onClick={() => setIsExpanded(!isExpanded)}>
                        <i className="fa-solid fa-angles-down mr-10px"></i>
                        See More
                      </Link>
                    </div>
                  )}
                </div>
              </Col>

              <Col className=''>
                <TiredOfSearching/>


              </Col>
            </Row>


            <Helmet>
              <title>{details?.name} | Travellinglark</title>
              {details?.metaDescription && (
                <meta name="description" content={details?.metaDescription} />
              )}
              {details?.metaKeyword && (
                <meta name="keywords" content={details?.metaKeyword} />
              )}
            </Helmet>

            {Object.keys(categories).map((category) => {
              const packages = categories[category];

              if (packages && packages?.length > 0) {
                const slug = category?.toLocaleLowerCase()?.replace(/\s+/g, '-')

                return (
                  <>
                    <div className='w-100p d-flex align-items-center mt-4-5 me-2 justify-content-between'>
                      <div className="Rubik-SemiBold fs_17 ms-2 flex-grow-1">{category}</div>
                      <div className='d-flex align-items-center pointer' onClick={() => goToPkgList('category', slug)}>
                        <div className='Rubik-Regular text_thmdark fs_14'>View All</div>
                        <i className="fa-solid fa-chevron-right ms-2 fs_14 bg-gradient-thm hw_20px rd_50 text-white d-flex align-items-center justify-content-center"></i>
                      </div>
                    </div>

                    <PkgContainer pkgsList={packages} />
                  </>
                )
              }
            })}
          </div>

          {/* 
          <PkgContainer pkgsList={}/>
          <PkgContainer pkgsList={}/>
          <PkgContainer pkgsList={}/> */}


          <TagsSection tags={tagsList} cName={currency?.country_name} hideIs={'location'} />
        </>
      ) : (
        <div className='d-flex align-items-center justify-content-center mb-4 vh-100 w-100' style={{ background: 'rgba(0,0,0,0.2)' }}>
          <Spin className='bg-white rd_10 hw_80px d-flex align-items-center justify-content-center thm-spin' />
        </div>
      )}

      {/* Modal Sigin */}
      <SignInModal isOpen={isModalOpen} onClose={setIsModalOpen} />
    </div>
  )
}

export default PkgByCategory