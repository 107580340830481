import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Col, FormControl, InputGroup, Row, Spinner } from 'react-bootstrap'
import { Link, useLocation, useParams } from 'react-router-dom'
import { CommonAPI } from '../../Componets/CommonAPI';
import { dec } from '../../Componets/SecRes';
import { Modal, Spin } from 'antd';
import { FilterPackages } from '../Hotels/FilterSection';
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux';
import { svTripCate } from '../../ContextRedux/Action';
import { checkSlug, useCountryGet } from '../../Componets/CommonFs';
import { TagsSection } from '../../Componets/Modal';



const img_base_url = process.env.REACT_APP_IMG_BASE_URL;
const key = process.env.REACT_APP_KEY;

function PkgList() {
  const { category } = useParams();
  const location = useLocation();
  const pathname = location.pathname;



  const [state, setState] = useState({ cID: '', month: [], location: '', categoryId: [] })
  const [page, setPage] = useState(1)
  const [search1, setSearch1] = useState('')
  const [country, setCountry] = useState('')
  const [countries, setCountries] = useState([]);
  const [list, setList] = useState([])
  const [list1, setList1] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [reload, setReload] = useState(0);
  const [loadMore, setLoadMore] = useState(true);
  const [selectedValues, setSelectedValues] = useState([100, 100000]);
  const [open1, setOpen1] = useState(false);
  const [tagsList, setTagsList] = useState([]);
  const categories = useSelector(reducer => reducer.data.category);
  const [open2, setOpen2] = useState(false);

  const elementRef = useRef(null);
  const { country1 } = useCountryGet();
  const dispatch = useDispatch();

  const filterId = countries?.filter(item => item.name === country1)?.[0]


  useEffect(() => {
    countryList();
  }, []);

  const countryList = () => {
    setIsLoading2(true)
    CommonAPI({}, 'GET', `countryList`, responseBack2)
    CommonAPI({}, 'GET', `tripCategoryList`, responseBack4)
  }

  const responseBack2 = useCallback(res => {
    if (res.status === true) {
      const dt = res?.data
      
      setCountries(dt)
    } else {
    }
  })

  const responseBack4 = useCallback(res => {
    setIsLoading2(true)
    if (res.status === true) {
      const dec1 = dec(res?.data, key)

      const categories = dec1?.tripcategory || [];
      dispatch(svTripCate(categories))
    } else {
    }

  })

  const { slugSatus, slugId } = checkSlug(category, categories)


  useEffect(() => {
    if (pathname !== '/packages/list') {
      if (!(slugSatus && isLoading && isLoading2)) {
        
        // navigate('/not-found', { replace: true });
        return
      }
    }
  }, []);




  useEffect(() => {
    const selected = localStorage.getItem('searchParams');
    const states = JSON.parse(selected);

    const stateVal = (states?.cID == '' || states == null) ? state : states

    setState(stateVal)
    packageList(stateVal)
  }, [page, reload, search1]);

  useEffect(() => {
    const selected = localStorage.getItem('searchParams');
    const states = JSON.parse(selected);

    if (filterId) {
      if (!states?.cID) {
        const state = { cID: filterId?._id, month: [], location: '', categoryId: [] }
        packageList(state)
      }
    }
  }, [filterId])


  const packageList = (states) => {
    setIsLoading2(false)
    setIsLoading(true)

    CommonAPI({}, 'GET', `topTagsList?type=country&typeId=${Number(states.digit) || ''}`, responseBack3)
    CommonAPI({}, 'GET', `packageList?countryId=${states.cID || filterId}&location=${states.location || ''}&query=${search1 ? search1 : ''}&pricefilter=${selectedValues[1] == '' || selectedValues.length == 0 ? JSON.stringify([100, 220000]) : JSON.stringify(selectedValues)}&month=${states.month.length ? JSON.stringify(states.month) : ""}&categoryId=${states.categoryId?.length > 0 ? JSON.stringify(states.categoryId) : slugSatus ? JSON.stringify([slugId]) : []}&page=${page}`, responseBack)
  }

  const responseBack = useCallback(res => {
    if (res.status === true) {
      const dt = res.data && res?.data;
      const dec1 = dec(dt?.data, key)
      const data = dec1?.packagefilter
      setLoadMore(true);


      setList1(dec1?.locations)
      if (data == undefined || data == null) {
        setList(data)
      } else {
        setList(prevData => {
          const newData = data.filter(item => !prevData.some(existingItem => existingItem._id === item._id));
          return [...prevData, ...newData];
        });
      }

      if (data.length < 12) {
        setLoadMore(false);
      }

    }
    setIsLoading1(false)
    setIsLoading(false)
  })

  const responseBack3 = useCallback(res => {
    if (res.status === true) {
      const dec1 = dec(res?.data, key)
      setTagsList(dec1)
    } else {
    }
  })


  const handleShowMore = () => {
    setIsLoading1(true)
    setPage(prevPage => prevPage + 1)
  };

  const handleCheckboxChange = (value, type, type1) => {
    setList([])
    setPage(1)
    const rn = Math.floor(Math.random() * 9999 + 1)
    setReload(rn)

    if (type == 'rng') {
      setSelectedValues(value)
    } else if (type1 == 'other') {
      setState(prevValues => {
        const prev = prevValues?.[type] || []
        if (prev?.includes(value)) {
          const newState = {
            ...prevValues,
            [type]: prev.filter(item => item !== value)
          }

          localStorage.setItem('searchParams', JSON.stringify(newState));
          return newState
        } else {
          const newState = {
            ...prevValues,
            [type]: [...prev, value]
          }
          localStorage.setItem('searchParams', JSON.stringify(newState));
          return newState
        }
      });
    } else {

      setState(prevValues => {
        const resetMonth = type == 'cID' ? { month: [], location: '' } : null

        const newState = {
          ...prevValues,
          ...resetMonth,
          [type]: value == 'TL' ? '' : value
        }

        localStorage.setItem('searchParams', JSON.stringify(newState));
        return newState
      });
    }
  };


  const data = localStorage.getItem('searchParams');
  const stateVal = JSON.parse(data);

  const customStyle = {
    placeholder: (provied) => ({
      ...provied,
      fontSize: '12px'
    }),
    singleValue: (provied) => ({
      ...provied,
      fontSize: '12px'
    }),
    option: (provided) => ({
      ...provided,
      fontSize: '12px',
    }),
    menuPortal: base => ({ ...base, zIndex: 98889999, })
  }

  const options = countries.map((item) => ({
    value: item._id,
    digit: item.id,
    label: item.name
  }))


  const handleOk = (ty) => {
    setTimeout(() => {
      setOpen1(false);
      setOpen2(false);
    }, 1000);
  };

  const filterLocation = list1?.filter((item) => stateVal?.location?.includes(item._id))
  const filterCate = categories?.filter((item) => stateVal?.categoryId?.includes(item._id))



  return (
    <div className='min-vh-100 mt-12 pt-2 d-flex flex-column align-items-center bg-gray'>

      <div className='w_92 mt-4 mb-5 d-flex align-items-center flex-column'>

        <Row className='mt-4 w-100 mt-0px--sm' ref={elementRef}>
          <Col xs={0} sm={0} md={5} lg={4} xl={3} className='d-none-on-lg h-auto'>
            <FilterPackages selectedValues={stateVal} locations={list1 || []} country={country} onChange={handleCheckboxChange} slugStatus={slugSatus} />
          </Col>
          <Col xs={12} sm={12} md={12} lg={8} xl={9} className='bg-primary1 pt-0 px-0 d-flex justify-content-start align-items-center flex-column'>
            <div className='bg-white d-flex align-items-center flex-column flex-sm-row rd_6 p-3 w_97s mx-0-7 mb-4'>
              <div className='bg-warning1 d-flex w-md-100 me-sm-2'>
                <div className='h-100 p-0 w_150px w-md-200px w-md-100 me-2 d-hide-on-lg'>
                  <Select
                    options={options}
                    value={options.find(val => val.value === stateVal?.cID)}
                    placeholder='Select Country'
                    styles={customStyle}
                    classNamePrefix="custom-select h-100"
                    menuPortalTarget={document.body}
                    onChange={(val) => { handleCheckboxChange(val.value, 'cID'); handleCheckboxChange(val.digit, 'digit'); setCountry(val.label); }} />
                </div>
                <div className='d-show-on-mdl'>
                  <div className='d-flex'>
                    <p className='Source_Medium text-white m-0px mr-10px px-3 rd_6 fs-13px h-35px bg-gradient-thm d-flex align-items-center justify-content-center pointer' onClick={() => setOpen2(true)}><i className='bi bi-search fs_13 mr_5'></i> SEARCH</p>
                    <p className='Source_Medium text-white m-0 px-3 rd_6 fs-13px h-35px bg-gradient-thm d-flex align-items-center justify-content-center pointer' onClick={() => setOpen1(true)}>FILTER <i className='bi bi-filter ml-10px'></i></p>
                  </div>
                </div>
              </div>
              <div className='bg-primary11 p-0  flex-grow-1 w-md-100 mb-xs1-20 d-hide-on-lg'>
                <InputGroup className=''>
                  <InputGroup.Text className='input-group-text pointer h_40px'>
                    <i className='fa fa-search text_gray'></i>
                  </InputGroup.Text>
                  <FormControl
                    className='search-input text_gray fs_13 rd_tr_br_6 px-2 h_40px'
                    type='text'
                    placeholder='Search'
                    style={{ height: 35, color: 'red' }}
                    value={search1}
                    onChange={(event) => { setList([]); setSearch1(event.target.value); setPage(1) }}
                  />
                </InputGroup>
              </div>


              <div className='w-100 d-show-on-sm'>
                {filterLocation?.length > 0 && (
                  <div className='d-flex gap-2 mt-1 scroll-h hide-scroll-indi'>
                    {filterLocation?.map((item) => (
                      <div className='d_flex_align_justify_center text-muted px-2 p-1 rd_50 Inter-Regular bg_lightgray' onClick={() => handleCheckboxChange('', 'location')}>
                        <p className='m-0 fs_11'>{item.name}</p>
                        <i className="fa-solid fa-xmark fs_11 ms-2"></i>
                      </div>
                    ))}
                  </div>
                )}
                {stateVal?.month?.length > 0 && (
                  <div className='d-flex gap-2 mt-2 scroll-h hide-scroll-indi'>
                    {stateVal?.month?.map((name) => (
                      <div className='d_flex_align_justify_center text-muted px-2 p-1 rd_50 Inter-Regular bg_lightgray' onClick={() => handleCheckboxChange(name, 'month', 'other')}>
                        <p className='m-0 fs_11'>{name}</p>
                        <i className="fa-solid fa-xmark fs_11 ms-2"></i>
                      </div>
                    ))}
                  </div>
                )}
                {filterCate?.length > 0 && (
                  <div className='d-flex gap-2 mt-2 scroll-h hide-scroll-indi'>
                    {filterCate?.map((item) => (
                      <div className='d_flex_align_justify_center text-muted px-2 p-1 rd_50 Inter-Regular bg_lightgray' onClick={() => handleCheckboxChange(item._id, 'categoryId', 'other')}>
                        <p className='m-0 fs_11'>{item.name}</p>
                        <i className="fa-solid fa-xmark fs_11 ms-2"></i>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>


            {isLoading && (
              <div className='d-flex justify-content-center mb-4'>
                <Spin className='thm-spin' />
              </div>
            )}

            <Row className='d-flex justify-sm-center w-100'>
              {/* Listing */}
              {list && list.map((item, i) => {
                const lowPrice = item.discountPrice
                const final = (lowPrice == null || lowPrice == 0) ? item.price : lowPrice
                const vendor = item?.vendorDetails
                return (
                  <Col xs={12} sm={12} lg={6} xl={4} xxl={4} key={i} className="xmd-6 m-xxs-auto mb-4 w-xxs-99 px-2 px-xs-0">
                    <div className="package m-1 mt-0 position-relative rd_10 bg-white mb-xs-20 overflow-hidden  pkg-btn-hover">
                      <Link target='_blank' rel="noopener noreferrer" to={`/package/${item?.slug}/${item.id}`} className='text-decoration-none hover-me'>
                        <img src={`${img_base_url + item.image}`} alt={item.name} className='img-fluid mty-image h_150px w-100 position-relative overflow-hidden' />
                        <div className='p-1 px-2-5' style={{
                          position: 'absolute',
                          top: 0,
                          width: 'auto',
                          height: 'auto',
                          borderBottomRightRadius: 10,
                          background: 'rgba(0,0,0,0.3)'
                        }}>
                          <p className='Source_Regular text-white fs_11 m-0 text-capitalize'>{item.duration}</p>
                        </div>

                        <div className="p-3 overflow-hidden position-relative">
                          <h3 className='Rubik-SemiBold text_gray fs_13 spacing text-capitalize min-line two m-0'>{item.name}</h3>


                          <div className='d-flex'>
                            <p className='NunitoSans-Regular text_gray2 fs_11 mini-line-1'>By <span className='text_thm NunitoSans-SemiBold'>{vendor?.business_name}</span></p>
                          </div>

                          <div className='d-flex align-items-center justify-content-between mt-20px '>
                            <div>
                              <p className='Rubik-Regular fs-10px text_gray2 mini-line-1 m-0'>Starting Price</p>
                              <div className='Rubik-Regular fs_13 text-muted'><strong className='text_dark_medium fs-15px mini-line one'>{item?.currency?.code}{final}</strong>/Person</div>
                              {lowPrice != null && lowPrice > 0 && (
                                <div className='Rubik-Regular fs_12 TxtCut text-secondary'>{item?.currency?.code}{item.price}</div>
                              )}
                            </div>

                            <div className='book-now border_thmdark rounded-5 fs_12  text-nowrap h_30px Rubik-Light d-flex align-items-center px-2-5 text_thm'>View Details</div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </Col>
                )
              })}

              {loadMore && !isLoading && list && list?.length > 0 && (
                <div className='d_flex_align_justify_center  mt-4'>
                  <button className='btn bg_thm text-white w_150px fs_12' disabled={isLoading1} onClick={handleShowMore}>{isLoading1 ? <Spinner size='sm' /> : 'Load More'}</button>
                </div>
              )}

              {(!isLoading && !isLoading && list && list?.length == 0) && (
                <div className='d_flex_align_justify_center flex-column mt-5'>
                  <img src='/images/not_data.webp' alt='not found' className='img-fluid h_130px w_200px mt-5' />
                  <div className='Rubik-Regular text_gray2 mt-2'>No packages available</div>
                </div>
              )}
            </Row>
          </Col>
        </Row>

        <Modal
          title="FILTER"
          style={{ top: '8%' }}
          styles={{
            header: {
              padding: '10px'
            },
            body: {
              overflowY: 'auto', maxHeight: '65vh',
              paddingBottom: '10px'
            }
          }}
          footer={null}
          className='Source_Medium text_gray w-xs-auto mx-xs-4 scrollable-width'
          open={open1}
          onOk={handleOk}
          onCancel={() => setOpen1(false)}
        >
          <div className="scrollable">
            <FilterPackages selectedValues={stateVal} locations={list1 || []} onChange={handleCheckboxChange} />
          </div>
        </Modal>

        <Modal
          title="Search"
          className='Source_Medium text_gray w_60 w-xs-auto mx-xs-4'
          styles={{
            body: {
              padding: '15px', height: 'auto'
            },
            header: {
              padding: '10px 10px 0 10px',
            },
            footer: {
              padding: '10px'
            }
          }}
          open={open2}
          onOk={handleOk}
          footer={null}
          onCancel={() => setOpen2(false)}
        >
          <div className='bg-warnin1g  align-items-end mr_10 mt-3'>
            <Row className='d-flex justify-content-between'>
              <Col lg={3} md={6} sm={6} xs={12} className='bg1-warning mb-2'>
                <Select
                  options={options}
                  value={options.find(val => val.value === stateVal?.cID)}
                  placeholder='Select Country'
                  styles={customStyle}
                  classNamePrefix="custom-select h-100"
                  menuPortalTarget={document.body}
                  onChange={(val) => { handleCheckboxChange(val.value, 'cID'); handleCheckboxChange(val.digit, 'digit'); setCountry(val.label); }} />
              </Col>
              <Col lg={3} md={6} sm={6} xs={12} className='mb-2 bg1-warning'>
                <input
                  type='text'
                  placeholder='Search'
                  style={{ width: '100%', padding: '10px' }}
                  className='form-control bg-warning1 fs_13 h_38px rd_10 border_gray Rubik-Regular text_dark_medium'
                  value={search1}
                  onChange={(event) => { setList([]); setSearch1(event.target.value); setPage(1) }}
                />
              </Col>

            </Row>
          </div>
        </Modal>

      </div>

      <TagsSection tags={tagsList} cName={stateVal?.cName} />

    </div>
  )
}

export default PkgList