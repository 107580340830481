import moment from "moment";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";


export const dateFormat = (dt, type) => {
    const date = new Date(dt);
    if (type == 'date') {
        const formatted = date.toLocaleString('en-GB', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
        })
        return formatted
    } else if (type == 'dmy') {
        const formatted = date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        })
        return formatted
    } else if (type == 'hhmm') {
        const formatted = date.toLocaleString('en-GB', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        })
        return formatted
    } else {
        const formatted = date.toLocaleString('en', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        })

        return formatted
    }
}

const timeAgo = (time) => {
    const timeDifferenceInSec = (new Date() - new Date(time)) / 1000;
    if (timeDifferenceInSec >= 86400) return `${Math.floor(timeDifferenceInSec / 86400)} day's ago`
    if (timeDifferenceInSec >= 3600) return `${Math.floor(timeDifferenceInSec / 3600)} hour's ago`
    if (timeDifferenceInSec >= 60) return `${Math.floor(timeDifferenceInSec / 60)} minute's ago`
    return "a few seconds ago"
}


const disabledDate = (current) => {
    return current && current < moment().startOf('day')
}

const generateRandomText = (minLength, maxLength) => {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789 ';
    const length = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;
    let result = '';

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
    }

    return result;
};

const openInNewTab = (url, params) => {
    // const sessionKey = `${Date.now()}`

    // sessionStorage.setItem(sessionKey,JSON.stringify(params));
    // window.open(`${url}?search=${sessionKey}`, '_blank')

    localStorage.setItem('searchParams', JSON.stringify(params));
    window.open(url, '_blank');
}

const avgRatingCalculate = (reviews) => {
    let total = 0;
    let weightSum = 0;

    reviews.forEach(review => {
        total += 1;
        weightSum += Math.floor(review.rating);
    });

    return total > 0 ? (weightSum / total).toFixed() : 0;
}

const reviewOfSummary = (reviews) => {
    const ratingCounts = Array(5).fill(0);
    let totalRating = 0;

    reviews.forEach(review => {
        const rating = Number(review.rating)
        if (rating >= 1 && rating <= 5) {
            const round = Math.round(rating)
            ratingCounts[round - 1] += 1;
            totalRating += rating
        }
    });

    const totalReviews = reviews.length;
    const avgRating = totalReviews > 0 ? (totalRating / totalReviews).toFixed(1) : 0

    const summary = ratingCounts.map((count, index) => {
        const value = totalReviews > 0 ? (count / totalReviews) * 100 : 0;
        return { rating: index + 1, value, count };
    });

    return { summary, avgRating }
}


const useCountryGet = () => {
    const [country1, setCountry] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchLocation = async () => {
            try {
                const response = await fetch(`https://get.geojs.io/v1/ip/geo.json`);
                const data = await response.json();

                setCountry(data.country);
            } catch (error) {
                console.error('Fetch error:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchLocation();
    }, []);

    return { country1, loading };
};

const customStyle = {
    placeholder: (provied) => ({
        ...provied,
        fontSize: '12px'
    }),
    singleValue: (provied) => ({
        ...provied,
        fontSize: '12px'
    }),
    option: (provided) => ({
        ...provided,
        fontSize: '12px',
    }),
    menuPortal: base => ({ ...base, zIndex: 98889999, }),
    control: (provided) => ({
        ...provided,
        height: '35px',
        minHeight: '35px',
        borderColor: 'transparent',
        boxShadow: 'none',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: 0,
    }),
    menuList: (provided) => ({
        ...provided,
        fontSize: 12,
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    }),
}

const lookingForOptions = ['Holiday Packages', 'Hotel', 'Vehicle', 'Guider', 'Photographer', 'Tickets', 'Visa', 'Activities'];


const carTypes = [
    { icon: '/icons/tempo.png', name: 'Tempo Traveller', id: '6753bd96553d654548c3c726' },
    { icon: '/icons/sedan.png', name: 'Sedan', id: '6753b87e553d654548c3c71a' },
    { icon: '/icons/suv1.png', name: 'SUV', id: '6753bbc2553d654548c3c720' },
    { icon: '/icons/hatchback-car.png', name: 'Hatchback', id: '6753bb7b553d654548c3c71d' },
    { icon: '/icons/bus.png', name: 'Bus', id: '6753bd17553d654548c3c723' },
    { icon: '/icons/bike.png', name: 'Bike/Scooty', id: '6753be4e553d654548c3c72f' },
];

const fuelType = [
    { name: 'DISEL', id: 1 },
    { name: 'PETROL', id: 2 },
    { name: 'CNG', id: 3 },
    { name: 'ELECTRIC', id: 4 },
    { name: 'PETROL-CNG', id: 5 },
    { name: 'OTHER', id: 6 }
];

const amenitiesArray = [
    { name: 'Water', id: 1 },
    { name: 'Mobile Charging', id: 2 },
    { name: 'Newspaper', id: 3 },
    { name: 'Sanitizer', id: 4 },
    { name: 'Music', id: 5 },
];

const checkSlug = (slug, categories) => {

    let slugSatus = false;
    let slugId = '';
    categories?.forEach((category) => {
        const cateSlug = category.name.toLowerCase().replace(/\s+/g, '-');
        if (cateSlug === slug) {
            slugSatus = true;
            slugId = category._id
        }
    })

    return { slugSatus, slugId }
}

// const countriesOptions = [
//     {
//         "value": "66f50a3818c9a3c6d4f4dbde",
//         "label": "Afghanistan"
//     },
//     {
//         "value": "66f51d8d18c9a3c6d4f4dbe2",
//         "label": "Albania"
//     },
//     {
//         "value": "66f51f8d18c9a3c6d4f4dbe6",
//         "label": "Algeria"
//     },
//     {
//         "value": "66f5205318c9a3c6d4f4dbea",
//         "label": "Andorra"
//     },
//     {
//         "value": "66f521c718c9a3c6d4f4dbee",
//         "label": "Angola"
//     },
//     {
//         "value": "66f5228318c9a3c6d4f4dbf2",
//         "label": "Antigua and Barbuda"
//     },
//     {
//         "value": "66b1d2d452e0e474b79b6a54",
//         "label": "Argentina"
//     },
//     {
//         "value": "66f241ed8cae9fb7dde19bf1",
//         "label": "Armenia"
//     },
//     {
//         "value": "66accc662a29f0a20896b27b",
//         "label": "Australia"
//     },
//     {
//         "value": "66f0131cc700af86f7cb3f57",
//         "label": "Austria"
//     },
//     {
//         "value": "66f2443f8cae9fb7dde19c0d",
//         "label": "Azerbaijan"
//     },
//     {
//         "value": "66f5238418c9a3c6d4f4dbf6",
//         "label": "Bahamas"
//     },
//     {
//         "value": "66f245688cae9fb7dde19c11",
//         "label": "Bahrain"
//     },
//     {
//         "value": "66f524c118c9a3c6d4f4dbfa",
//         "label": "Bangladesh"
//     },
//     {
//         "value": "66f5258e18c9a3c6d4f4dbfe",
//         "label": "Barbados"
//     },
//     {
//         "value": "66f2e7f6bb5b9582252eef26",
//         "label": "Belarus"
//     },
//     {
//         "value": "66f2db56bb5b9582252eeef7",
//         "label": "Belgium"
//     },
//     {
//         "value": "66f5268b18c9a3c6d4f4dc02",
//         "label": "Belize"
//     },
//     {
//         "value": "66f4baac18c9a3c6d4f4db59",
//         "label": "Benin"
//     },
//     {
//         "value": "66f04061c700af86f7cb4451",
//         "label": "Bhutan"
//     },
//     {
//         "value": "66f5279418c9a3c6d4f4dc06",
//         "label": "Bolivia"
//     },
//     {
//         "value": "66f3774f71a18706fbd3adee",
//         "label": "Bosnia and Herzegovina"
//     },
//     {
//         "value": "66f4a91b71a18706fbd3ae5a",
//         "label": "Botswana"
//     },
//     {
//         "value": "66accdcb2a29f0a20896b2a6",
//         "label": "Brazil"
//     },
//     {
//         "value": "66f4d17e18c9a3c6d4f4db88",
//         "label": "Brunei"
//     },
//     {
//         "value": "66f2e029bb5b9582252eef07",
//         "label": "Bulgaria"
//     },
//     {
//         "value": "66f4ae0471a18706fbd3ae6a",
//         "label": "Burkina Faso"
//     },
//     {
//         "value": "66f5292b18c9a3c6d4f4dc0a",
//         "label": "Burundi"
//     },
//     {
//         "value": "66f03418c700af86f7cb442d",
//         "label": "Cambodia"
//     },
//     {
//         "value": "66f52cbf18c9a3c6d4f4dc16",
//         "label": "Cameroon"
//     },
//     {
//         "value": "66accd422a29f0a20896b291",
//         "label": "Canada"
//     },
//     {
//         "value": "66f52bb218c9a3c6d4f4dc12",
//         "label": "Cape Verde"
//     },
//     {
//         "value": "66f5782a18c9a3c6d4f4dc32",
//         "label": "Central African Republic"
//     },
//     {
//         "value": "66f4cd3618c9a3c6d4f4db78",
//         "label": "Chad"
//     },
//     {
//         "value": "66f2e4e2bb5b9582252eef1a",
//         "label": "Chile"
//     },
//     {
//         "value": "66b1d2d452e0e474b79b6a48",
//         "label": "China"
//     },
//     {
//         "value": "66f0150ac700af86f7cb3f77",
//         "label": "Colombia"
//     },
//     {
//         "value": "66f5796618c9a3c6d4f4dc36",
//         "label": "Comoros"
//     },
//     {
//         "value": "66f046b1c700af86f7cb4465",
//         "label": "Costa Rica"
//     },
//     {
//         "value": "66f00f05c700af86f7cb3f48",
//         "label": "Croatia"
//     },
//     {
//         "value": "66f57e1f18c9a3c6d4f4dc3e",
//         "label": "Cuba"
//     },
//     {
//         "value": "66f57fe118c9a3c6d4f4dc42",
//         "label": "Cyprus"
//     },
//     {
//         "value": "66f03b0ac700af86f7cb4441",
//         "label": "Czech Republic"
//     },
//     {
//         "value": "66f5821a18c9a3c6d4f4dc49",
//         "label": "Democratic Republic of the Congo"
//     },
//     {
//         "value": "66f2d39fbb5b9582252eeee7",
//         "label": "Denmark"
//     },
//     {
//         "value": "66f584a818c9a3c6d4f4dc4d",
//         "label": "Djibouti"
//     },
//     {
//         "value": "66f2e3efbb5b9582252eef16",
//         "label": "Dominican Republic"
//     },
//     {
//         "value": "66f03bcec700af86f7cb4445",
//         "label": "Dubai, United Arab Emirates (UAE)"
//     },
//     {
//         "value": "66f5860d18c9a3c6d4f4dc51",
//         "label": "Ecuador"
//     },
//     {
//         "value": "66b1d2d452e0e474b79b6a58",
//         "label": "Egypt"
//     },
//     {
//         "value": "66f587e618c9a3c6d4f4dc55",
//         "label": "El Salvador"
//     },
//     {
//         "value": "66af202e680c82111a7e34b9",
//         "label": "England"
//     },
//     {
//         "value": "66f5896b18c9a3c6d4f4dc5a",
//         "label": "Equatorial Guinea"
//     },
//     {
//         "value": "66f58a4f18c9a3c6d4f4dc5e",
//         "label": "Eritrea"
//     },
//     {
//         "value": "66f2f23f71a18706fbd3adc2",
//         "label": "Estonia"
//     },
//     {
//         "value": "66f4cfef18c9a3c6d4f4db80",
//         "label": "Eswatini"
//     },
//     {
//         "value": "66f58c7418c9a3c6d4f4dc62",
//         "label": "Ethiopia"
//     },
//     {
//         "value": "66f58d4418c9a3c6d4f4dc66",
//         "label": "Fiji"
//     },
//     {
//         "value": "66f2dde1bb5b9582252eeeff",
//         "label": "Finland"
//     },
//     {
//         "value": "66b1d2d452e0e474b79b6a3e",
//         "label": "France"
//     },
//     {
//         "value": "66f4ac2371a18706fbd3ae62",
//         "label": "Gabon"
//     },
//     {
//         "value": "66f4d25b18c9a3c6d4f4db8c",
//         "label": "Gambia"
//     },
//     {
//         "value": "66f0353ac700af86f7cb4431",
//         "label": "Georgia"
//     },
//     {
//         "value": "66af2052680c82111a7e34bd",
//         "label": "Germany"
//     },
//     {
//         "value": "66f37d2c71a18706fbd3adfe",
//         "label": "Ghana"
//     },
//     {
//         "value": "66efffcdacfb5411bf02f3c8",
//         "label": "Greece"
//     },
//     {
//         "value": "66f58e5f18c9a3c6d4f4dc6a",
//         "label": "Grenada"
//     },
//     {
//         "value": "66f6011b18c9a3c6d4f4dc86",
//         "label": "Guatemala"
//     },
//     {
//         "value": "66f4af5171a18706fbd3ae6e",
//         "label": "Guinea"
//     },
//     {
//         "value": "66f6031618c9a3c6d4f4dc8a",
//         "label": "Guinea-Bissau"
//     },
//     {
//         "value": "66f6047b18c9a3c6d4f4dc8e",
//         "label": "Guyana"
//     },
//     {
//         "value": "66f4cbe718c9a3c6d4f4db74",
//         "label": "Haiti"
//     },
//     {
//         "value": "66f6061318c9a3c6d4f4dc92",
//         "label": "Holy See"
//     },
//     {
//         "value": "66f6096918c9a3c6d4f4dc96",
//         "label": "Honduras"
//     },
//     {
//         "value": "66f010bcc700af86f7cb3f4d",
//         "label": "Hong Kong"
//     },
//     {
//         "value": "66f2e1a4bb5b9582252eef0b",
//         "label": "Hungary"
//     },
//     {
//         "value": "66f3663f71a18706fbd3ade6",
//         "label": "Iceland"
//     },
//     {
//         "value": "66a1e7b2e3e1ced4992a2617",
//         "label": "India"
//     },
//     {
//         "value": "66f03679c700af86f7cb4435",
//         "label": "Indonesia"
//     },
//     {
//         "value": "66f60a7e18c9a3c6d4f4dc9a",
//         "label": "Iran"
//     },
//     {
//         "value": "66f60fde18c9a3c6d4f4dc9e",
//         "label": "Iraq"
//     },
//     {
//         "value": "66f2d88fbb5b9582252eeeef",
//         "label": "Ireland"
//     },
//     {
//         "value": "66f01f1ec700af86f7cb4425",
//         "label": "Israel"
//     },
//     {
//         "value": "66b1d2d452e0e474b79b6a40",
//         "label": "Italy"
//     },
//     {
//         "value": "66f52a1918c9a3c6d4f4dc0e",
//         "label": "Ivory Coast"
//     },
//     {
//         "value": "66f04219c700af86f7cb4455",
//         "label": "Jamaica"
//     },
//     {
//         "value": "66af2080680c82111a7e34c1",
//         "label": "Japan"
//     },
//     {
//         "value": "66f03e16c700af86f7cb444d",
//         "label": "Jordan"
//     },
//     {
//         "value": "66f2e958bb5b9582252eef2a",
//         "label": "Kazakhstan"
//     },
//     {
//         "value": "66f6112818c9a3c6d4f4dca2",
//         "label": "Kenya"
//     },
//     {
//         "value": "66f6127118c9a3c6d4f4dca6",
//         "label": "Kiribati"
//     },
//     {
//         "value": "66f613a818c9a3c6d4f4dcaa",
//         "label": "Kuwait"
//     },
//     {
//         "value": "66f380e371a18706fbd3ae06",
//         "label": "Kyrgyzstan"
//     },
//     {
//         "value": "66f6158018c9a3c6d4f4dcae",
//         "label": "Laos"
//     },
//     {
//         "value": "66f2ef15bb5b9582252eef2e",
//         "label": "Latvia"
//     },
//     {
//         "value": "66f3628371a18706fbd3ade2",
//         "label": "Lebanon"
//     },
//     {
//         "value": "66f6167918c9a3c6d4f4dcb2",
//         "label": "Lesotho"
//     },
//     {
//         "value": "66f6181918c9a3c6d4f4dcb6",
//         "label": "Liberia"
//     },
//     {
//         "value": "66f6193d18c9a3c6d4f4dcba",
//         "label": "Libya"
//     },
//     {
//         "value": "66f61a1a18c9a3c6d4f4dcbe",
//         "label": "Liechtenstein"
//     },
//     {
//         "value": "66f2f11171a18706fbd3adbb",
//         "label": "Lithuania"
//     },
//     {
//         "value": "66f61b2d18c9a3c6d4f4dcc2",
//         "label": "Luxembourg"
//     },
//     {
//         "value": "66f4a64971a18706fbd3ae52",
//         "label": "Madagascar"
//     },
//     {
//         "value": "66f4ad1471a18706fbd3ae66",
//         "label": "Malawi"
//     },
//     {
//         "value": "66f03a2fc700af86f7cb443d",
//         "label": "Malaysia"
//     },
//     {
//         "value": "66a1ed94e3e1ced4992a2622",
//         "label": "Maldives"
//     },
//     {
//         "value": "66fa0c0f9454e950b715053e",
//         "label": "Mali"
//     },
//     {
//         "value": "66fa0ce09454e950b7150542",
//         "label": "Malta"
//     },
//     {
//         "value": "66fa0e0e9454e950b7150546",
//         "label": "Marshall Islands"
//     },
//     {
//         "value": "66fa0f469454e950b715054a",
//         "label": "Mauritania"
//     },
//     {
//         "value": "66f378c071a18706fbd3adf2",
//         "label": "Mauritius"
//     },
//     {
//         "value": "66b1d2d452e0e474b79b6a36",
//         "label": "Mexico"
//     },
//     {
//         "value": "66fa104d9454e950b715054e",
//         "label": "Micronesia"
//     },
//     {
//         "value": "66f4ce7818c9a3c6d4f4db7c",
//         "label": "Moldova"
//     },
//     {
//         "value": "66fa112b9454e950b7150552",
//         "label": "Monaco"
//     },
//     {
//         "value": "66fa11df9454e950b7150556",
//         "label": "Mongolia"
//     },
//     {
//         "value": "66fa12ae9454e950b715055a",
//         "label": "Montenegro"
//     },
//     {
//         "value": "66f03d01c700af86f7cb4449",
//         "label": "Morocco"
//     },
//     {
//         "value": "66f4a7e971a18706fbd3ae56",
//         "label": "Mozambique"
//     },
//     {
//         "value": "66f379ff71a18706fbd3adf6",
//         "label": "Myanmar"
//     },
//     {
//         "value": "66f3823f71a18706fbd3ae0a",
//         "label": "Namibia"
//     },
//     {
//         "value": "66fa13bd9454e950b715055e",
//         "label": "Nauru"
//     },
//     {
//         "value": "66f04540c700af86f7cb4461",
//         "label": "Nepal"
//     },
//     {
//         "value": "66f03203c700af86f7cb4429",
//         "label": "Netherlands"
//     },
//     {
//         "value": "66f0391fc700af86f7cb4439",
//         "label": "New Zealand"
//     },
//     {
//         "value": "66fa14ad9454e950b7150562",
//         "label": "Nicaragua"
//     },
//     {
//         "value": "66f4c51718c9a3c6d4f4db6c",
//         "label": "Niger"
//     },
//     {
//         "value": "66fa15879454e950b715056c",
//         "label": "Nigeria"
//     },
//     {
//         "value": "66fa17669454e950b7150570",
//         "label": "North Korea"
//     },
//     {
//         "value": "66f3768471a18706fbd3adea",
//         "label": "North Macedonia"
//     },
//     {
//         "value": "66f2d4c8bb5b9582252eeeeb",
//         "label": "Norway"
//     },
//     {
//         "value": "66f2e6abbb5b9582252eef22",
//         "label": "Oman"
//     },
//     {
//         "value": "66fa1b2f9454e950b7150574",
//         "label": "Pakistan"
//     },
//     {
//         "value": "66fa1d249454e950b7150578",
//         "label": "Palau"
//     },
//     {
//         "value": "66fa1e239454e950b715057d",
//         "label": "Palestine State"
//     },
//     {
//         "value": "66f37c4d71a18706fbd3adfa",
//         "label": "Panama"
//     },
//     {
//         "value": "66fb3f3e9454e950b7150a25",
//         "label": "Papua New Guinea"
//     },
//     {
//         "value": "66fb40e19454e950b7150a29",
//         "label": "Paraguay"
//     },
//     {
//         "value": "66f2df27bb5b9582252eef03",
//         "label": "Peru"
//     },
//     {
//         "value": "66f2dc9fbb5b9582252eeefb",
//         "label": "Philippines"
//     },
//     {
//         "value": "66f2a31bbb5b9582252eeedb",
//         "label": "Poland"
//     },
//     {
//         "value": "66f01de5c700af86f7cb4299",
//         "label": "Portugal"
//     },
//     {
//         "value": "66f042b8c700af86f7cb4459",
//         "label": "Qatar"
//     },
//     {
//         "value": "66f57c5d18c9a3c6d4f4dc3a",
//         "label": "Republic of the Congo"
//     },
//     {
//         "value": "66f2d9a2bb5b9582252eeef3",
//         "label": "Romania"
//     },
//     {
//         "value": "66b1d2d452e0e474b79b6a4e",
//         "label": "Russia"
//     },
//     {
//         "value": "66f4b8ba71a18706fbd3ae72",
//         "label": "Rwanda"
//     },
//     {
//         "value": "66fb420f9454e950b7150a2d",
//         "label": "Saint Kitts and Nevis"
//     },
//     {
//         "value": "66fb42d39454e950b7150a31",
//         "label": "Saint Lucia"
//     },
//     {
//         "value": "66fb43749454e950b7150a35",
//         "label": "Saint Vincent and the Grenadines"
//     },
//     {
//         "value": "66fb444f9454e950b7150a39",
//         "label": "Samoa"
//     },
//     {
//         "value": "66fb45589454e950b7150a3d",
//         "label": "San Marino"
//     },
//     {
//         "value": "66fb46b49454e950b7150a41",
//         "label": "Sao Tome and Principe"
//     },
//     {
//         "value": "66b1d2d452e0e474b79b6a56",
//         "label": "Saudi Arabia"
//     },
//     {
//         "value": "66f37e8571a18706fbd3ae02",
//         "label": "Senegal"
//     },
//     {
//         "value": "66fb58b59454e950b7150a45",
//         "label": "Serbia"
//     },
//     {
//         "value": "66fb5c189454e950b7150a49",
//         "label": "Seychelles"
//     },
//     {
//         "value": "66f4d32818c9a3c6d4f4db90",
//         "label": "Sierra Leone"
//     },
//     {
//         "value": "66f000a0acfb5411bf02f3cc",
//         "label": "Singapore"
//     },
//     {
//         "value": "66f2e5d5bb5b9582252eef1e",
//         "label": "Slovakia"
//     },
//     {
//         "value": "66fb5d309454e950b7150a4d",
//         "label": "Slovenia"
//     },
//     {
//         "value": "66fb602e9454e950b7150a51",
//         "label": "Solomon Islands"
//     },
//     {
//         "value": "66fb62449454e950b7150a55",
//         "label": "Somalia"
//     },
//     {
//         "value": "66b1d2d452e0e474b79b6a4c",
//         "label": "South Africa"
//     },
//     {
//         "value": "66b1d2d452e0e474b79b6a50",
//         "label": "South Korea"
//     },
//     {
//         "value": "66b1d2d452e0e474b79b6a42",
//         "label": "Spain"
//     },
//     {
//         "value": "66f043d6c700af86f7cb445d",
//         "label": "Sri Lanka"
//     },
//     {
//         "value": "66f4ab3171a18706fbd3ae5e",
//         "label": "Sudan"
//     },
//     {
//         "value": "66fb64cb9454e950b7150a59",
//         "label": "Suriname"
//     },
//     {
//         "value": "66f2b816bb5b9582252eeee3",
//         "label": "Sweden"
//     },
//     {
//         "value": "66effe01acfb5411bf02f3c4",
//         "label": "Switzerland"
//     },
//     {
//         "value": "66f4a56e71a18706fbd3ae4e",
//         "label": "Syria"
//     },
//     {
//         "value": "66f4ca7a18c9a3c6d4f4db70",
//         "label": "Tajikistan"
//     },
//     {
//         "value": "66f4c3d218c9a3c6d4f4db68",
//         "label": "Tanzania"
//     },
//     {
//         "value": "66a1e900e3e1ced4992a261e",
//         "label": "Thailand"
//     },
//     {
//         "value": "66f4d0d218c9a3c6d4f4db84",
//         "label": "Timor-Leste"
//     },
//     {
//         "value": "66f4a46571a18706fbd3ae4a",
//         "label": "Togo"
//     },
//     {
//         "value": "66fb672c9454e950b7150a5d",
//         "label": "Tonga"
//     },
//     {
//         "value": "66fb68189454e950b7150a61",
//         "label": "Trinidad and Tobago"
//     },
//     {
//         "value": "66f2e2f8bb5b9582252eef12",
//         "label": "Tunisia"
//     },
//     {
//         "value": "66b1d2d452e0e474b79b6a52",
//         "label": "Turkey"
//     },
//     {
//         "value": "66fb683c9454e950b7150a65",
//         "label": "Turkmenistan"
//     },
//     {
//         "value": "66fb6a0f9454e950b7150a6c",
//         "label": "Tuvalu"
//     },
//     {
//         "value": "66f4a36371a18706fbd3ae46",
//         "label": "Uganda"
//     },
//     {
//         "value": "66fb6aec9454e950b7150a70",
//         "label": "Ukraine"
//     },
//     {
//         "value": "66b1d2d452e0e474b79b6a3a",
//         "label": "United Kingdom"
//     },
//     {
//         "value": "66accaa82a29f0a20896b246",
//         "label": "United States"
//     },
//     {
//         "value": "66fb6d0d9454e950b7150a74",
//         "label": "United States of America"
//     },
//     {
//         "value": "66fb6e4b9454e950b7150a78",
//         "label": "Uruguay"
//     },
//     {
//         "value": "66f2f33171a18706fbd3adc6",
//         "label": "Uzbekistan"
//     },
//     {
//         "value": "66fb6efd9454e950b7150a7c",
//         "label": "Vanuatu"
//     },
//     {
//         "value": "66fb70769454e950b7150a80",
//         "label": "Venezuela"
//     },
//     {
//         "value": "66cf46a1534e410281d2847e",
//         "label": "Vietnam"
//     },
//     {
//         "value": "66fb71329454e950b7150a84",
//         "label": "Yemen"
//     },
//     {
//         "value": "66f42beb71a18706fbd3ae26",
//         "label": "Zambia"
//     },
//     {
//         "value": "66fb721a9454e950b7150a88",
//         "label": "Zimbabwe"
//     }
// ]

// const currenciesOptions = [
//     {
//         "value": "66e3fb6dbee3abe2875109a6",
//         "label": "AED"
//     },
//     {
//         "value": "67869ce9bc74f3bd0555e478",
//         "label": "AFN"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109a7",
//         "label": "ALL"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109a8",
//         "label": "AMD"
//     },
//     {
//         "value": "67869d2dbc74f3bd0555e47a",
//         "label": "AOA"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109a9",
//         "label": "ARS"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109aa",
//         "label": "AUD"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109ab",
//         "label": "AWG"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109ac",
//         "label": "AZN"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109ad",
//         "label": "BAM"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109ae",
//         "label": "BBD"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109af",
//         "label": "BDT"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109b0",
//         "label": "BGN"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109b1",
//         "label": "BHD"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109b2",
//         "label": "BIF"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109b3",
//         "label": "BMD"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109b4",
//         "label": "BND"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109b5",
//         "label": "BOB"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109b6",
//         "label": "BRL"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109b7",
//         "label": "BSD"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109b8",
//         "label": "BTN"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109b9",
//         "label": "BWP"
//     },
//     {
//         "value": "6786922cbc74f3bd0555e467",
//         "label": "BYR"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109ba",
//         "label": "BZD"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109bb",
//         "label": "CAD"
//     },
//     {
//         "value": "67869d70bc74f3bd0555e47c",
//         "label": "CDF"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109bc",
//         "label": "CHF"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109bd",
//         "label": "CLP"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109be",
//         "label": "CNY"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109bf",
//         "label": "COP"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109c0",
//         "label": "CRC"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109c1",
//         "label": "CUP"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109c2",
//         "label": "CVE"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109c3",
//         "label": "CZK"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109c4",
//         "label": "DJF"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109c5",
//         "label": "DKK"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109c6",
//         "label": "DOP"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109c7",
//         "label": "DZD"
//     },
//     {
//         "value": "67869e38bc74f3bd0555e47f",
//         "label": "ECS"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109c8",
//         "label": "EGP"
//     },
//     {
//         "value": "67869e62bc74f3bd0555e481",
//         "label": "ERN"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109c9",
//         "label": "ETB"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109ca",
//         "label": "EUR"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109cb",
//         "label": "FJD"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109cc",
//         "label": "GBP"
//     },
//     {
//         "value": "678691dfbc74f3bd0555e465",
//         "label": "GEL"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109cd",
//         "label": "GHS"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109ce",
//         "label": "GIP"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109cf",
//         "label": "GMD"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109d0",
//         "label": "GNF"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109d1",
//         "label": "GTQ"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109d2",
//         "label": "GYD"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109d3",
//         "label": "HKD"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109d4",
//         "label": "HNL"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109d5",
//         "label": "HRK"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109d6",
//         "label": "HTG"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109d7",
//         "label": "HUF"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109d8",
//         "label": "IDR"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109d9",
//         "label": "ILS"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109a5",
//         "label": "INR"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109da",
//         "label": "IQD"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109db",
//         "label": "ISK"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109dc",
//         "label": "JMD"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109dd",
//         "label": "JOD"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109de",
//         "label": "JPY"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109df",
//         "label": "KES"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109e0",
//         "label": "KGS"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109e1",
//         "label": "KHR"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109e2",
//         "label": "KMF"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109e3",
//         "label": "KRW"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109e4",
//         "label": "KWD"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109e5",
//         "label": "KYD"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109e6",
//         "label": "KZT"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109e7",
//         "label": "LAK"
//     },
//     {
//         "value": "678692a1bc74f3bd0555e469",
//         "label": "LBP"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109e8",
//         "label": "LKR"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109e9",
//         "label": "LRD"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109ea",
//         "label": "LSL"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109eb",
//         "label": "MAD"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109ec",
//         "label": "MDL"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109ed",
//         "label": "MGA"
//     },
//     {
//         "value": "67869378bc74f3bd0555e471",
//         "label": "MGF"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109ee",
//         "label": "MKD"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109ef",
//         "label": "MMK"
//     },
//     {
//         "value": "678692dcbc74f3bd0555e46b",
//         "label": "MMR"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109f0",
//         "label": "MNT"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109f1",
//         "label": "MOP"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109f2",
//         "label": "MUR"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109f3",
//         "label": "MVR"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109f4",
//         "label": "MWK"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109f5",
//         "label": "MXN"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109f6",
//         "label": "MYR"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109f7",
//         "label": "MZN"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109f8",
//         "label": "NAD"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109f9",
//         "label": "NGN"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109fa",
//         "label": "NIO"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109fb",
//         "label": "NOK"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109fc",
//         "label": "NPR"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109fd",
//         "label": "NZD"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109fe",
//         "label": "OMR"
//     },
//     {
//         "value": "6786930abc74f3bd0555e46d",
//         "label": "PAB"
//     },
//     {
//         "value": "66e3fb6dbee3abe2875109ff",
//         "label": "PEN"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a00",
//         "label": "PGK"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a01",
//         "label": "PHP"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a02",
//         "label": "PKR"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a03",
//         "label": "PLN"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a04",
//         "label": "PYG"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a05",
//         "label": "QAR"
//     },
//     {
//         "value": "67869eaebc74f3bd0555e484",
//         "label": "QTQ"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a06",
//         "label": "RON"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a07",
//         "label": "RSD"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a08",
//         "label": "RUB"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a09",
//         "label": "RWF"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a0a",
//         "label": "SAR"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a0b",
//         "label": "SCR"
//     },
//     {
//         "value": "678693b7bc74f3bd0555e473",
//         "label": "SDG"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a0c",
//         "label": "SEK"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a0d",
//         "label": "SGD"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a0e",
//         "label": "SLL"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a0f",
//         "label": "SOS"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a10",
//         "label": "SSP"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a11",
//         "label": "SVC"
//     },
//     {
//         "value": "6786933abc74f3bd0555e46f",
//         "label": "SYP"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a12",
//         "label": "SZL"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a13",
//         "label": "THB"
//     },
//     {
//         "value": "6786954cbc74f3bd0555e475",
//         "label": "TJS"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a14",
//         "label": "TND"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a15",
//         "label": "TRY"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a16",
//         "label": "TTD"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a17",
//         "label": "TWD"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a18",
//         "label": "TZS"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a19",
//         "label": "UAH"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a1a",
//         "label": "UGX"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a1b",
//         "label": "USD"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a1c",
//         "label": "UYU"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a1d",
//         "label": "UZS"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a1e",
//         "label": "VND"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a1f",
//         "label": "VUV"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a20",
//         "label": "XAF"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a21",
//         "label": "XCD"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a22",
//         "label": "XOF"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a23",
//         "label": "XPF"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a24",
//         "label": "YER"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a25",
//         "label": "ZAR"
//     },
//     {
//         "value": "66e3fb6dbee3abe287510a26",
//         "label": "ZMW"
//     }
// ]

const countriesOptions = [
    { value: "66f50a3818c9a3c6d4f4dbde", label: "Afghanistan", currencyId: "67869ce9bc74f3bd0555e478" }, // AFN
    { value: "66f51d8d18c9a3c6d4f4dbe2", label: "Albania", currencyId: "66e3fb6dbee3abe2875109a7" }, // ALL
    { value: "66f51f8d18c9a3c6d4f4dbe6", label: "Algeria", currencyId: "66e3fb6dbee3abe2875109c7" }, // DZD
    { value: "66f5205318c9a3c6d4f4dbea", label: "Andorra", currencyId: "66e3fb6dbee3abe2875109ca" }, // EUR
    { value: "66f521c718c9a3c6d4f4dbee", label: "Angola", currencyId: "67869d2dbc74f3bd0555e47a" }, // AOA
    { value: "66f5228318c9a3c6d4f4dbf2", label: "Antigua and Barbuda", currencyId: "66e3fb6dbee3abe2875109ae" }, // XCD (not in currenciesOptions, placeholder)
    { value: "66b1d2d452e0e474b79b6a54", label: "Argentina", currencyId: "66e3fb6dbee3abe2875109a9" }, // ARS
    { value: "66f241ed8cae9fb7dde19bf1", label: "Armenia", currencyId: "66e3fb6dbee3abe2875109a8" }, // AMD
    { value: "66accc662a29f0a20896b27b", label: "Australia", currencyId: "66e3fb6dbee3abe2875109aa" }, // AUD
    { value: "66f0131cc700af86f7cb3f57", label: "Austria", currencyId: "66e3fb6dbee3abe2875109ca" }, // EUR
    { value: "66f2443f8cae9fb7dde19c0d", label: "Azerbaijan", currencyId: "66e3fb6dbee3abe2875109ac" }, // AZN
    { value: "66f5238418c9a3c6d4f4dbf6", label: "Bahamas", currencyId: "66e3fb6dbee3abe2875109b7" }, // BSD
    { value: "66f245688cae9fb7dde19c11", label: "Bahrain", currencyId: "66e3fb6dbee3abe2875109b1" }, // BHD
    { value: "66f524c118c9a3c6d4f4dbfa", label: "Bangladesh", currencyId: "66e3fb6dbee3abe2875109af" }, // BDT
    { value: "66f5258e18c9a3c6d4f4dbfe", label: "Barbados", currencyId: "66e3fb6dbee3abe2875109ae" }, // BBD
    { value: "66f2e7f6bb5b9582252eef26", label: "Belarus", currencyId: "6786922cbc74f3bd0555e467" }, // BYR
    { value: "66f2db56bb5b9582252eeef7", label: "Belgium", currencyId: "66e3fb6dbee3abe2875109ca" }, // EUR
    { value: "66f5268b18c9a3c6d4f4dc02", label: "Belize", currencyId: "66e3fb6dbee3abe2875109ba" }, // BZD
    { value: "66f4baac18c9a3c6d4f4db59", label: "Benin", currencyId: "66e3fb6dbee3abe2875109d0" }, // XOF
    { value: "66f04061c700af86f7cb4451", label: "Bhutan", currencyId: "66e3fb6dbee3abe2875109b8" }, // BTN
    { value: "66f5279418c9a3c6d4f4dc06", label: "Bolivia", currencyId: "66e3fb6dbee3abe2875109b5" }, // BOB
    { value: "66f3774f71a18706fbd3adee", label: "Bosnia and Herzegovina", currencyId: "66e3fb6dbee3abe2875109ad" }, // BAM
    { value: "66f4a91b71a18706fbd3ae5a", label: "Botswana", currencyId: "66e3fb6dbee3abe2875109b9" }, // BWP
    { value: "66accdcb2a29f0a20896b2a6", label: "Brazil", currencyId: "66e3fb6dbee3abe2875109b6" }, // BRL
    { value: "66f4d17e18c9a3c6d4f4db88", label: "Brunei", currencyId: "66e3fb6dbee3abe2875109b4" }, // BND
    { value: "66f2e029bb5b9582252eef07", label: "Bulgaria", currencyId: "66e3fb6dbee3abe2875109b0" }, // BGN
    { value: "66f4ae0471a18706fbd3ae6a", label: "Burkina Faso", currencyId: "66e3fb6dbee3abe2875109d0" }, // XOF
    { value: "66f5292b18c9a3c6d4f4dc0a", label: "Burundi", currencyId: "66e3fb6dbee3abe2875109b2" }, // BIF
    { value: "66f03418c700af86f7cb442d", label: "Cambodia", currencyId: "66e3fb6dbee3abe2875109e1" }, // KHR
    { value: "66f52cbf18c9a3c6d4f4dc16", label: "Cameroon", currencyId: "66e3fb6dbee3abe2875109d0" }, // XAF
    { value: "66accd422a29f0a20896b291", label: "Canada", currencyId: "66e3fb6dbee3abe2875109bb" }, // CAD
    { value: "66f52bb218c9a3c6d4f4dc12", label: "Cape Verde", currencyId: "66e3fb6dbee3abe2875109c2" }, // CVE
    { value: "66f5782a18c9a3c6d4f4dc32", label: "Central African Republic", currencyId: "66e3fb6dbee3abe2875109d0" }, // XAF
    { value: "66f4cd3618c9a3c6d4f4db78", label: "Chad", currencyId: "66e3fb6dbee3abe2875109d0" }, // XAF
    { value: "66f2e4e2bb5b9582252eef1a", label: "Chile", currencyId: "66e3fb6dbee3abe2875109bd" }, // CLP
    { value: "66b1d2d452e0e474b79b6a48", label: "China", currencyId: "66e3fb6dbee3abe2875109be" }, // CNY
    { value: "66f0150ac700af86f7cb3f77", label: "Colombia", currencyId: "66e3fb6dbee3abe2875109bf" }, // COP
    { value: "66f5796618c9a3c6d4f4dc36", label: "Comoros", currencyId: "66e3fb6dbee3abe2875109e2" }, // KMF
    { value: "66f046b1c700af86f7cb4465", label: "Costa Rica", currencyId: "66e3fb6dbee3abe2875109c0" }, // CRC
    { value: "66f00f05c700af86f7cb3f48", label: "Croatia", currencyId: "66e3fb6dbee3abe2875109d5" }, // HRK
    { value: "66f57e1f18c9a3c6d4f4dc3e", label: "Cuba", currencyId: "66e3fb6dbee3abe2875109c1" }, // CUP
    { value: "66f57fe118c9a3c6d4f4dc42", label: "Cyprus", currencyId: "66e3fb6dbee3abe2875109ca" }, // EUR
    { value: "66f03b0ac700af86f7cb4441", label: "Czech Republic", currencyId: "66e3fb6dbee3abe2875109c3" }, // CZK
    { value: "66f5821a18c9a3c6d4f4dc49", label: "Democratic Republic of the Congo", currencyId: "67869d70bc74f3bd0555e47c" }, // CDF
    { value: "66f2d39fbb5b9582252eeee7", label: "Denmark", currencyId: "66e3fb6dbee3abe2875109c5" }, // DKK
    { value: "66f584a818c9a3c6d4f4dc4d", label: "Djibouti", currencyId: "66e3fb6dbee3abe2875109c4" }, // DJF
    { value: "66f2e3efbb5b9582252eef16", label: "Dominican Republic", currencyId: "66e3fb6dbee3abe2875109c6" }, // DOP
    { value: "66f03bcec700af86f7cb4445", label: "Dubai, United Arab Emirates (UAE)", currencyId: "66e3fb6dbee3abe2875109a6" }, // AED
    { value: "66f5860d18c9a3c6d4f4dc51", label: "Ecuador", currencyId: "66e3fb6dbee3abe2875109c8" }, // USD
    { value: "66b1d2d452e0e474b79b6a58", label: "Egypt", currencyId: "66e3fb6dbee3abe2875109c8" }, // EGP
    { value: "66f587e618c9a3c6d4f4dc55", label: "El Salvador", currencyId: "66e3fb6dbee3abe2875109a1b" }, // USD
    { value: "66af202e680c82111a7e34b9", label: "England", currencyId: "66e3fb6dbee3abe2875109cc" }, // GBP
    { value: "66f5896b18c9a3c6d4f4dc5a", label: "Equatorial Guinea", currencyId: "66e3fb6dbee3abe2875109d0" }, // XAF
    { value: "66f58a4f18c9a3c6d4f4dc5e", label: "Eritrea", currencyId: "67869e62bc74f3bd0555e481" }, // ERN
    { value: "66f2f23f71a18706fbd3adc2", label: "Estonia", currencyId: "66e3fb6dbee3abe2875109ca" }, // EUR
    { value: "66f4cfef18c9a3c6d4f4db80", label: "Eswatini", currencyId: "66e3fb6dbee3abe2875109a25" }, // SZL
    { value: "66f58c7418c9a3c6d4f4dc62", label: "Ethiopia", currencyId: "66e3fb6dbee3abe2875109c9" }, // ETB
    { value: "66f58d4418c9a3c6d4f4dc66", label: "Fiji", currencyId: "66e3fb6dbee3abe2875109cb" }, // FJD
    { value: "66f2dde1bb5b9582252eeeff", label: "Finland", currencyId: "66e3fb6dbee3abe2875109ca" }, // EUR
    { value: "66b1d2d452e0e474b79b6a3e", label: "France", currencyId: "66e3fb6dbee3abe2875109ca" }, // EUR
    { value: "66f4ac2371a18706fbd3ae62", label: "Gabon", currencyId: "66e3fb6dbee3abe2875109d0" }, // XAF
    { value: "66f4d25b18c9a3c6d4f4db8c", label: "Gambia", currencyId: "66e3fb6dbee3abe2875109cf" }, // GMD
    { value: "66f0353ac700af86f7cb4431", label: "Georgia", currencyId: "678691dfbc74f3bd0555e465" }, // GEL
    { value: "66af2052680c82111a7e34bd", label: "Germany", currencyId: "66e3fb6dbee3abe2875109ca" }, // EUR
    { value: "66f37d2c71a18706fbd3adfe", label: "Ghana", currencyId: "66e3fb6dbee3abe2875109cd" }, // GHS
    { value: "66efffcdacfb5411bf02f3c8", label: "Greece", currencyId: "66e3fb6dbee3abe2875109ca" }, // EUR
    { value: "66f58e5f18c9a3c6d4f4dc6a", label: "Grenada", currencyId: "66e3fb6dbee3abe2875109ae" }, // XCD
    { value: "66f6011b18c9a3c6d4f4dc86", label: "Guatemala", currencyId: "66e3fb6dbee3abe2875109d1" }, // GTQ
    { value: "66f4af5171a18706fbd3ae6e", label: "Guinea", currencyId: "66e3fb6dbee3abe2875109d0" }, // GNF
    { value: "66f6031618c9a3c6d4f4dc8a", label: "Guinea-Bissau", currencyId: "66e3fb6dbee3abe2875109d0" }, // XOF
    { value: "66f6047b18c9a3c6d4f4dc8e", label: "Guyana", currencyId: "66e3fb6dbee3abe2875109d2" }, // GYD
    { value: "66f4cbe718c9a3c6d4f4db74", label: "Haiti", currencyId: "66e3fb6dbee3abe2875109d6" }, // HTG
    { value: "66f6061318c9a3c6d4f4dc92", label: "Holy See", currencyId: "66e3fb6dbee3abe2875109ca" }, // EUR
    { value: "66f6096918c9a3c6d4f4dc96", label: "Honduras", currencyId: "66e3fb6dbee3abe2875109d4" }, // HNL
    { value: "66f010bcc700af86f7cb3f4d", label: "Hong Kong", currencyId: "66e3fb6dbee3abe2875109d3" }, // HKD
    { value: "66f2e1a4bb5b9582252eef0b", label: "Hungary", currencyId: "66e3fb6dbee3abe2875109d7" }, // HUF
    { value: "66f3663f71a18706fbd3ade6", label: "Iceland", currencyId: "66e3fb6dbee3abe2875109db" }, // ISK
    { value: "66a1e7b2e3e1ced4992a2617", label: "India", currencyId: "66e3fb6dbee3abe2875109a5" }, // INR
    { value: "66f03679c700af86f7cb4435", label: "Indonesia", currencyId: "66e3fb6dbee3abe2875109d8" }, // IDR
    { value: "66f60a7e18c9a3c6d4f4dc9a", label: "Iran", currencyId: "66e3fb6dbee3abe2875109da" }, // IRR
    { value: "66f60fde18c9a3c6d4f4dc9e", label: "Iraq", currencyId: "66e3fb6dbee3abe2875109da" }, // IQD
    { value: "66f2d88fbb5b9582252eeeef", label: "Ireland", currencyId: "66e3fb6dbee3abe2875109ca" }, // EUR
    { value: "66f01f1ec700af86f7cb4425", label: "Israel", currencyId: "66e3fb6dbee3abe2875109d9" }, // ILS
    { value: "66b1d2d452e0e474b79b6a40", label: "Italy", currencyId: "66e3fb6dbee3abe2875109ca" }, // EUR
    { value: "66f52a1918c9a3c6d4f4dc0e", label: "Ivory Coast", currencyId: "66e3fb6dbee3abe2875109d0" }, // XOF
    { value: "66f04219c700af86f7cb4455", label: "Jamaica", currencyId: "66e3fb6dbee3abe2875109dc" }, // JMD
    { value: "66af2080680c82111a7e34c1", label: "Japan", currencyId: "66e3fb6dbee3abe2875109de" }, // JPY
    { value: "66f03e16c700af86f7cb444d", label: "Jordan", currencyId: "66e3fb6dbee3abe2875109dd" }, // JOD
    { value: "66f2e958bb5b9582252eef2a", label: "Kazakhstan", currencyId: "66e3fb6dbee3abe2875109e6" }, // KZT
    { value: "66f6112818c9a3c6d4f4dca2", label: "Kenya", currencyId: "66e3fb6dbee3abe2875109df" }, // KES
    { value: "66f6127118c9a3c6d4f4dca6", label: "Kiribati", currencyId: "66e3fb6dbee3abe2875109a1b" }, // AUD
    { value: "66f613a818c9a3c6d4f4dcaa", label: "Kuwait", currencyId: "66e3fb6dbee3abe2875109e4" }, // KWD
    { value: "66f380e371a18706fbd3ae06", label: "Kyrgyzstan", currencyId: "66e3fb6dbee3abe2875109e0" }, // KGS
    { value: "66f6158018c9a3c6d4f4dcae", label: "Laos", currencyId: "66e3fb6dbee3abe2875109e7" }, // LAK
    { value: "66f2ef15bb5b9582252eef2e", label: "Latvia", currencyId: "66e3fb6dbee3abe2875109ca" }, // EUR
    { value: "66f3628371a18706fbd3ade2", label: "Lebanon", currencyId: "678692a1bc74f3bd0555e469" }, // LBP
    { value: "66f6167918c9a3c6d4f4dcb2", label: "Lesotho", currencyId: "66e3fb6dbee3abe2875109a25" }, // LSL
    { value: "66f6181918c9a3c6d4f4dcb6", label: "Liberia", currencyId: "66e3fb6dbee3abe2875109e9" }, // LRD
    { value: "66f6193d18c9a3c6d4f4dcba", label: "Libya", currencyId: "66e3fb6dbee3abe2875109c7" }, // LYD
    { value: "66f61a1a18c9a3c6d4f4dcbe", label: "Liechtenstein", currencyId: "66e3fb6dbee3abe2875109cc" }, // CHF
    { value: "66f2f11171a18706fbd3adbb", label: "Lithuania", currencyId: "66e3fb6dbee3abe2875109ca" }, // EUR
    { value: "66f61b2d18c9a3c6d4f4dcc2", label: "Luxembourg", currencyId: "66e3fb6dbee3abe2875109ca" }, // EUR
    { value: "66f4a64971a18706fbd3ae52", label: "Madagascar", currencyId: "66e3fb6dbee3abe2875109ed" }, // MGA
    { value: "66f4ad1471a18706fbd3ae66", label: "Malawi", currencyId: "66e3fb6dbee3abe2875109f4" }, // MWK
    { value: "66f03a2fc700af86f7cb443d", label: "Malaysia", currencyId: "66e3fb6dbee3abe2875109f6" }, // MYR
    { value: "66a1ed94e3e1ced4992a2622", label: "Maldives", currencyId: "66e3fb6dbee3abe2875109f3" }, // MVR
    { value: "66fa0c0f9454e950b715053e", label: "Mali", currencyId: "66e3fb6dbee3abe2875109d0" }, // XOF
    { value: "66fa0ce09454e950b7150542", label: "Malta", currencyId: "66e3fb6dbee3abe2875109ca" }, // EUR
    { value: "66fa0e0e9454e950b7150546", label: "Marshall Islands", currencyId: "66e3fb6dbee3abe2875109a1b" }, // USD
    { value: "66fa0f469454e950b715054a", label: "Mauritania", currencyId: "66e3fb6dbee3abe2875109eb" }, // MRO
    { value: "66f378c071a18706fbd3adf2", label: "Mauritius", currencyId: "66e3fb6dbee3abe2875109f2" }, // MUR
    { value: "66b1d2d452e0e474b79b6a36", label: "Mexico", currencyId: "66e3fb6dbee3abe2875109f5" }, // MXN
    { value: "66fa104d9454e950b715054e", label: "Micronesia", currencyId: "66e3fb6dbee3abe2875109a1b" }, // USD
    { value: "66f4ce7818c9a3c6d4f4db7c", label: "Moldova", currencyId: "66e3fb6dbee3abe2875109ec" }, // MDL
    { value: "66fa112b9454e950b7150552", label: "Monaco", currencyId: "66e3fb6dbee3abe2875109ca" }, // EUR
    { value: "66fa11df9454e950b7150556", label: "Mongolia", currencyId: "66e3fb6dbee3abe2875109f0" }, // MNT
    { value: "66fa12ae9454e950b715055a", label: "Montenegro", currencyId: "66e3fb6dbee3abe2875109ca" }, // EUR
    { value: "66f03d01c700af86f7cb4449", label: "Morocco", currencyId: "66e3fb6dbee3abe2875109eb" }, // MAD
    { value: "66f4a7e971a18706fbd3ae56", label: "Mozambique", currencyId: "66e3fb6dbee3abe2875109f7" }, // MZN
    { value: "66f379ff71a18706fbd3adf6", label: "Myanmar", currencyId: "66e3fb6dbee3abe2875109ef" }, // MMK
    { value: "66f3823f71a18706fbd3ae0a", label: "Namibia", currencyId: "66e3fb6dbee3abe2875109f8" }, // NAD
    { value: "66fa13bd9454e950b715055e", label: "Nauru", currencyId: "66e3fb6dbee3abe2875109a1b" }, // AUD
    { value: "66f04540c700af86f7cb4461", label: "Nepal", currencyId: "66e3fb6dbee3abe2875109fc" }, // NPR
    { value: "66f03203c700af86f7cb4429", label: "Netherlands", currencyId: "66e3fb6dbee3abe2875109ca" }, // EUR
    { value: "66f0391fc700af86f7cb4439", label: "New Zealand", currencyId: "66e3fb6dbee3abe2875109fd" }, // NZD
    { value: "66fa14ad9454e950b7150562", label: "Nicaragua", currencyId: "66e3fb6dbee3abe2875109fa" }, // NIO
    { value: "66f4c51718c9a3c6d4f4db6c", label: "Niger", currencyId: "66e3fb6dbee3abe2875109d0" }, // XOF
    { value: "66fa15879454e950b715056c", label: "Nigeria", currencyId: "66e3fb6dbee3abe2875109f9" }, // NGN
    { value: "66fa17669454e950b7150570", label: "North Korea", currencyId: "66e3fb6dbee3abe2875109e3" }, // KPW
    { value: "66f3768471a18706fbd3adea", label: "North Macedonia", currencyId: "66e3fb6dbee3abe2875109ee" }, // MKD
    { value: "66f2d4c8bb5b9582252eeeeb", label: "Norway", currencyId: "66e3fb6dbee3abe2875109fb" }, // NOK
    { value: "66f2e6abbb5b9582252eef22", label: "Oman", currencyId: "66e3fb6dbee3abe2875109fe" }, // OMR
    { value: "66fa1b2f9454e950b7150574", label: "Pakistan", currencyId: "66e3fb6dbee3abe287510a02" }, // PKR
    { value: "66fa1d249454e950b7150578", label: "Palau", currencyId: "66e3fb6dbee3abe2875109a1b" }, // USD
    { value: "66fa1e239454e950b715057d", label: "Palestine State", currencyId: "66e3fb6dbee3abe2875109d9" }, // ILS
    { value: "66f37c4d71a18706fbd3adfa", label: "Panama", currencyId: "66e3fb6dbee3abe2875109a1b" }, // USD
    { value: "66fb3f3e9454e950b7150a25", label: "Papua New Guinea", currencyId: "66e3fb6dbee3abe287510a00" }, // PGK
    { value: "66fb40e19454e950b7150a29", label: "Paraguay", currencyId: "66e3fb6dbee3abe287510a04" }, // PYG
    { value: "66f2df27bb5b9582252eef03", label: "Peru", currencyId: "66e3fb6dbee3abe2875109ff" }, // PEN
    { value: "66f2dc9fbb5b9582252eeefb", label: "Philippines", currencyId: "66e3fb6dbee3abe287510a01" }, // PHP
    { value: "66f2a31bbb5b9582252eeedb", label: "Poland", currencyId: "66e3fb6dbee3abe287510a03" }, // PLN
    { value: "66f01de5c700af86f7cb4299", label: "Portugal", currencyId: "66e3fb6dbee3abe2875109ca" }, // EUR
    { value: "66f042b8c700af86f7cb4459", label: "Qatar", currencyId: "66e3fb6dbee3abe287510a05" }, // QAR
    { value: "66f57c5d18c9a3c6d4f4dc3a", label: "Republic of the Congo", currencyId: "66e3fb6dbee3abe2875109d0" }, // XAF
    { value: "66f2d9a2bb5b9582252eeef3", label: "Romania", currencyId: "66e3fb6dbee3abe287510a06" }, // RON
    { value: "66b1d2d452e0e474b79b6a4e", label: "Russia", currencyId: "66e3fb6dbee3abe287510a08" }, // RUB
    { value: "66f4b8ba71a18706fbd3ae72", label: "Rwanda", currencyId: "66e3fb6dbee3abe287510a09" }, // RWF
    { value: "66fb420f9454e950b7150a2d", label: "Saint Kitts and Nevis", currencyId: "66e3fb6dbee3abe2875109ae" }, // XCD
    { value: "66fb42d39454e950b7150a31", label: "Saint Lucia", currencyId: "66e3fb6dbee3abe2875109ae" }, // XCD
    { value: "66fb43749454e950b7150a35", label: "Saint Vincent and the Grenadines", currencyId: "66e3fb6dbee3abe2875109ae" }, // XCD
    { value: "66fb444f9454e950b7150a39", label: "Samoa", currencyId: "66e3fb6dbee3abe2875109fd" }, // WST
    { value: "66fb45589454e950b7150a3d", label: "San Marino", currencyId: "66e3fb6dbee3abe2875109ca" }, // EUR
    { value: "66fb46b49454e950b7150a41", label: "Sao Tome and Principe", currencyId: "66e3fb6dbee3abe287510a0b" }, // STD
    { value: "66b1d2d452e0e474b79b6a56", label: "Saudi Arabia", currencyId: "66e3fb6dbee3abe287510a0a" }, // SAR
    { value: "66f37e8571a18706fbd3ae02", label: "Senegal", currencyId: "66e3fb6dbee3abe2875109d0" }, // XOF
    { value: "66fb58b59454e950b7150a45", label: "Serbia", currencyId: "66e3fb6dbee3abe287510a07" }, // RSD
    { value: "66fb5c189454e950b7150a49", label: "Seychelles", currencyId: "66e3fb6dbee3abe287510a0b" }, //
    { value: "66fb5c189454e950b7150a49", label: "Seychelles", currencyId: "66e3fb6dbee3abe287510a0b" }, // SCR
    { value: "66f4d32818c9a3c6d4f4db90", label: "Sierra Leone", currencyId: "66e3fb6dbee3abe287510a0c" }, // SLL
    { value: "66f000a0acfb5411bf02f3cc", label: "Singapore", currencyId: "66e3fb6dbee3abe287510a0d" }, // SGD
    { value: "66f2e5d5bb5b9582252eef1e", label: "Slovakia", currencyId: "66e3fb6dbee3abe287510a10" }, // EUR
    { value: "66fb5d309454e950b7150a4d", label: "Slovenia", currencyId: "66e3fb6dbee3abe287510a10" }, // EUR
    { value: "66fb602e9454e950b7150a51", label: "Solomon Islands", currencyId: "66e3fb6dbee3abe287510a11" }, // SBD
    { value: "66fb62449454e950b7150a55", label: "Somalia", currencyId: "66e3fb6dbee3abe287510a0f" }, // SOS
    { value: "66b1d2d452e0e474b79b6a4c", label: "South Africa", currencyId: "66e3fb6dbee3abe287510a25" }, // ZAR
    { value: "66b1d2d452e0e474b79b6a50", label: "South Korea", currencyId: "66e3fb6dbee3abe2875109e3" }, // KRW
    { value: "66b1d2d452e0e474b79b6a42", label: "Spain", currencyId: "66e3fb6dbee3abe2875109ca" }, // EUR
    { value: "66f043d6c700af86f7cb445d", label: "Sri Lanka", currencyId: "66e3fb6dbee3abe2875109e8" }, // LKR
    { value: "66f4ab3171a18706fbd3ae5e", label: "Sudan", currencyId: "66e3fb6dbee3abe287510a0c" }, // SDG
    { value: "66fb64cb9454e950b7150a59", label: "Suriname", currencyId: "66e3fb6dbee3abe287510a12" }, // SRD
    { value: "66f2b816bb5b9582252eeee3", label: "Sweden", currencyId: "66e3fb6dbee3abe287510a0d" }, // SEK
    { value: "66effe01acfb5411bf02f3c4", label: "Switzerland", currencyId: "66e3fb6dbee3abe2875109cc" }, // CHF
    { value: "66f4a56e71a18706fbd3ae4e", label: "Syria", currencyId: "6786933abc74f3bd0555e46f" }, // SYP
    { value: "66f4ca7a18c9a3c6d4f4db70", label: "Tajikistan", currencyId: "66e3fb6dbee3abe287510a14" }, // TJS
    { value: "66f4c3d218c9a3c6d4f4db68", label: "Tanzania", currencyId: "66e3fb6dbee3abe287510a18" }, // TZS
    { value: "66a1e900e3e1ced4992a261e", label: "Thailand", currencyId: "66e3fb6dbee3abe287510a13" }, // THB
    { value: "66f4d0d218c9a3c6d4f4db84", label: "Timor-Leste", currencyId: "66e3fb6dbee3abe2875109a1b" }, // USD
    { value: "66f4a46571a18706fbd3ae4a", label: "Togo", currencyId: "66e3fb6dbee3abe2875109d0" }, // XOF
    { value: "66fb672c9454e950b7150a5d", label: "Tonga", currencyId: "66e3fb6dbee3abe287510a16" }, // TOP
    { value: "66fb68189454e950b7150a61", label: "Trinidad and Tobago", currencyId: "66e3fb6dbee3abe287510a17" }, // TTD
    { value: "66f2e2f8bb5b9582252eef12", label: "Tunisia", currencyId: "66e3fb6dbee3abe287510a14" }, // TND
    { value: "66b1d2d452e0e474b79b6a52", label: "Turkey", currencyId: "66e3fb6dbee3abe287510a15" }, // TRY
    { value: "66fb683c9454e950b7150a65", label: "Turkmenistan", currencyId: "66e3fb6dbee3abe287510a1d" }, // TMT
    { value: "66fb6a0f9454e950b7150a6c", label: "Tuvalu", currencyId: "66e3fb6dbee3abe2875109a1b" }, // AUD
    { value: "66f4a36371a18706fbd3ae46", label: "Uganda", currencyId: "66e3fb6dbee3abe287510a1a" }, // UGX
    { value: "66fb6aec9454e950b7150a70", label: "Ukraine", currencyId: "66e3fb6dbee3abe287510a19" }, // UAH
    { value: "66b1d2d452e0e474b79b6a3a", label: "United Kingdom", currencyId: "66e3fb6dbee3abe2875109cc" }, // GBP
    { value: "66accaa82a29f0a20896b246", label: "United States", currencyId: "66e3fb6dbee3abe287510a1b" }, // USD
    { value: "66fb6d0d9454e950b7150a74", label: "United States of America", currencyId: "66e3fb6dbee3abe287510a1b" }, // USD
    { value: "66fb6e4b9454e950b7150a78", label: "Uruguay", currencyId: "66e3fb6dbee3abe287510a1c" }, // UYU
    { value: "66f2f33171a18706fbd3adc6", label: "Uzbekistan", currencyId: "66e3fb6dbee3abe287510a1d" }, // UZS
    { value: "66fb6efd9454e950b7150a7c", label: "Vanuatu", currencyId: "66e3fb6dbee3abe287510a1f" }, // VUV
    { value: "66fb70769454e950b7150a80", label: "Venezuela", currencyId: "66e3fb6dbee3abe287510a20" }, // VES
    { value: "66cf46a1534e410281d2847e", label: "Vietnam", currencyId: "66e3fb6dbee3abe287510a1e" }, // VND
    { value: "66fb71329454e950b7150a84", label: "Yemen", currencyId: "66e3fb6dbee3abe287510a24" }, // YER
    { value: "66f42beb71a18706fbd3ae26", label: "Zambia", currencyId: "66e3fb6dbee3abe287510a26" }, // ZMW
    { value: "66fb721a9454e950b7150a88", label: "Zimbabwe", currencyId: "66e3fb6dbee3abe287510a25" }, // ZWL
];

const currenciesOptions = [
    { value: "66e3fb6dbee3abe2875109a6", label: "AED", currencyCountryId: "66f03bcec700af86f7cb4445" }, // United Arab Emirates
    { value: "67869ce9bc74f3bd0555e478", label: "AFN", currencyCountryId: "66f50a3818c9a3c6d4f4dbde" }, // Afghanistan
    { value: "66e3fb6dbee3abe2875109a7", label: "ALL", currencyCountryId: "66f51d8d18c9a3c6d4f4dbe2" }, // Albania
    { value: "66e3fb6dbee3abe2875109a8", label: "AMD", currencyCountryId: "66f241ed8cae9fb7dde19bf1" }, // Armenia
    { value: "67869d2dbc74f3bd0555e47a", label: "AOA", currencyCountryId: "66f521c718c9a3c6d4f4dbee" }, // Angola
    { value: "66e3fb6dbee3abe2875109a9", label: "ARS", currencyCountryId: "66b1d2d452e0e474b79b6a54" }, // Argentina
    { value: "66e3fb6dbee3abe2875109aa", label: "AUD", currencyCountryId: "66accc662a29f0a20896b27b" }, // Australia
    { value: "66e3fb6dbee3abe2875109ab", label: "AWG", currencyCountryId: "66f5238418c9a3c6d4f4dbf6" }, // Aruba (not in countriesOptions, placeholder)
    { value: "66e3fb6dbee3abe2875109ac", label: "AZN", currencyCountryId: "66f2443f8cae9fb7dde19c0d" }, // Azerbaijan
    { value: "66e3fb6dbee3abe2875109ad", label: "BAM", currencyCountryId: "66f3774f71a18706fbd3adee" }, // Bosnia and Herzegovina
    { value: "66e3fb6dbee3abe2875109ae", label: "BBD", currencyCountryId: "66f5258e18c9a3c6d4f4dbfe" }, // Barbados
    { value: "66e3fb6dbee3abe2875109af", label: "BDT", currencyCountryId: "66f524c118c9a3c6d4f4dbfa" }, // Bangladesh
    { value: "66e3fb6dbee3abe2875109b0", label: "BGN", currencyCountryId: "66f2e029bb5b9582252eef07" }, // Bulgaria
    { value: "66e3fb6dbee3abe2875109b1", label: "BHD", currencyCountryId: "66f245688cae9fb7dde19c11" }, // Bahrain
    { value: "66e3fb6dbee3abe2875109b2", label: "BIF", currencyCountryId: "66f5292b18c9a3c6d4f4dc0a" }, // Burundi
    { value: "66e3fb6dbee3abe2875109b3", label: "BMD", currencyCountryId: "66f5238418c9a3c6d4f4dbf6" }, // Bermuda (not in countriesOptions, placeholder)
    { value: "66e3fb6dbee3abe2875109b4", label: "BND", currencyCountryId: "66f4d17e18c9a3c6d4f4db88" }, // Brunei
    { value: "66e3fb6dbee3abe2875109b5", label: "BOB", currencyCountryId: "66f5279418c9a3c6d4f4dc06" }, // Bolivia
    { value: "66e3fb6dbee3abe2875109b6", label: "BRL", currencyCountryId: "66accdcb2a29f0a20896b2a6" }, // Brazil
    { value: "66e3fb6dbee3abe2875109b7", label: "BSD", currencyCountryId: "66f5238418c9a3c6d4f4dbf6" }, // Bahamas
    { value: "66e3fb6dbee3abe2875109b8", label: "BTN", currencyCountryId: "66f04061c700af86f7cb4451" }, // Bhutan
    { value: "66e3fb6dbee3abe2875109b9", label: "BWP", currencyCountryId: "66f4a91b71a18706fbd3ae5a" }, // Botswana
    { value: "6786922cbc74f3bd0555e467", label: "BYR", currencyCountryId: "66f2e7f6bb5b9582252eef26" }, // Belarus
    { value: "66e3fb6dbee3abe2875109ba", label: "BZD", currencyCountryId: "66f5268b18c9a3c6d4f4dc02" }, // Belize
    { value: "66e3fb6dbee3abe2875109bb", label: "CAD", currencyCountryId: "66accd422a29f0a20896b291" }, // Canada
    { value: "67869d70bc74f3bd0555e47c", label: "CDF", currencyCountryId: "66f5821a18c9a3c6d4f4dc49" }, // Democratic Republic of the Congo
    { value: "66e3fb6dbee3abe2875109bc", label: "CHF", currencyCountryId: "66effe01acfb5411bf02f3c4" }, // Switzerland
    { value: "66e3fb6dbee3abe2875109bd", label: "CLP", currencyCountryId: "66f2e4e2bb5b9582252eef1a" }, // Chile
    { value: "66e3fb6dbee3abe2875109be", label: "CNY", currencyCountryId: "66b1d2d452e0e474b79b6a48" }, // China
    { value: "66e3fb6dbee3abe2875109bf", label: "COP", currencyCountryId: "66f0150ac700af86f7cb3f77" }, // Colombia
    { value: "66e3fb6dbee3abe2875109c0", label: "CRC", currencyCountryId: "66f046b1c700af86f7cb4465" }, // Costa Rica
    { value: "66e3fb6dbee3abe2875109c1", label: "CUP", currencyCountryId: "66f57e1f18c9a3c6d4f4dc3e" }, // Cuba
    { value: "66e3fb6dbee3abe2875109c2", label: "CVE", currencyCountryId: "66f52bb218c9a3c6d4f4dc12" }, // Cape Verde
    { value: "66e3fb6dbee3abe2875109c3", label: "CZK", currencyCountryId: "66f03b0ac700af86f7cb4441" }, // Czech Republic
    { value: "66e3fb6dbee3abe2875109c4", label: "DJF", currencyCountryId: "66f584a818c9a3c6d4f4dc4d" }, // Djibouti
    { value: "66e3fb6dbee3abe2875109c5", label: "DKK", currencyCountryId: "66f2d39fbb5b9582252eeee7" }, // Denmark
    { value: "66e3fb6dbee3abe2875109c6", label: "DOP", currencyCountryId: "66f2e3efbb5b9582252eef16" }, // Dominican Republic
    { value: "66e3fb6dbee3abe2875109c7", label: "DZD", currencyCountryId: "66f51f8d18c9a3c6d4f4dbe6" }, // Algeria
    { value: "67869e38bc74f3bd0555e47f", label: "ECS", currencyCountryId: "66f5860d18c9a3c6d4f4dc51" }, // Ecuador (not in countriesOptions, placeholder)
    { value: "66e3fb6dbee3abe2875109c8", label: "EGP", currencyCountryId: "66b1d2d452e0e474b79b6a58" }, // Egypt
    { value: "67869e62bc74f3bd0555e481", label: "ERN", currencyCountryId: "66f5896b18c9a3c6d4f4dc5a" }, // Eritrea
    { value: "66e3fb6dbee3abe2875109c9", label: "ETB", currencyCountryId: "66f58c7418c9a3c6d4f4dc62" }, // Ethiopia
    { value: "66e3fb6dbee3abe2875109ca", label: "EUR", currencyCountryId: "66f2db56bb5b9582252eeef7" }, // Belgium (Eurozone, placeholder)
    { value: "66e3fb6dbee3abe2875109cb", label: "FJD", currencyCountryId: "66f58d4418c9a3c6d4f4dc66" }, // Fiji
    { value: "66e3fb6dbee3abe2875109cc", label: "GBP", currencyCountryId: "66af2052680c82111a7e34bd" }, // United Kingdom
    { value: "678691dfbc74f3bd0555e465", label: "GEL", currencyCountryId: "66f0353ac700af86f7cb4431" }, // Georgia
    { value: "66e3fb6dbee3abe2875109cd", label: "GHS", currencyCountryId: "66f37d2c71a18706fbd3adfe" }, // Ghana
    { value: "66e3fb6dbee3abe2875109ce", label: "GIP", currencyCountryId: "66f52a1918c9a3c6d4f4dc0e" }, // Gibraltar (not in countriesOptions, placeholder)
    { value: "66e3fb6dbee3abe2875109cf", label: "GMD", currencyCountryId: "66f4d25b18c9a3c6d4f4db8c" }, // Gambia
    { value: "66e3fb6dbee3abe2875109d0", label: "GNF", currencyCountryId: "66f4af5171a18706fbd3ae6e" }, // Guinea
    { value: "66e3fb6dbee3abe2875109d1", label: "GTQ", currencyCountryId: "66f6011b18c9a3c6d4f4dc86" }, // Guatemala
    { value: "66e3fb6dbee3abe2875109d2", label: "GYD", currencyCountryId: "66f6047b18c9a3c6d4f4dc8e" }, // Guyana
    { value: "66e3fb6dbee3abe2875109d3", label: "HKD", currencyCountryId: "66f010bcc700af86f7cb3f4d" }, // Hong Kong
    { value: "66e3fb6dbee3abe2875109d4", label: "HNL", currencyCountryId: "66f6096918c9a3c6d4f4dc96" }, // Honduras
    { value: "66e3fb6dbee3abe2875109d5", label: "HRK", currencyCountryId: "66f00f05c700af86f7cb3f48" }, // Croatia
    { value: "66e3fb6dbee3abe2875109d6", label: "HTG", currencyCountryId: "66f4cbe718c9a3c6d4f4db74" }, // Haiti
    { value: "66e3fb6dbee3abe2875109d7", label: "HUF", currencyCountryId: "66f2e1a4bb5b9582252eef0b" }, // Hungary
    { value: "66e3fb6dbee3abe2875109d8", label: "IDR", currencyCountryId: "66f03679c700af86f7cb4435" }, // Indonesia
    { value: "66e3fb6dbee3abe2875109d9", label: "ILS", currencyCountryId: "66f01f1ec700af86f7cb4425" }, // Israel
    { value: "66e3fb6dbee3abe2875109a5", label: "INR", currencyCountryId: "66a1e7b2e3e1ced4992a2617" }, // India
    { value: "66e3fb6dbee3abe2875109da", label: "IQD", currencyCountryId: "66f60fde18c9a3c6d4f4dc9e" }, // Iraq
    { value: "66e3fb6dbee3abe2875109db", label: "ISK", currencyCountryId: "66f3663f71a18706fbd3ade6" }, // Iceland
    { value: "66e3fb6dbee3abe2875109dc", label: "JMD", currencyCountryId: "66f04219c700af86f7cb4455" }, // Jamaica
    { value: "66e3fb6dbee3abe2875109dd", label: "JOD", currencyCountryId: "66f03e16c700af86f7cb444d" }, // Jordan
    { value: "66e3fb6dbee3abe2875109de", label: "JPY", currencyCountryId: "66af2080680c82111a7e34c1" }, // Japan
    { value: "66e3fb6dbee3abe2875109df", label: "KES", currencyCountryId: "66f6112818c9a3c6d4f4dca2" }, // Kenya
    { value: "66e3fb6dbee3abe2875109e0", label: "KGS", currencyCountryId: "66f380e371a18706fbd3ae06" }, // Kyrgyzstan
    { value: "66e3fb6dbee3abe2875109e1", label: "KHR", currencyCountryId: "66f03418c700af86f7cb442d" }, // Cambodia
    { value: "66e3fb6dbee3abe2875109e2", label: "KMF", currencyCountryId: "66f5796618c9a3c6d4f4dc36" }, // Comoros
    { value: "66e3fb6dbee3abe2875109e3", label: "KRW", currencyCountryId: "66b1d2d452e0e474b79b6a50" }, // South Korea
    { value: "66e3fb6dbee3abe2875109e4", label: "KWD", currencyCountryId: "66f2e958bb5b9582252eef2a" }, // Kuwait
    { value: "66e3fb6dbee3abe2875109e5", label: "KYD", currencyCountryId: "66f52a1918c9a3c6d4f4dc0e" }, // Cayman Islands (not in countriesOptions, placeholder)
    { value: "66e3fb6dbee3abe2875109e6", label: "KZT", currencyCountryId: "66f2e958bb5b9582252eef2a" }, // Kazakhstan
    { value: "66e3fb6dbee3abe2875109e7", label: "LAK", currencyCountryId: "66f6158018c9a3c6d4f4dcae" }, // Laos
    { value: "678692a1bc74f3bd0555e469", label: "LBP", currencyCountryId: "66f3628371a18706fbd3ade2" }, // Lebanon
    { value: "66e3fb6dbee3abe2875109e8", label: "LKR", currencyCountryId: "66f043d6c700af86f7cb445d" }, // Sri Lanka
    { value: "66e3fb6dbee3abe2875109e9", label: "LRD", currencyCountryId: "66f6181918c9a3c6d4f4dcb6" }, // Liberia
    { value: "66e3fb6dbee3abe2875109ea", label: "LSL", currencyCountryId: "66f6167918c9a3c6d4f4dcb2" }, // Lesotho
    { value: "66e3fb6dbee3abe2875109eb", label: "MAD", currencyCountryId: "66f03d01c700af86f7cb4449" }, // Morocco
    { value: "66e3fb6dbee3abe2875109ec", label: "MDL", currencyCountryId: "66f4ce7818c9a3c6d4f4db7c" }, // Moldova
    { value: "66e3fb6dbee3abe2875109ed", label: "MGA", currencyCountryId: "66f4a64971a18706fbd3ae52" }, // Madagascar
    { value: "67869378bc74f3bd0555e471", label: "MGF", currencyCountryId: "66f4a7e971a18706fbd3ae56" }, // Madagascar (old currency, placeholder)
    { value: "66e3fb6dbee3abe2875109ee", label: "MKD", currencyCountryId: "66f3768471a18706fbd3adea" }, // North Macedonia
    { value: "66e3fb6dbee3abe2875109ef", label: "MMK", currencyCountryId: "66f379ff71a18706fbd3adf6" }, // Myanmar
    { value: "678692dcbc74f3bd0555e46b", label: "MMR", currencyCountryId: "66f379ff71a18706fbd3adf6" }, // Myanmar (old currency, placeholder)
    { value: "66e3fb6dbee3abe2875109f0", label: "MNT", currencyCountryId: "66fa11df9454e950b7150556" }, // Mongolia
    { value: "66e3fb6dbee3abe2875109f1", label: "MOP", currencyCountryId: "66f03a2fc700af86f7cb443d" }, // Macau (not in countriesOptions, placeholder)
    { value: "66e3fb6dbee3abe2875109f2", label: "MUR", currencyCountryId: "66f378c071a18706fbd3adf2" }, // Mauritius
    { value: "66e3fb6dbee3abe2875109f3", label: "MVR", currencyCountryId: "66a1ed94e3e1ced4992a2622" }, // Maldives
    { value: "66e3fb6dbee3abe2875109f4", label: "MWK", currencyCountryId: "66f4ad1471a18706fbd3ae66" }, // Malawi
    { value: "66e3fb6dbee3abe2875109f5", label: "MXN", currencyCountryId: "66b1d2d452e0e474b79b6a36" }, // Mexico
    { value: "66e3fb6dbee3abe2875109f6", label: "MYR", currencyCountryId: "66f03a2fc700af86f7cb443d" }, // Malaysia
    { value: "66e3fb6dbee3abe2875109f7", label: "MZN", currencyCountryId: "66f4a7e971a18706fbd3ae56" }, // Mozambique
    { value: "66e3fb6dbee3abe2875109f8", label: "NAD", currencyCountryId: "66f3823f71a18706fbd3ae0a" }, // Namibia
    { value: "66e3fb6dbee3abe2875109f9", label: "NGN", currencyCountryId: "66fa15879454e950b715056c" }, // Nigeria
    { value: "66e3fb6dbee3abe2875109fa", label: "NIO", currencyCountryId: "66fa14ad9454e950b7150562" }, // Nicaragua
    { value: "66e3fb6dbee3abe2875109fb", label: "NOK", currencyCountryId: "66f2d4c8bb5b9582252eeeeb" }, // Norway
    { value: "66e3fb6dbee3abe2875109fc", label: "NPR", currencyCountryId: "66f04540c700af86f7cb4461" }, // Nepal
    { value: "66e3fb6dbee3abe2875109fd", label: "NZD", currencyCountryId: "66f0391fc700af86f7cb4439" }, // New Zealand
    { value: "66e3fb6dbee3abe2875109fe", label: "OMR", currencyCountryId: "66f2e6abbb5b9582252eef22" }, // Oman
    { value: "6786930abc74f3bd0555e46d", label: "PAB", currencyCountryId: "66f37c4d71a18706fbd3adfa" }, // Panama
    { value: "66e3fb6dbee3abe2875109ff", label: "PEN", currencyCountryId: "66f2df27bb5b9582252eef03" }, // Peru
    { value: "66e3fb6dbee3abe287510a00", label: "PGK", currencyCountryId: "66fb3f3e9454e950b7150a25" }, // Papua New Guinea
    { value: "66e3fb6dbee3abe287510a01", label: "PHP", currencyCountryId: "66f2dc9fbb5b9582252eeefb" }, // Philippines
    { value: "66e3fb6dbee3abe287510a02", label: "PKR", currencyCountryId: "66fa1b2f9454e950b7150574" }, // Pakistan
    { value: "66e3fb6dbee3abe287510a03", label: "PLN", currencyCountryId: "66f2a31bbb5b9582252eeedb" }, // Poland
    { value: "66e3fb6dbee3abe287510a04", label: "PYG", currencyCountryId: "66fb40e19454e950b7150a29" }, // Paraguay
    { value: "66e3fb6dbee3abe287510a05", label: "QAR", currencyCountryId: "66f042b8c700af86f7cb4459" }, // Qatar
    { value: "67869eaebc74f3bd0555e484", label: "QTQ", currencyCountryId: "66f042b8c700af86f7cb4459" }, // Qatar (old currency, placeholder)
    { value: "66e3fb6dbee3abe287510a06", label: "RON", currencyCountryId: "66f2d9a2bb5b9582252eeef3" }, // Romania
    { value: "66e3fb6dbee3abe287510a07", label: "RSD", currencyCountryId: "66fb58b59454e950b7150a45" }, // Serbia
    { value: "66e3fb6dbee3abe287510a08", label: "RUB", currencyCountryId: "66b1d2d452e0e474b79b6a4e" }, // Russia
    { value: "66e3fb6dbee3abe287510a09", label: "RWF", currencyCountryId: "66f4b8ba71a18706fbd3ae72" }, // Rwanda
    { value: "66e3fb6dbee3abe287510a0a", label: "SAR", currencyCountryId: "66b1d2d452e0e474b79b6a56" }, // Saudi Arabia
    { value: "66e3fb6dbee3abe287510a0b", label: "SCR", currencyCountryId: "66fb5c189454e950b7150a49" }, // Seychelles
    { value: "678693b7bc74f3bd0555e473", label: "SDG", currencyCountryId: "66f4ab3171a18706fbd3ae5e" }, // Sudan
    { value: "66e3fb6dbee3abe287510a0c", label: "SEK", currencyCountryId: "66f2b816bb5b9582252eeee3" }, // Sweden
    { value: "66e3fb6dbee3abe287510a0d", label: "SGD", currencyCountryId: "66f000a0acfb5411bf02f3cc" }, // Singapore
    { value: "66e3fb6dbee3abe287510a0e", label: "SLL", currencyCountryId: "66f4d32818c9a3c6d4f4db90" }, // Sierra Leone
    { value: "66e3fb6dbee3abe287510a0f", label: "SOS", currencyCountryId: "66fb62449454e950b7150a55" }, // Somalia
    { value: "66e3fb6dbee3abe287510a10", label: "SSP", currencyCountryId: "66f4ab3171a18706fbd3ae5e" }, // South Sudan
    { value: "66e3fb6dbee3abe287510a11", label: "SVC", currencyCountryId: "66f6011b18c9a3c6d4f4dc86" }, // El Salvador
    { value: "6786933abc74f3bd0555e46f", label: "SYP", currencyCountryId: "66f4a56e71a18706fbd3ae4e" }, // Syria
    { value: "66e3fb6dbee3abe287510a12", label: "SZL", currencyCountryId: "66f4cfef18c9a3c6d4f4db80" }, // Eswatini
    { value: "66e3fb6dbee3abe287510a13", label: "THB", currencyCountryId: "66a1e900e3e1ced4992a261e" }, // Thailand
    { value: "6786954cbc74f3bd0555e475", label: "TJS", currencyCountryId: "66f4ca7a18c9a3c6d4f4db70" }, // Tajikistan
    { value: "66e3fb6dbee3abe287510a14", label: "TND", currencyCountryId: "66f2e2f8bb5b9582252eef12" }, // Tunisia
    { value: "66e3fb6dbee3abe287510a15", label: "TRY", currencyCountryId: "66b1d2d452e0e474b79b6a52" }, // Turkey
    { value: "66e3fb6dbee3abe287510a16", label: "TTD", currencyCountryId: "66fb68189454e950b7150a61" }, // Trinidad and Tobago
    { value: "66e3fb6dbee3abe287510a17", label: "TWD", currencyCountryId: "66f2e5d5bb5b9582252eef1e" }, // Taiwan
    { value: "66e3fb6dbee3abe287510a18", label: "TZS", currencyCountryId: "66f4c3d218c9a3c6d4f4db68" }, // Tanzania
    { value: "66e3fb6dbee3abe287510a19", label: "UAH", currencyCountryId: "66fb6aec9454e950b7150a70" }, // Ukraine
    { value: "66e3fb6dbee3abe287510a1a", label: "UGX", currencyCountryId: "66f4a36371a18706fbd3ae46" }, // Uganda
    { value: "66e3fb6dbee3abe287510a1b", label: "USD", currencyCountryId: "66accaa82a29f0a20896b246" }, // United States
    { value: "66e3fb6dbee3abe287510a1c", label: "UYU", currencyCountryId: "66fb6e4b9454e950b7150a78" }, // Uruguay
    { value: "66e3fb6dbee3abe287510a1d", label: "UZS", currencyCountryId: "66f2f33171a18706fbd3adc6" }, // Uzbekistan
    { value: "66e3fb6dbee3abe287510a1e", label: "VND", currencyCountryId: "66cf46a1534e410281d2847e" }, // Vietnam
    { value: "66e3fb6dbee3abe287510a1f", label: "VUV", currencyCountryId: "66fb6efd9454e950b7150a7c" }, // Vanuatu
    { value: "66e3fb6dbee3abe287510a20", label: "XAF", currencyCountryId: "66f4baac18c9a3c6d4f4db59" }, // Central African CFA franc (multiple countries, placeholder)
    { value: "66e3fb6dbee3abe287510a21", label: "XCD", currencyCountryId: "66f58e5f18c9a3c6d4f4dc6a" }, // East Caribbean dollar (multiple countries, placeholder)
    { value: "66e3fb6dbee3abe287510a22", label: "XOF", currencyCountryId: "66f4ae0471a18706fbd3ae6a" }, // West African CFA franc (multiple countries, placeholder)
    { value: "66e3fb6dbee3abe287510a23", label: "XPF", currencyCountryId: "66f6127118c9a3c6d4f4dca6" }, // CFP franc (multiple countries, placeholder)
    { value: "66e3fb6dbee3abe287510a24", label: "YER", currencyCountryId: "66fb71329454e950b7150a84" }, // Yemen
    { value: "66e3fb6dbee3abe287510a25", label: "ZAR", currencyCountryId: "66b1d2d452e0e474b79b6a4c" }, // South Africa
    { value: "66e3fb6dbee3abe287510a26", label: "ZMW", currencyCountryId: "66f42beb71a18706fbd3ae26" }, // Zambia
];


const SendOffer = ({ details, editProposal, setProposal, textFieldFocus, handleSubmit, userInfo, proposal, err, isLoading, }) => {
    return (
        <>
            {(details?.status == 0 || editProposal?.length > 0) && (
                <>
                    {(userInfo == null || userInfo?.role == 'vendor') &&
                        <div className='px-1 mt-5 mb-5'>
                            <label className='Rubik-Medium fs_13 text-muted mb-1'>Enter your offer <span className='Rubik-Regular gradient-text'>(For Travel Agents)</span></label>

                            <form onSubmit={handleSubmit}>
                                <div className="form-group mb-3 mt-1">
                                    {/* <label className='Source_Medium fs_14 text-muted mb-1'>Enter details what your package offers on this (minimum 100 characters)</label> */}
                                    <textarea
                                        ref={textFieldFocus}
                                        name="offer"
                                        placeholder='Describe your itinerary here like including inclusion, exclusion, pricing, pick and drop location, availability and special benefits for travelers. Ensure your offer is clear, engaging, and meets the traveler’s needs (minimum 100 characters).'
                                        rows={7}
                                        style={{ borderRadius: 8 }}
                                        className="form-control Source_Regular fs_14 text_gray outline-none border_gray"
                                        value={proposal}
                                        onChange={(event) => {
                                            if (event.target.value?.length <= 1500) {
                                                setProposal(event.target.value);
                                            }
                                        }} />
                                    <p className='Rubik-Regular text_gray2 fs_11 mt-1 m-0'>{1500 - proposal?.trim()?.length} characters left</p>

                                    {err.desc && <p className='fs_13 mb-2 pt-0 mt-1 text-danger'>{err.desc}</p>}
                                </div>

                                <div className='d-flex align-items-end justify-content-end'>
                                    {/* <button type="submit" className="btn bg-gradient-thm text-white px-3 btn-block mt-3 fs_14 pt-1" disabled={isLoading}>
                            
                          </button> */}
                                    <button className='border-0 bg-gradient-thm h-40px d-inline-flex px-5 rd_6 justify-content-center align-items-center'>
                                        <p className='Rubik-Regular fs_12 text-white pointer m-0'>{isLoading ? <Spinner size='sm' /> : 'Send Offer'}</p>
                                    </button>
                                </div>
                            </form>

                        </div>}
                </>
            )}
        </>
    )
}


const useMaskEmail = (email) => {
    const [name, domain] = email?.split('@');

    const maskedUsername = name?.slice(0, 2) + '*****' + name?.slice(-2);

    return `${maskedUsername}@${domain}`
}

const useMaskPhone = (number) => {

    const maskedNumber = number?.slice(0, 3) + '****' + number?.slice(-2);

    return maskedNumber
}

export { disabledDate, SendOffer, useMaskPhone, useMaskEmail, carTypes, countriesOptions, currenciesOptions, checkSlug, fuelType, amenitiesArray, lookingForOptions, timeAgo, generateRandomText, openInNewTab, avgRatingCalculate, reviewOfSummary, useCountryGet, customStyle }