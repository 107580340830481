import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { GuiderList, Hotels, PkgList } from './Images'
import { FormVehicleModal, ParagraphModal } from './Modal'
import { Link } from 'react-router-dom'
import Carousel from 'react-multi-carousel'
import { CustomLeftArrow } from './CustomArrow'
import { useDispatch, useSelector } from 'react-redux'
import { svModalStatus } from '../ContextRedux/Action'
import SignInModal from '../Pages/Auth/SignInModal'
const img_base_url = process.env.REACT_APP_IMG_BASE_URL;

const responsive_pkg = {
    desktop1: {
        breakpoint: { max: 2000, min: 1150 },
        items: 4,
        slidesToSlide: 4
    },
    desktop: {
        breakpoint: { max: 1150, min: 1024 },
        items: 3,
        slidesToSlide: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 828 },
        items: 3,
        slidesToSlide: 3
    },
    mobile: {
        breakpoint: { max: 828, min: 560 },
        items: 2,
        slidesToSlide: 2
    },
    mobilex: {
        breakpoint: { max: 560, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};

function RecommendedVehicle() {
    const [open3, setOpen3] = useState(false)
    const [open4, setOpen4] = useState(false)

    const termsCharges = `
    - Vehicle and fuel charges included
    
    - Driver Night Charges included
    
    - For Round trip bookings, all the local sightseeing in the destination cities is included except for Sikkim and Darjeeling. For local sightseeing in Pickup city (if required), please add all the Pickup city stops in itinerary.
    
    - 5% GST Extra
    
    - Included Kilometers will start from pickup location
    
    - Driver allowance includes driver's stay, food and night charges
    
    - Toll and state tax extra
    
    - Parking charges extra if applicable
    
    - AC will remain switch off in hill areas
    
    - For round trip booking, Kilometers will count from pickup location to pickup location
    
    - One way trip includes only one pickup and one drop. Additional pickup or drop on the way will incur additional charges of Rs. 250 per pickup / drop.
    
    - Rohtang Pass needs special permit and is not included in any trip
        `
    return (
        <>
            <Row className='bg-primary1 mt-3'>
                {/* Listing */}
                {Hotels.slice(0, 4).map((item, index) => (
                    <Col key={index} xs={6} sm={6} md={4} lg={3} className="m-xxs-auto mb-4 w-xxs-90 bg-warning1">
                        <div className='mb-xs-10 shadow-n1 bg-white rd_6 p-0 overflow-hidden position-relative'>
                            <div className='bg-warning'>
                                <img alt='Vehicle Img' src={'https://www.carandbike.com/_next/image?url=https%3A%2F%2Fmedia.mahindrafirstchoice.com%2Flive_web_images%2Fusedcarsimg%2Fmfc%2F405%2F598164%2Fcover_image-20231223151645.jpeg&w=750&q=75'} className='img-fluid h_150px w-100 position-relative bg-primary overflow-hidden' />
                            </div>
                            <div className='p-1 px-2' style={{
                                position: 'absolute',
                                top: 0,
                                width: 'auto',
                                height: 'auto',
                                borderBottomRightRadius: 10,
                                background: 'rgba(0,0,0,0.6)'
                            }}>
                                <p className='Source_Regular text-white fs_12 m-0'>Starting ₹8932</p>
                            </div>
                            <h3 className='Source_SemiBold text_gray fs_15 fs-xs-14 spacing ps-2 pt-2'>Used Hyundai Verna</h3>

                            <div className="p-2 px-2-5 pb-3 overflow-hidden position-relative">
                                <div className='d-flex align-items-center Source_Medium'>
                                    <p className='text-secondary fs_14 bg-warning1 m-0 flex-grow-1'>Seat Included</p>
                                    <p className='text-secondary fs_14 bg-warning1 m-0 text_gray2'>4</p>
                                </div>
                                <div className='d-flex align-items-center Source_Medium'>
                                    <p className='text-secondary fs_14 bg-warning1 m-0 flex-grow-1'>Luggage Bag</p>
                                    <p className='text-secondary fs_14 bg-warning1 m-0 text_gray2'>1</p>
                                </div>
                                <div className='d-flex align-items-center Source_Medium'>
                                    <p className='text-secondary fs_14 bg-warning1 m-0 flex-grow-1'>Driver Charges</p>
                                    <p className='text-secondary fs_14 bg-warning1 m-0 text_gray2'>Included</p>
                                </div>
                                <div className='d-flex align-items-center Source_Medium'>
                                    <p className='text-secondary fs_14 bg-warning1 m-0 flex-grow-1'>Per KM</p>
                                    <p className='text-secondary fs_14 bg-warning1 m-0 text_gray2'> ₹11</p>
                                </div>

                                <label className='Source_Regular text-info fs_12 mb-1 pointer' onClick={() => setOpen4(true)}>Other Terms & Charges</label>

                                <div className='pointer p-1 mt-2 rd_50 h-xxs-35px border_thm text_thm Source_Medium d-flex align-items-center justify-content-center' onClick={() => setOpen3(true)}>
                                    Book Now
                                </div>
                            </div>

                        </div>
                    </Col>
                ))}
            </Row>

            {/* Other Charges */}
            <ParagraphModal isVisible={open4} onClose={setOpen4} text={termsCharges} />

            {/* From */}
            <FormVehicleModal isVisible={open3} onClose={setOpen3} />
        </>
    )
}

const RecoGuider = () => {
    const [open3, setOpen3] = useState(false)
    return (
        <>
            <Row className='bg-primary1 pt-2 bg-light'>
                {/* Listing */}
                {GuiderList.slice(0, 4).map((item) => (
                    <Col xs={6} sm={6} md={4} lg={3} xl={3} className="m-xxs-auto mb-4  bg-warning1 px-xl-4">
                        <div className='mb-xs-10 bg-white1 p-2 text-center shadow-n1 h_250px mt-1 rd_6 bg-white bg-gradient d-flex justify-content-between flex-column'>
                            <Link to={'/guiders/details'} className='text-decoration-none hover-me'>
                                <img src={item.img} alt='Guider Image' className='img-fluid hw_80px rounded-circle position-relative overflow-hidden' />

                                <h3 className='Source_SemiBold text_gray fs_15 fs-xs-14 spacing mt-3 m-0'>{item.name}</h3>
                                <p className={`m-0 text-secondary fs_12 Source_Regular bg-warning1`}>{item.loc}</p>
                            </Link>
                            <div>
                                <div className='d-flex Source_Medium fs_13 text-secondary'>
                                    <p className='p-0 m-0'>Hourly price</p>
                                    <p className='flex-grow-1 m-0'></p>
                                    <p className='m-0 text_thmdark'>₹389</p>
                                </div>
                                <div className='d-flex Source_Medium fs_13 text-secondary'>
                                    <p className='m-0'>Experience</p>
                                    <p className='flex-grow-1 m-0'></p>
                                    <p className='m-0 text_thmdark'>12 Yrs</p>
                                </div>
                            </div>

                            <div className='pointer p-1 mt-2 rd_50 fs_12 px-2 bg_thm h-xxs-35px text-white Source_Medium d-flex align-items-center justify-content-center' onClick={() => setOpen3(true)}>
                                Book Now
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>

            {/* From */}
            <FormVehicleModal isVisible={open3} onClose={setOpen3} />
        </>
    )
}

const PkgContainer = ({ pkgsList }) => {
    return (
        <div className='w-100p'>
            <Carousel
                swipeable={true}
                draggable={true}
                responsive={responsive_pkg}
                ssr={true}
                infinite
                keyBoardControl={true}
                customTransition="transform 1000ms ease-in-out"
                containerClass="carousel-container"
                itemClass="carousel-item1 "
                customLeftArrow={
                    <CustomLeftArrow
                        direction="left"
                    />
                }
                customRightArrow={
                    <CustomLeftArrow
                        direction="right"
                    />
                }
                removeArrowOnDeviceType={["desktop1", "desktop",]}
                dotListClass="custom-dot-list-style"
            >
                {pkgsList && pkgsList?.map((item, index) => {
                    const lowPrice = item.discountPrice
                    const final = (lowPrice == null || lowPrice == 0) ? item.price : lowPrice

                    return (
                        <div key={item._id} className='hover-highlight-pkg pkg-btn-hover border_white br-2 mt-3 mb-4 rd-10px mx-2-5 pointer shadow-slowclean p-5px pb-0px' style={{ backgroundColor: '#fff' }}>
                            <Link target='_blank' rel="noopener noreferrer" to={`/package/${item?.slug}/${item.id}`} state={{ type_id: item._id, type: 'Package' }} className='text-decoration-none hover-me '>
                                <img alt={item?.name} src={`${img_base_url + item?.image}`} className='h_150px w-100p rd-10px' />

                                <div className='p-2-5'>
                                    <div className='Rubik-SemiBold fs-14px text_gray text_dark_medium m-0 p-0 mini-line-1'>{item?.name}</div>
                                    <p className='NunitoSans-Regular text_gray2 fs_11 mini-line-1 '>By <span className='text_thm NunitoSans-SemiBold'>{item?.vendorId?.business_name}</span></p>

                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div>
                                            <p className='Rubik-Regular fs-10px text_gray2 mini-line-1 m-0'>Starting Price</p>
                                            <div className='Rubik-Regular fs_14 text-muted'><strong className='text_dark_medium fs_15'>{item?.currency?.code}{final}</strong>/Person</div>
                                            {lowPrice != null && lowPrice > 0 && (
                                                <div className='Rubik-Regular fs_12 TxtCut text-secondary'>{item?.currency?.code}{item.price}</div>
                                            )}
                                        </div>
                                        <div className='book-now border_thmdark rounded-5 fs_13 h_30px Rubik-Light d-flex align-items-center px-2-5 text_thm'>View Details</div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    )
                })}
            </Carousel>
        </div>
    )
}

const RecoHotel = () => {
    return (
        <>
            <Row className='bg-primary1'>
                {/* Listing */}
                {Hotels.slice(0, 4).map((item, index) => (
                    <Col xs={6} sm={6} md={4} lg={3} key={index} className="m-xxs-auto mb-4 w-xxs-90 bg-warning1">
                        <div className='mb-xs-10 shadow-n1 bg-white rd_6 p-0 overflow-hidden position-relative'>
                            <Link to={'/hotel/details'} className='text-decoration-none hover-me'>
                                <img src={item.img} alt='Hotel Image' className='img-fluid h_150px w-100 position-relative overflow-hidden' />
                                <div className='p-1 px-2' style={{
                                    position: 'absolute',
                                    top: 0,
                                    width: 'auto',
                                    height: 'auto',
                                    borderBottomRightRadius: 10,
                                    background: 'rgba(0,0,0,0.6)'
                                }}>
                                    <p className='Source_Regular text-white fs_12 m-0'>Starting ₹8932</p>
                                </div>
                                <div className="p-2 overflow-hidden position-relative">
                                    <h3 className='Source_SemiBold text_gray fs_15 fs-xs-14 spacing'>{item.name}</h3>
                                    <div className='d-flex align-items-center'>
                                        <i className='material-symbols-outlined fs_15 text_thm'>home_pin</i>
                                        <p className='Source_Regular text_gray fs_14 m-0'>{item.address}</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </Col>
                ))}
            </Row>
        </>
    )
}

const QuerySpons = ({ mt }) => {
    const user1 = useSelector(state => state.data.info)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch()

    const openModal = () => {
        dispatch(svModalStatus({ status: true }))
        setIsModalOpen(true);
    }

    const closeModal = () => {
        dispatch(svModalStatus({ status: false }))

        setIsModalOpen(false);
    }
    return (
        <>
            <div id='query' className='d-flex bg-sky w-100 mt-120px' style={{ marginTop: mt }}>
                <img src={'/images/man_with_lauggage.png'} alt='Travel Post' className='img-fluid position-relative d-none-on-lg me-5' />
                <div className='bg-warning1 flex-grow-1 ms-2 d-flex flex-column justify-content-center align-items-center'>
                    <p className='m-0 fw-bold fs_16 mt-4'>Tired of Searching?</p>
                    <p className='m-0 Rubik-Regular text-secondary fs_13 mb-4 text-center mt-1'>Post your travel upcoming travel details on travellinglark.com and get offer quotations from multiple travel agencies. Choose an offer that meets your budget and requirements, save money and enjoy your travel. Post now!</p>
                    <Link to={'/post-travel'} className='btn bg-white mt-4 Rubik-Regular fs_13 text_thm'>
                        Post Your Travel
                    </Link>
                </div>
            </div>

            <SignInModal isOpen={isModalOpen} onClose={closeModal} redirect={'/post-travel'} />
        </>
    )
}

const TiredOfSearching = ({ mt }) => {
    const user1 = useSelector(state => state.data.info)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch()

    const openModal = () => {
        dispatch(svModalStatus({ status: true }))
        setIsModalOpen(true);
    }

    const closeModal = () => {
        dispatch(svModalStatus({ status: false }))

        setIsModalOpen(false);
    }
    
    return (
        <>
            <div className='rd_6 d-flex align-items-center flex-column bg-white p-3 px-4'>
                <img src='/images/man_with_lauggage.png' alt='Traveller Boy' className='img-fluid h_130px w_200px rotate-img-flip' />
                <p className='m-0 fw-bold fs_16 mt-4'>Tired of Searching?</p>
                <p className='m-0 Rubik-Regular text-secondary fs_13 mb-4 text-center mt-1'>Post your travel upcoming travel details on travellinglark.com and get offer quotations from multiple travel agencies. Choose an offer that meets your budget and requirements, save money and enjoy your travel. Post now!</p>

                <Link to={'/post-travel'}>
                    <button className='bg-gradient-thm btn w-auto mt-3 d-flex flex-grow-1 justify-content-center align-items-center rd_6 text-white pointer h_40px fs_14 Source_Medium px-4'>
                        Post Your Travel
                    </button>
                </Link>
            </div>

            <SignInModal isOpen={isModalOpen} onClose={closeModal} redirect={'/post-travel'} />
        </>
    )
}

export { RecommendedVehicle, RecoGuider, PkgContainer, RecoHotel, QuerySpons, TiredOfSearching }