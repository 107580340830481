import React, { createContext, useContext, useState, useEffect } from 'react'
import { svChatCountStatus, svInfo, svMsgId, svReceiverDetails, svTripCate } from './Action';
import { useDispatch } from "react-redux";

export const MemContext = createContext();


function Context({ children }) {
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [isRegistering, setIsRegistering] = useState(false);
    const [isLoading, setIsLoading] = useState(true)
    const [unId, setunId] = useState(null)
    const [userInfo, setUserInfo] = useState(null)
    const dispatch = useDispatch()

    useEffect(() => {
        const userLoggedIn = localStorage.getItem("isLoggedIn");
        const userRegistered = localStorage.getItem("isRegistered");
        const modalShown = localStorage.getItem("modalShown");

        if (userLoggedIn === "true" && !modalShown) {
            setIsLoggingIn(true);
            localStorage.setItem("modalShown", "true");
        } else if (userRegistered === "true" && !modalShown) {
            setIsRegistering(true);
            localStorage.setItem("modalShown", "true");
        }
    }, []);

    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === 'userD') {
                const isLoggedIn = !!event.newValue;
                setUserInfo(event.newValue);

                if (!isLoggedIn) {
                    dispatch(svInfo({}));
                    dispatch(svTripCate([]));
                    dispatch(svChatCountStatus(''));
                    dispatch(svMsgId({}));
                    dispatch(svReceiverDetails({}));
                }
            }

            if (event.key === 'unId') {
                window.location.href = '/';

                const isLoggedIn = !!event.newValue;

                setunId(event.newValue);
                if (!isLoggedIn) {
                    dispatch(svInfo({}));
                    dispatch(svChatCountStatus(''));
                    dispatch(svTripCate([]));
                    dispatch(svMsgId({}));
                    dispatch(svReceiverDetails({}));
                }
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [dispatch]);

    const login = (data, unId) => {
        setIsLoading(true);
        let userInfo = data
        localStorage.setItem('userD', JSON.stringify(data))
        localStorage.setItem('unId', unId)
        setUserInfo(userInfo);
        setunId(unId);
        setIsLoading(false);
    }

    const logout = async () => {
        setIsLoading(true);
        setunId(null);
        setUserInfo(null);
        dispatch(svInfo({}));
        dispatch(svChatCountStatus(''));
        dispatch(svTripCate([]));
        dispatch(svMsgId({}));
        dispatch(svReceiverDetails({}));

        localStorage.removeItem('userD');
        localStorage.removeItem('unId');
        setIsLoading(false);
    }

    useEffect(() => {
        isLoggedIn();
    }, []);

    const isLoggedIn = async () => {
        try {
            setIsLoading(true);

            let userInfo = await localStorage.getItem('userD');
            let unId = await localStorage.getItem('unId');
            userInfo = JSON.parse(userInfo);

            if (userInfo) {
                setUserInfo(userInfo);
                setunId(unId);
            }
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    }

    const toggleLogin = () => {
        setIsLoggingIn(!isLoggingIn);

        if (!isLoggingIn) {
            setTimeout(() => {
                setIsLoggingIn(false);
            }, 3000);
        }
    };

    const toggleRegister = () => {
        setIsRegistering(!isRegistering);

        if (!isRegistering) {
            setTimeout(() => {
                setIsRegistering(false);
            }, 3000);
        }
    };

    return (
        <MemContext.Provider value={{
            login,
            logout,
            isLoading,
            unId,
            userInfo,
            toggleLogin,
            toggleRegister,
            isRegistering,
            isLoggingIn
        }}>
            {children}
        </MemContext.Provider>
    )
}

export default Context

// export const MemUser = () => useContext(MemContext)