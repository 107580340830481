import React from 'react';

interface GradientTextProps {
  text: string;
  direction?:string;
  gradientColors: string[];
  className?: string;
}

const GradientText: React.FC<GradientTextProps> = ({ text, direction = "to right", gradientColors, className }) => {
  const gradient = `linear-gradient(${direction}, ${gradientColors.join(', ')})`;

  return (
    <span
      className={className}
      style={{
        background: gradient,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        display: 'inline-block',
      }}
    >
      {text}
    </span>
  );
};

export default GradientText;