import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import { dec, enc } from '../../Componets/SecRes';
import { CommonAPI } from '../../Componets/CommonAPI';
import { CustomLeftArrow } from '../../Componets/CustomArrow';
import { lookingForOptions, openInNewTab, useCountryGet } from '../../Componets/CommonFs';
import { useLocalStorage } from 'react-fu-hooks';
import { GridCategories, HowItWork } from '../../Componets/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { svModalStatus, svTripCate } from '../../ContextRedux/Action';
// import MessagingPopup from './MessagingPopup';
// import { Spin } from 'antd';
import { Loader } from '../../Componets/Loader';
import { Helmet } from 'react-helmet';
import FaqSection from '../../Componets/FaqSection';
import SignInModal from '../Auth/SignInModal';
import { MemContext } from '../../ContextRedux/Context';
import { Checkbox } from 'antd';
import PostQueryCommon from './Query/PostQueryCommon';

const img_base_url = process.env.REACT_APP_IMG_BASE_URL;
const key = process.env.REACT_APP_KEY;
const vendor_signup_url = process.env.REACT_APP_VENDOR__SIGNUP_URL;

const responsive_blog = {
    xdesktop1: {
        breakpoint: { max: 2560, min: 2000 },
        items: 6,
        slidesToSlide: 6
    },
    desktop: {
        breakpoint: { max: 2000, min: 1024 },
        items: 4,
        slidesToSlide: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 828 },
        items: 3,
        slidesToSlide: 3
    },
    mobile: {
        breakpoint: { max: 828, min: 560 },
        items: 2,
        slidesToSlide: 2
    },
    mobilex: {
        breakpoint: { max: 560, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};

const responsive_review = {
    xdesktop1: {
        breakpoint: { max: 2560, min: 2000 },
        items: 5,
        slidesToSlide: 5
    },
    desktop: {
        breakpoint: { max: 2000, min: 1050 },
        items: 3,
        slidesToSlide: 3
    },
    tablet: {
        breakpoint: { max: 1050, min: 560 },
        items: 2,
        slidesToSlide: 2
    },
    mobilex: {
        breakpoint: { max: 560, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};

const responsive_pkg = {
    desktop1: {
        breakpoint: { max: 2000, min: 1150 },
        items: 4,
        slidesToSlide: 4
    },
    desktop: {
        breakpoint: { max: 1150, min: 1024 },
        items: 3,
        slidesToSlide: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 828 },
        items: 3,
        slidesToSlide: 3
    },
    mobile: {
        breakpoint: { max: 828, min: 560 },
        items: 2,
        slidesToSlide: 2
    },
    mobilex: {
        breakpoint: { max: 560, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};

// const responsive_type = {
//     xdesktop1: {
//         breakpoint: { max: 2560, min: 2000 },
//         items: 11,
//         slidesToSlide: 8
//     },
//     desktop1: {
//         breakpoint: { max: 2000, min: 1200 },
//         items: 9,
//         slidesToSlide: 7
//     },
//     desktop: {
//         breakpoint: { max: 1200, min: 1024 },
//         items: 7,
//         slidesToSlide: 5
//     },
//     tablet: {
//         breakpoint: { max: 1024, min: 768 },
//         items: 5,
//         slidesToSlide: 3
//     },
//     mobile: {
//         breakpoint: { max: 767, min: 559 },
//         items: 4,
//         slidesToSlide: 3
//     },
//     xmobile: {
//         breakpoint: { max: 559, min: 449 },
//         items: 3,
//         slidesToSlide: 2
//     },
//     xsmobile: {
//         breakpoint: { max: 449, min: 0 },
//         items: 2,
//         slidesToSlide: 3
//     },
// };

const responsive_8 = {
    xdesktop1: {
        breakpoint: { max: 2560, min: 2200 },
        items: 7,
        slidesToSlide: 7
    },
    tablet: {
        breakpoint: { max: 2200, min: 968 },
        items: 5,
        slidesToSlide: 5
    },
    mobile: {
        breakpoint: { max: 967, min: 759 },
        items: 4,
        slidesToSlide: 4
    },
    xmobile: {
        breakpoint: { max: 759, min: 549 },
        items: 3,
        slidesToSlide: 3
    },
    xsmobile: {
        breakpoint: { max: 549, min: 0 },
        items: 2,
        slidesToSlide: 2
    },
};

// const categories = [
//     { id: 1, name: "Holiday Packages", imageUrl: "/images/package.jpg", route: 'packages' },
//     { id: 2, name: "Hotels", imageUrl: "/images/hotel.jpg", route: 'hotels' },
//     { id: 3, name: "Vehicle", imageUrl: "/images/taxi.webp", route: 'vehicle-search' },
//     { id: 4, name: "Guiders", imageUrl: "/images/Bag.webp", route: 'guiders' },
// ];

function Home() {
    const { country1 } = useCountryGet();
    const { unId, userInfo } = useContext(MemContext);

    const carouselRef1 = useRef(null);
    const carouselRef3 = useRef(null);
    const carouselRef4 = useRef(null);
    const [currentIndex1, setCurrentIndex1] = useState(0);
    const [currentIndex3, setCurrentIndex3] = useState(0);
    const [currentIndex4, setCurrentIndex4] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [open, setopen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [redirectionUrl, setRedirectionUrl] = useState('');
    const [blogs, setBlogs] = useState([])
    const [countries, setCountries] = useState([])
    const [countriesList, setCountriesList] = useState([])
    const [testimonial, setTestimonial] = useState([])
    const [tripCate, setTripCate] = useState([])
    const navigate = useNavigate()
    const dispatch = useDispatch();
    


    useEffect(() => {
        countryList();
    }, []);

    const countryList = () => {
        CommonAPI({}, 'GET', `countryList`, responseBack2)
    }

    const responseBack2 = useCallback(res => {
        if (res.status === true) {
            const dt = res?.data

            setCountriesList(dt)
        }
    })


    useEffect(() => {
        getDetails();
    }, []);

    useEffect(() => {
        if ('Notification' in window) {
            Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                }
            });
        }
    }, []);


    const getDetails = () => {
        setIsLoading(true)
        const send = {
            countryName: country1
        }

        const enc1 = enc(send, key)
        CommonAPI(enc1, 'POST', `homeV2`, responseBack)
    }

    const responseBack = useCallback((res) => {
        if (res.status === true) {
            const dec1 = dec(res?.data, key);

            setBlogs(dec1?.blog);
            setCountries(dec1?.country);
            setTripCate(dec1?.tripcategories);
            setTestimonial(dec1?.testimonial);

            dispatch(svTripCate(dec1?.tripcategories))
        } else {
        }
        setIsLoading(false)
    })

    const handlePrev = (type) => {
        if (type === 'pkg') {
            if (carouselRef1) {
                carouselRef1.current.previous();
            }
        } else if (type === 'blogs') {
            if (carouselRef3) {
                carouselRef3.current.previous();
            }
        } else {
            if (carouselRef4) {
                carouselRef4.current.previous();
            }
        }
    }

    const handleNext = (type) => {
        if (type === 'pkg') {
            if (currentIndex1 < tripCate.length - 1) {
                carouselRef1.current.next();
            }
        } else if (type === 'blogs') {
            if (currentIndex3 < blogs.length - 1) {
                carouselRef3.current.next();
            }
        } else {
            if (currentIndex4 < testimonial.length - 1) {
                carouselRef4.current.next();
            }
        }

    }


    const [isModalVisible, setModalVisible] = useState(false);


    const videoUrl = "https://www.youtube.com/embed/lqa_y0hV_WI?autoplay=0&mute=0&controls=1&origin=https%3A%2F%2Ftravellinglark.com&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=1";


    useEffect(() => {
        handleShow()
    }, [])

    const statusModal = useSelector(state => state.data?.status)

    const handleShow = (ty) => {
        if (!unId && !statusModal?.status) {
            setTimeout(() => {
                if (!unId && !statusModal?.status) {
                    dispatch(svModalStatus({ status: true }))

                    setIsModalOpen(true);
                }
            }, 5000);
        }
    };

    return (
        <div className='min-vh-100 mt-12 pt-2 d-flex flex-column align-items-center bg-gray'>
            <div className='w_92 bg-succes1s mb-4 mt-5  d-flex align-items-center flex-column'>

                {/* Section 1 */}
                <Row className="bg-warning1 w-100">
                    <Col xxl={8} xl={7} lg={7} md={6} className="bg1-warning d-flex align-items-center flex-column justify-content-center justify-start--vs justify-start--sm justify-start--md pb-2">
                        <div className='bg-secondarhy d-flex align-items-center flex-column  justify-content-center'>
                            <div className='Inter-Bold fs-40px fs-30px--vs'>Say Goodbye To Endless  Searching!</div>
                            <div className='Inter-Regular fs_14 text-secondary mt-2'>
                                Post your travel details and budget, and let travel agent come to you with tailored offers that suit your style and financial needs. Your next adventure is just a post away!
                            </div>

                            <div className='d-flex align-items-center bg1-danger pointer justify-center-xxl w-100 mt-3'>
                                <div className='mt-3 bg_thm h_35px d-inline-flex px-3 mr_10 rd_50 justify-content-center align-items-center' onClick={() => navigate('/post-travel')}>
                                    <p className='Rubik-Regular fs_12 text-white m-0'>Post Your Travel</p>
                                </div>
                                <div className='mt-3 bg-transparent border_thm pointer h_35px d-inline-flex px-3 mr_10 rd_50 justify-content-center align-items-center' onClick={() => setModalVisible(true)}>
                                    <p className='Rubik-Regular fs_12 text_thm  m-0'>How it works</p>
                                    <i className="fa-solid fa-play bg_thm rd_50 text-white p-1 fs_10 ms-2"></i>
                                </div>
                            </div>
                        </div>

                    </Col>
                    <Col className='px-0 d-hide-on-md'>
                        <img src={`/images/illus.webp`} alt='travel' loading='lazy' className='img-fluid  h_380px' />
                    </Col>
                </Row>

                {/* Post Travel */}
                <div className='w-100 bg-post-travel py-4 py-5px--xs overflow-hidden mt-6 rd-12px position-relative'>

                    <div className='w-50p w-80p--md w-80p--sm-g w-97p--vs shadow-slowclean-dark bg-white m-auto p-20px py-30px p-5px--xs py-20px--xs rd-12px position-relative z-1 d-flex justify-center align-items-center flex-column'>
                        <div className='Inter-Bold fs-22px fs-18px--xs fs-18px--sm text-center text_thmdark border-bottomw border-secondaryw'>Plan Smarter, Travel Better!</div>

                        <div className='Inter-Regular fs_14 text-secondary mt-2 text-center'>
                            Tell us your travel preferences, budget, and destination – get personalized trip proposals from trusted travel agencies!
                        </div>
                        <PostQueryCommon rowClass={'d-flex items-center justify-center mt-6'} className={'d-none'} />
                    </div>
                    <div className='blur p-3' />
                </div>


                {isModalVisible && (
                    <div className="modalOverlay" onClick={() => setModalVisible(false)}>
                        <div className="modalContent" onClick={e => e.stopPropagation()}>
                            <div style={{ width: '100%', height: '100%' }}>
                                <div style={{ width: '100%', height: '100%' }}>
                                    <iframe
                                        frameBorder="0"
                                        allowFullScreen
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerPolicy="strict-origin-when-cross-origin"
                                        title="Winter Spiti - An Unforgettable Journey"
                                        width="100%"
                                        height="100%"
                                        src={isModalVisible ? videoUrl : ""}
                                    />
                                </div>
                            </div>
                            <button className="closeButton" onClick={() => setModalVisible(false)}>Close</button>
                        </div>
                    </div>
                )}

                {/* Map */}
                <div className='w-100 text-center d-hide-on-sm'>
                    <div className='Inter-Bold fs_24 mt-5'>How it's works</div>

                    <div className='bg-warning1 w-100 mt-2 d_flex_align_justify_center'>
                        <img src='/images/F11.webp' className='img-fluid h-520px h-380px--md h-280px--xs-g w-100-md w_70' alt='World Map' />
                    </div>
                </div>

                <Helmet>
                    <meta charSet="UTF-8" />
                    <title>Travel Agency Near Me | Custom Made Holiday Plans | Travellinglark</title>
                    <meta name="description" content="Discover Top Travel Agencies Near You With Travellinglark! Get Custom-made Holiday Plans Tailored to Your Needs. Explore, Compare, and Book Your Dream Trip Today." />
                    <meta name="keywords" content="Travel Platforms in India, Custom Made Holiday Plans, Tour Operators Near Me, Travel Agency Near Me" />
                    <link rel="canonical" href="https://www.travellinglark.com/" />
                </Helmet>
            </div>

            <div className='w_92 bg-succes1s mb-5 d-flex align-items-center flex-column'>

                {/* Why Choose Section */}
                <Row className="bg-warning1 w-100">
                    <Col className='bg-1danger px-0'>
                        <img src={`/images/illus1.webp`} loading='lazy' alt='Why choose us' className='img-fluid h_400px h-lg-380px' />
                    </Col>
                    <Col xl={7} lg={7} md={6} className="bg1-warning  pb-2">
                        <div className='bg1-secondary'>
                            <div className='Inter-SemiBold fs-22px fs-20px--xs'>Why Choose TravellingLark?</div>
                            <div className='Inter-Medium fs_14 text_gray mt-2'>
                                TravellingLark makes trip planning effortless by connecting travelers with trusted vendors who offer customized travel offers. Simply post your travel details, receive tailored offers from experienced travel agencies, and choose the best fit for your journey—all in one place.
                            </div>

                            <Row className=''>
                                <Col sm={12} xl={6} className='p-2-5 rd_6 bg-white bg1-white w-75 w-xxs-99 mt-3'>
                                    <div className='Inter-SemiBold fs_15'>Post Your Travel Requirements</div>
                                    <div className='Inter-Regular fs_12 text-secondary'>Share your destination, budget, and preferences in a simple post, and let vendors come to you with the best travel offers.</div>
                                </Col>

                                <Col sm={12} md={6} xl={6} className='p-2-5 rd_6 bg1-danger w-75 w-xxs-99 mt-3'>
                                    <div className='Inter-SemiBold fs_15'>Receive Personalized offers</div>
                                    <div className='Inter-Regular fs_12 text-secondary'>Get multiple tailored offers from verified travel agencies, including complete itineraries, pricing, and exclusive deals.</div>
                                </Col>
                                <Col sm={12} md={6} xl={6} className='p-2-5 rd_6 bg1-danger bg1-white w-75 w-xxs-99 mt-3'>
                                    <div className='Inter-SemiBold fs_15'>Compare & Choose the Best Offer</div>
                                    <div className='Inter-Regular fs_12 text-secondary'>Easily compare vendor offers, read reviews, and pick the perfect package that matches your expectations.</div>
                                </Col>
                                <Col sm={12} md={6} xl={6} className='p-2-5 rd_6 bg1-danger bg1-white w-75 w-xxs-99 mt-3'>
                                    <div className='Inter-SemiBold fs_15'>Seamless Booking Experience</div>
                                    <div className='Inter-Regular fs_12 text-secondary'>Confirm your booking directly with the vendor, ensuring a smooth and hassle-free travel planning process.</div>
                                </Col>
                                <Col sm={12} md={6} xl={6} className='p-2-5 rd_6 bg1-danger bg1-white w-75 w-xxs-99 mt-3'>
                                    <div className='Inter-SemiBold fs_15'>Expert Travel Assistance</div>
                                    <div className='Inter-Regular fs_12 text-secondary'>From accommodation to transport and local guides, vendors on TravellingLark provide comprehensive solutions for a stress-free journey.</div>
                                </Col>
                            </Row>

                        </div>
                    </Col>
                </Row>

                {/* Review Section */}
                {testimonial && testimonial.length > 0 &&
                    <div className='d-flex align-items-center mt-7 w-100'>
                        <div className='Inter-Bold fs_20  flex-grow-1'>Our Clients Review</div>
                        <div className='bg-warning1 d-flex'>
                            <div onClick={() => handlePrev('review')} className={`mr_10 hw_30px pointer d-flex align-items-center justify-content-center rd_50 p-2 ${currentIndex4 === 0 ? 'border border-dark' : 'bg-gradient-dark'}`}>
                                <i className='fas fa-angle-left' style={{ color: currentIndex4 === 0 ? '#000' : '#fff' }}></i>
                            </div>
                            <div onClick={() => handleNext('review')} className={`mr_10 hw_30px pointer d-flex align-items-center justify-content-center rd_50 p-2 bg-gradient-dark`}>
                                <i className='fas fa-angle-right' style={{ color: '#fff' }}></i>
                            </div>
                        </div>
                    </div>}

                <div className='w-100'>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <Carousel
                            ref={carouselRef4}
                            afterChange={(previousSlide, { currentSlide }) => setCurrentIndex4(currentSlide)}
                            responsive={responsive_review}
                            swipeable={true}
                            draggable={true}
                            ssr={true}
                            infinite={true}
                            autoPlay
                            autoPlaySpeed={2500}
                            keyBoardControl={true}
                            customTransition="transform 1000ms ease-in-out"
                            containerClass="carousel-container mt-2"
                            itemClass="carousel-item1 "
                            customLeftArrow={
                                <CustomLeftArrow
                                    direction="left"
                                />
                            }
                            customRightArrow={
                                <CustomLeftArrow
                                    direction="right"
                                />
                            }
                        >
                            {testimonial && testimonial?.map((item, index) => {
                                return (
                                    <div key={item._id} className="reviews-box bg-white mb-4 mt-3 p-2-5 me-3 shadow-slowclean rd_10">
                                        <img src='/icons/review_user.png' alt={item.name || 'blogs'} className='rd_6 hw_55px' loading='lazy' />
                                        <h5 className='Source_SemiBold spacing m-0 mt-3'>{item.name}</h5>
                                        <div className='NunitoSans-Regular text_gray fs_12 text-center m-0 mt-2'>
                                            {item.message}
                                        </div>
                                    </div>
                                )
                            })}

                        </Carousel>
                    )}
                </div>



                {/* Blogs Section */}
                <div className='d-flex align-items-center mt-5 ms-1 w-100'>
                    <div className='Inter-Bold fs_20  flex-grow-1'>Blogs</div>
                    <div className='d-flex align-items-center pointer' onClick={() => navigate('/blogs')}>
                        <div className='Rubik-Regular text_thmdark fs_14'>View All</div>
                        <i className="fa-solid fa-chevron-right ms-2 fs_14 bg-gradient-thm hw_20px rd_50 text-white d-flex align-items-center justify-content-center"></i>
                    </div>
                </div>


                <div className='w-100'>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <Carousel
                            ref={carouselRef3}
                            afterChange={(previousSlide, { currentSlide }) => setCurrentIndex3(currentSlide)}
                            responsive={responsive_blog}
                            swipeable={true}
                            draggable={true}
                            ssr={true}
                            infinite={true}
                            autoPlay
                            autoPlaySpeed={2500}
                            keyBoardControl={true}
                            customTransition="transform 1000ms ease-in-out"
                            containerClass="carousel-container mt-2"
                            itemClass="carousel-item1 "
                            customLeftArrow={
                                <CustomLeftArrow
                                    direction="left"
                                />
                            }
                            customRightArrow={
                                <CustomLeftArrow
                                    direction="right"
                                />
                            }
                        // arrows={false}
                        >
                            {blogs && blogs?.map((item) => {
                                return (
                                    <div key={item._id} className="reviews-box h_280px bg-white mb-4 mt-3 d-flex flex-column mx-2 shadow-clean position-relative rd_10 hover_btt ">
                                        <Link to={`/blog/${item.slug}/${item.id}`} target='_blank' rel="noopener noreferrer" className='text-decoration-none hover-me d-flex justify-content-center flex-column'>
                                            <img src={`${img_base_url + item?.image}`} loading='lazy' alt={item?.name || 'blogs'} className='rd_tl_tr_6 w-100 h_165px pointer' />
                                            <h5 className='Source_SemiBold text_gray fs_15 px-15px fs-xs-14 spacing pointer m-0px mt-10px text-capitalize mini-line-1'>{item.title}</h5>
                                            <h5 className='Inter-Regular text-secondary fs_12 px-15px spacing pointer m-0 mt-2 text-capitalize mini-line-1 two'>{item.sortDescription}</h5>
                                            <p className='m-0 Source_Regular px-15px d-flex align-items-center mt-2-5 text_thm fs_14 pointer move_ltr pb-2'>Read More <i className='fa-solid fa-angle-right ms-2 fs_12'></i></p>
                                        </Link>
                                    </div>
                                )
                            })}

                        </Carousel>
                    )}
                </div>

                {/* Country Section */}
                <div className='Inter-Bold fs-22px fs-18px--xs fs-18px--sm mt-3 text-center'>Explore Popular Countries to Visit</div>

                <div className='Inter-Regular fs_14 text-secondary mt-2 text-center'>
                    Discover countries with rich cultures, scenic views, and unforgettable experiences.
                </div>

                <div className='w-100 mt-4'>
                    {isLoading ? (
                        <Loader type={'country'} />
                    ) : (
                        <Carousel
                            swipeable={true}
                            draggable={true}
                            responsive={responsive_8}
                            ssr={true}
                            autoPlay
                            infinite
                            autoPlaySpeed={2500}
                            keyBoardControl={true}
                            customTransition="transform 1000ms ease-in-out"
                            containerClass="carousel-container"
                            itemClass="carousel-item1"
                            customLeftArrow={
                                <CustomLeftArrow
                                    direction="left"
                                    mt={'mt-30px'}
                                />
                            }
                            customRightArrow={
                                <CustomLeftArrow
                                    direction="right"
                                    mt={'mt-30px'}
                                />
                            }
                            dotListClass="custom-dot-list-style"
                        >
                            {countries && countries?.map((item, index) => {

                                return (
                                    <div key={index} className="mb-4 position-relative bg-white rd_30 mx-2-5 ">
                                        <Link to={`/country/${item.slug}/${item.id}`} target='_blank' rel="noopener noreferrer" className='text-decoration-none w-100'>
                                            <img src={img_base_url + item.image} loading='lazy' alt={item.name || 'blogs'} className='rd_15 w-100 h_250px h-xxs-200px pointer' />
                                            <div className='rd_15 p-3 pb-2-5 h-100 w-100 hoverthis d-flex position-absolute flex-column align-items-center justify-content-end pointer' style={{
                                                top: 0,
                                                background: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,0),rgba(255,255,255,0), rgba(41, 38, 38,0.9)) ',
                                            }}>
                                                <div className='NunitoSans-Medium fs_15 text-white text-capitalize px-1 text-center'>{item.name}</div>
                                            </div>
                                        </Link>
                                    </div>
                                )
                            })}

                        </Carousel>
                    )}
                </div>

                <div className='Inter-Bold fs-22px fs-20px--xs flex-grow-1 mt-50px w-95p text-center'>Frequently Asked Questions</div>

                <FaqSection />


                <p className='fs-13px Inter-Regular mt-70px'>
                    Discover customized holiday plans, unique travel packages, and expert travel agencies near you with TravellingLark. Connect with experienced tour operators to craft personalized travel itineraries tailored to your preferences. Find local travel agencies offering exclusive deals on hotels, travel vehicles, and guides. Post your travel leads and receive competitive offers from top vendors. Explore bespoke holiday ideas, group tours, family vacations, and honeymoon packages curated by professional travel planners. Whether you seek adventure tours, luxury escapes, or budget-friendly trips, our platform helps you find the perfect travel solution. Search for travel services, tour operators, and holiday planners near you to make your journey unforgettable.
                    Discover top travel platforms in India to plan your dream vacations effortlessly. Explore custom-made holiday plans tailored to your preferences. Find reliable tour operators near me and trusted travel agencies near me for seamless travel experiences.
                </p>
            </div>

            {/* <GoogleTranslate/>
            </> */}


            {/* Modal Sigin */}
            <SignInModal isOpen={isModalOpen} onClose={setIsModalOpen} redirect={redirectionUrl ? redirectionUrl : ''} />

            <HowItWork isVisible={open} onClose={setopen} />
        </div>
    )
}

export default Home