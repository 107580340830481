import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { Overview } from './Overview'
import { tstError, tstSuccess } from '../../Componets/HotToast';
import { enc } from '../../Componets/SecRes';
import { CommonAPI } from '../../Componets/CommonAPI';
import { useSelector } from 'react-redux';
import Settings from './Settings';
import MyQueries from './MyQueries';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import STicket from '../Ticket/STicket';

const key = process.env.REACT_APP_KEY;
const vendor_url = process.env.REACT_APP_VENDOR_URL;
const vendor_signup_url = process.env.REACT_APP_VENDOR__SIGNUP_URL;

function Profile() {
  const location = useLocation();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [conPassword, setConPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [err, setError] = useState({})
  const [active, setActive] = useState(location.state || 'profile');

  const user = useSelector(state => state.data.info.user)
  const user1 = useSelector(state => state.data.info)
  

  useEffect((item)=>{
    if (!user1?.tkn) {
      navigate('/not-found', { replace: true });
      return
    }
  },[])


  const val = () => {
    const err = {};
    if (password !== conPassword) {
      err.pass = "Password doesn't match!"
    }

    setError(err)
    return Object.keys(err).length === 0
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      userId: user && user._id,
      newPassword: password,
    };
    const enc1 = enc(data, key)

    if (val()) {
      setIsLoading(true)
      CommonAPI(enc1, 'POST', 'resetPassword', responseBack)
    }
  }

  const responseBack = useCallback(res => {
    if (res.status === true) {
      setConPassword('')
      setPassword('')
      tstSuccess('Password Changed Successfully')
    } else {
      tstError('Something went wrong!')
    }
    setIsLoading(false)
  })

  const serv = user1 && user1?.user?.services


  return (
    <div className='min-vh-100 mt-12 pt-2 pb-5 bg-light' style={{ backgroundColor: '#eef4fd' }}>
      <Container className='bg-warning1'>
        <Row className='bg-primary1 mt-4'>
          <Col lg={3} className='bg-primary1'>
            <p className='Rubik-Medium fs_16 mt-2 m-0'>Profile</p>

            <div className={`rd_6 bg-white shadow-slowclean p-3 mt-2 mb-40px`}>
              <label className={`Source_Regular pointer mb-2 fs_14 d-flex align-items-center ${active == 'profile' ? 'text_thmdark Source_Medium' : 'text_gray'}`} onClick={() => { setActive('profile'); }}>
                <i className="fa-regular fa-user fs_13 mr_10"></i>
                Profile
              </label>
              {/* <label className={`Source_Regular pointer p-0 mb-2 fs_14 d-flex align-items-center ${active == 'settings' ? 'text_thmdark Source_Medium' : 'text_gray'}`} onClick={() => setActive('settings')}>
                <i className="bi bi-gear fs_13 mr_10"></i>
                Notifications
              </label> */}
              <label className={`Source_Regular pointer p-0 mb-2 fs_14 d-flex align-items-center ${active == 'pass' ? 'text_thmdark Source_Medium' : 'text_gray'}`} onClick={() => setActive('pass')}>
                <i className="bi bi-shield-lock fs_13 mr_10"></i>
                Change Password
              </label>
              <label className={`Source_Regular pointer p-0 fs_14 d-flex align-items-center ${active == 'my_queries' ? 'text_thmdark Source_Medium' : 'text_gray'}`} onClick={() => setActive('my_queries')}>
                <i className="bi bi-file-post fs_13 mr_10"></i>
                My Travels
              </label>
              {user?.role == 'vendor' && (
                <label className={`Source_Regular pointer p-0 fs_14 d-flex align-items-center ${active == '/my-proposals' ? 'text_thmdark Source_Medium' : 'text_gray'}`} onClick={() => setActive('/my-proposals')}>
                  <i className="bi bi-file-post fs_13 mr_10"></i>
                  My Offers
                </label>
              )}
              <label className={`Source_Regular pointer p-0 fs_14 d-flex align-items-center ${active == 'my_ticket' ? 'text_thmdark Source_Medium' : 'text_gray'}`} onClick={() => setActive('my_ticket')}>
                <i className="bi bi-ticket-perforated mr_10"></i>
                My Tickets
              </label>
            </div>

            {user?.role !== 'user' && (
              <>
                <p className='Rubik-Medium fs_16 mt-30px m-0px'>Manage Services</p>
                <div className={`rd_6 bg-white shadow-slowclean p-3 mt-10px`}>
                  {serv?.includes('packages') && <Link to={`${vendor_url}redirect/view-packages/${user1?.tkn}`} target='_blank' rel="noopener noreferrer" className={`Source_Regular text_gray pointer mb-2 fs_14 d-flex align-items-center`}>
                    <i className="bi bi-box-seam fs_13 mr_10"></i>
                    Packages
                  </Link>}

                  {serv?.includes('hotels') && <Link to={`${vendor_url}redirect/hotel/${user1?.tkn}`} target='_blank' rel="noopener noreferrer" className={`Source_Regular text_gray pointer p-0 mb-2 fs_14 d-flex align-items-center `} onClick={() => setActive('settings')}>
                    <i className="bi bi-building fs_13 mr_10"></i>
                    Hotels
                  </Link>}

                  <Link to={`${vendor_url}redirect/view-leads/${user1?.tkn}`} target='_blank' rel="noopener noreferrer" className={`Source_Regular text_gray pointer p-0 mb-2 fs_14 d-flex align-items-center`} onClick={() => setActive('pass')}>
                    <i className="bi bi-card-checklist fs_13 mr_10"></i>
                    Leads
                  </Link>
                </div>
              </>
            )}
          </Col>
          <Col>
            {active == 'settings' ? (
              <Settings />
            ) : active == 'profile' ? (
              <>
                <Row className='' style={{ marginTop: '2.5rem' }}>
                  <Col lg={9} className='bg-warning1'>
                    <div className='d-flex rd_6 p-3 bg-white shadow-slowclean'>
                      <div className='border_gray bg-warning1 br-2 hw_80px rd_50 p-1'>
                        <img src='/icons/blank_profile.png' alt='User Profile' className='rd_50 img-fluid' />
                      </div>
                      <div className='d-flex justify-content-center flex-column ms-3'>
                        <p className='fw-bold fs_18 m-0 text-capitalize'>{user && user.first_name || ''} {user?.last_name || ''}</p>
                        <p className='Source_Regular text_gray fs_14 m-0'>{user && user.email || ''}</p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Overview user={user && user || null} />
              </>
            ) : (active == 'my_queries' || active == '/my-proposals') ? (
              <MyQueries type={'FT'} role={active}/>
            ) : active == 'my_ticket' ? (
              <STicket type={'TL'} />
            ) : (
              <Container className='bg-primary1' style={{ marginTop: '1rem' }}>
                <Row>
                  <Col lg={9}>
                    <div className='rd_6 p-3 bg-white shadow-slowclean mt-4'>
                      <h3 className='fw-bolder fs_16 m-0 flex-grow-1'>Change Password</h3>

                      <form onSubmit={handleSubmit}>
                        <div className='d-flex mt-3'>
                          <div className="form-group mr_10 flex-grow-1">
                            <label className='Source_SemiBold fs_14 text-muted mb-1'>New Password</label>
                            <div className='d-flex align-items-center bg-warning1 position-relative'>
                              <input type={'text'} className="form-control fs_13 h_35px" placeholder="Enter your password" minLength={5} value={password} onChange={(event) => setPassword(event.target.value)} />
                            </div>
                          </div>
                          <div className="form-group mr_10 flex-grow-1">
                            <label className='Source_SemiBold fs_14 text-muted mb-1'>Confirm Password</label>
                            <div className='d-flex align-items-center bg-warning1 position-relative'>
                              <input type={'password'} className="form-control fs_13 h_35px" placeholder="Enter your password" value={conPassword} onChange={(event) => setConPassword(event.target.value)} />
                            </div>
                          </div>
                        </div>
                        {err.pass && <p className='fs_13 mb-2 pt-0 mt-0 text-danger'>{err.pass}</p>}

                        <div className='d-flex justify-content-end'>
                          <button className='btn bg_thm text-white fs_14 mt-5'>
                            {isLoading ? <Spinner size='sm' /> : 'Change Password'}
                          </button>
                        </div>
                      </form>
                    </div>
                  </Col>
                </Row>
              </Container>
            )}
          </Col>
        </Row>
      </Container>

    </div>
  )
}

export default Profile