import { useContext } from "react";
import { useDispatch } from "react-redux";
import { svChatCountStatus, svInfo, svMsgId, svReceiverDetails, svTripCate } from "../ContextRedux/Action";
import { googleLogout } from "@react-oauth/google";
import { tstError } from "./HotToast";

const LogOut = async (logout, dispatch) => {
    try {
        dispatch(svInfo({}))
        dispatch(svTripCate([]))
        dispatch(svMsgId({}))
        dispatch(svReceiverDetails({}))
        dispatch(svChatCountStatus(''));

        await logout()
        await googleLogout()
        window.location.href = '/';
    } catch (error) {
        tstError('Something went wrong!')
    }
}

export default LogOut


