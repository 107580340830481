import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { MemContext } from '../../../ContextRedux/Context';
import { CommonAPI } from '../../../Componets/CommonAPI';
import { CountriesDrop, CurrencyDrop } from '../../../Componets/Countries';
import { useLocation, useNavigate } from 'react-router-dom';
import { dec, enc } from '../../../Componets/SecRes';
import { customStyle, dateFormat, lookingForOptions, useCountryGet } from '../../../Componets/CommonFs';
import SignInModal from '../../Auth/SignInModal';
import { Checkbox, DatePicker, Modal } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Counter from '../../Hotels/Counter';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';

const key = process.env.REACT_APP_KEY;


function PostQueryCommon({ editItem, className, rowClass, lg, md }) {
    const { userInfo } = useContext(MemContext)
    const navigate = useNavigate();


    const currencyId = userInfo?.countryId?.currencyId?._id

    const [checkedOptions, setCheckedOptions] = useState(editItem?.looking_for || ['Holiday Packages']);
    const [desc, setDesc] = useState(editItem?.description || '');
    const [country1, setCountry] = useState(editItem?.queryCountry[0]?._id || '');
    const [currency, setCurrency] = useState(editItem?.currency?._id || currencyId);
    const [budget, setBudget] = useState(String(editItem?.budget) || '');
    const [pickupCity, setPickupCity] = useState(editItem?.pickupCity || '');
    const [selectedDate, setSelectedDate] = useState('');
    const [autoSubmit, setAutoSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [err, setError] = useState({})
    const [isModalOpen, setIsModalOpen] = useState(false);
    const today = moment();
    const minDate = today.clone().add(0, 'days');
    const [checked, setChecked] = useState(editItem?.isAgree || true)




    const [dates, setDates] = useState([]);

    useEffect(() => {
        if (dates?.length === 0) {
            const currentDate = new Date();
            const nextDate = new Date()
            nextDate.setDate(currentDate.getDate() + 1)
            const parsedDate = dayjs(nextDate || null)
            const dateJson = parsedDate ? parsedDate.toDate() : null;
            const formated = dateFormat(dateJson, 'dmy')

            setSelectedDate(formated.replace(/\//g, '-'))
            setDates(parsedDate)
        }
    }, [dates?.length === 0]);

    useEffect(() => {
        if (editItem?.travelDate) {
            const date = editItem?.travelDate;

            const parsedDate = dayjs(date || null);

            const dateJson = parsedDate ? parsedDate.toDate() : null;
            const formated = dateFormat(dateJson, 'dmy')

            setSelectedDate(formated.replace(/\//g, '-'))
            setDates(parsedDate);
        }
    }, []);

    const handleDate = (date) => {
        const dateJson = date ? date.toDate() : null;
        const formated = dateFormat(dateJson, 'dmy')
        setSelectedDate(formated.replace(/\//g, '-'))
        setDates(date);
    };
    const user = useSelector(reducer => reducer.data?.info)


    const checkCurr = currency == '66e3fb6dbee3abe2875109a5' ? 1000 : 500

    const val = () => {
        const err = {};
        if (desc.trim().length <= 49) {
            err.desc = "Enter minimum 50 characters!"
        }
        if ((Number(budget) < checkCurr || isNaN(budget))) {
            err.bugt = `Enter a valid budget minimum ${checkCurr}!`
        }
        if (selectedDate == '') {
            err.date = 'Invalid date!'
        }
        if (!country1) {
            err.country = "Select valid country!"
        }
        if (!currency) {
            err.currency = "Select valid currency!"
        }

        if (!pickupCity) {
            err.pickup = "Enter valid pickup city/state!"
        }

        if (checkedOptions.length == 0) {
            err.for = "Please select at least one!"
        }

        setError(err)
        return Object.keys(err).length === 0
    }

    useEffect(() => {
        if (autoSubmit) {
            handleSubmit()
        }
    }, [autoSubmit])

    const handleSubmit = (e = null) => {
        if (e) {
            e.preventDefault();
        }

        const data = {
            description: desc,
            currency: currency,
            pickupCity: pickupCity,
            budget: Number(budget),
            countryId: country1,
            userId: userInfo?._id,
            looking_for: checkedOptions,
            id: editItem?._id || '',
            travelDate: selectedDate,
            isAgree:checked
        };


        const enc1 = enc(data, key)

        if (val()) {
            CommonAPI(enc1, 'POST', 'rawPostData', responseBack1)

            if (!user?.tkn) {
                setIsModalOpen(true)
                return
            }

            setAutoSubmit(false)

            setIsLoading(true)
            CommonAPI(enc1, 'POST', 'createQuery', responseBack)
        }
        // setIsLoading(false)
    }

    const responseBack1 = useCallback(res => {
        if (res.status === true) {

        } else {
            if ((res.message == 'Invalid token' || res.message == 'No token provided')) {
                setIsModalOpen(true)
            }
            setError({ submit: 'Something went wrong!' })
        }
        setIsLoading(false)
    })

    const responseBack = useCallback(res => {
        if (res.status === true) {
            setDesc('')
            setCurrency('')
            setCountry('')
            setSelectedDate('')
            setBudget('')
            setDates('')
            setCheckedOptions([])
            toast.success('Travel Lead Submitted Successfully')
            const dec1 = dec(res.data, key)

            navigate(`/travel-lead/details/${dec1.id}`)
        } else {
            if ((res.message == 'Invalid token' || res.message == 'No token provided')) {
                setIsModalOpen(true)
            }
            setError({ submit: 'Something went wrong!' })
        }
        setIsLoading(false)
    })

    const handleAutoSubmit = (rrr) => {
        setAutoSubmit(true)
    }




    return (
        <>
            <Row className={`bg-warning2 w-100 position-relative ${rowClass}`}>
                <Col md={6} lg={5} className={`h_350px query-right-img pt-0 ${className}`}>
                    <div className='d-flex justify-content-center'>
                        <img src='https://img.freepik.com/free-vector/gradient-world-tourism-day-illustration_52683-129641.jpg?t=st=1743317941~exp=1743321541~hmac=b4bb77af6d80efcea757071e75532e1deafb0475c665c7ab989d5f4da9b62060&w=996' className='img-fluid rd_6' alt='Man with bag rock' />
                    </div>
                </Col>
                <Col md={md} lg={lg} className='bg-success1'>
                    <div className='scrollable-content position-relative'>
                        <form onSubmit={handleSubmit} className=''>

                            <div className="form-group mb-3 position-relative">
                                <label className='Rubik-Medium fs_13 text-muted mb-1'>Describe Your Travel Plans <span className='Rubik-Regular gradient-text'>(For Travelers)</span></label>
                                <textarea
                                    name="description"
                                    placeholder='Provide details like  destination, no of travelers, hotel/stay type, accommodation type, activities, and any special requirements.'
                                    rows={4} className="form-control mb-2 fs_13 h_38px rd_10 border_gray Rubik-Regular text_dark_medium"
                                    value={desc}
                                    onChange={(event) => {
                                        if (event.target.value.length <= 1500) {
                                            setDesc(event.target.value);
                                        }
                                    }} />

                                <div className='d-flex mt-1'>
                                    {err.desc && <p className='fs_13 mb-2 pt-0 text-danger flex-grow-1'>{err.desc}</p>}
                                    <p className='flex-grow-1' />

                                    <p className='Rubik-Regular text_gray2 text-end fs_11 m-0'>{1500 - desc?.trim()?.length} characters left</p>
                                </div>
                            </div>

                            <Row className='d-flex bg-warning1 position-relative'>
                                <Col xl={4} className="form-group flex-grow-1">
                                    <label className='Rubik-Medium fs_13 text-muted mb-1'>Your Country</label>
                                    <CountriesDrop remove={'allCountry'} onChange={setCountry} currencyUpdate={setCurrency} selected={country1} />
                                    {err.country && <p className='fs_13 mb-2 pt-0 mt-1 text-danger'>{err.country}</p>}
                                </Col>

                                <Col xl={4} className="form-group">
                                    <label className='Rubik-Medium fs_13 text_gray mb-1'>Pickup City/State</label>
                                    <div className="form-group  mb-2 fs_13 h_38px rd_10 border_gray Rubik-Regular text_dark_medium overflow-hidden mb-1 flex-grow-1">
                                        <input type="text" placeholder="Enter city/state" className="form-control outline-0 border-0"
                                            value={pickupCity}
                                            onChange={(event) => {
                                                const value = event.target.value
                                                setPickupCity(value)
                                            }} />

                                    </div>
                                    {err.pickup && <p className='fs_13 mb-2 pt-0 mt-1 text-danger'>{err.pickup}</p>}
                                </Col>

                                <Col xl={4} className="form-group">
                                    <label className='Rubik-Medium fs_13 text_gray mb-1'>Budget</label>
                                    <div className="form-group mb-1 flex-grow-1">
                                        <div className='d-flex mb-2 fs_13 h_38px rd_10 border_gray Rubik-Regular text_dark_medium overflow-hidden'>
                                            <CurrencyDrop onChange={setCurrency} selected={currency} type={'simple'} />
                                            <input type="number" name="budget" placeholder={checkCurr} maxLength={6} className="form-control outline-0 border-0"
                                                value={budget}
                                                onChange={(event) => {
                                                    const value = event.target.value
                                                    if (/^\d{0,6}$/.test(value)) {
                                                        setBudget(value)
                                                    }
                                                }} />
                                        </div>

                                        {err.bugt && <p className='fs_13 mb-2 pt-0 mt-1 text-danger'>{err.bugt}</p>}
                                    </div>
                                    {err.currency && <p className='fs_13 mb-2 pt-0 mt-1 text-danger'>{err.currency}</p>}
                                </Col>

                                <Col className="form-group mb-3 flex-grow-1">
                                    <label className='Rubik-Medium fs_13 text-muted mb-1'>Expected Travel Date</label>
                                    <DatePicker
                                        className='border_gray w-100 flex-grow-1 rd_10 outline-none text_gray fs_14 h_38px m-0'
                                        onChange={handleDate}
                                        value={dates || null}
                                        format={'DD-MM-YYYY'}
                                        disabledDate={(current) => current && current < minDate}
                                    />
                                    {err.date && <p className='fs_13 mb-2 pt-0 mt-1 text-danger'>{err.date}</p>}
                                </Col>
                            </Row>

                            <div className='d-flex align-items-start justify-center'>
                                <Checkbox checked={checked}
                                    onChange={() => setChecked(!checked)} className='fs_14 Source_Regular'>
                                </Checkbox>
                                <label onClick={() => setChecked(!checked)} className='Rubik-Regular fs-12px text_gray mb-1 ms-2'>By selecting this option, you agree to share your personal information with travel agencies who can send offers based on your travel requirements.</label>
                            </div>

                            <p className='Rubik-Regular fs-10px m-0 p-0 text-secondary text-start mt-2 mb-2'>We will contact you via Email or WhatsApp as you receive offers. Please check your email regularly.</p>
                            <div className='d-flex justify-center'>
                                <button type="submit" className="border-0 py-2 bg-gradient-thm w-90p--sm-g rd-12px text-white px-5 btn-block mt-3" disabled={isLoading}>
                                    {isLoading ? <Spinner size='sm' /> : editItem?._id == undefined ? 'Submit' : 'Edit Travel Lead'}
                                </button>
                                {err.submit && <p className='fs_13 mb-2 pt-0 mt-1 text-danger'>{err.submit}</p>}
                            </div>
                        </form>
                    </div>
                </Col>

            </Row>

            {/* Modal Sigin */}
            <SignInModal isOpen={isModalOpen} onClose={setIsModalOpen} action={handleAutoSubmit} />
        </>
    )
}

export default PostQueryCommon