import React from 'react'
import ContentLoader from 'react-content-loader'
import Carousel from 'react-multi-carousel';
import { CustomLeftArrow } from './CustomArrow';



const Loader = ({ type, mt }) => {
    const margin = mt || ''
    const length = type == 'pkg' ? 4 : type == 'category' ? 6 : 5

    const lengthIs = Array.from({ length: length })

    const responsive = {
        desktop: {
            breakpoint: { max: 2000, min: 1024 },
            items: length,
            slidesToSlide: length
        },

        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 2,
            slidesToSlide: 2
        },
    };

    return (
        <Carousel
            swipeable={true}
            draggable={true}
            responsive={responsive}
            ssr={true}
            autoPlaySpeed={2500}
            keyBoardControl={true}
            customTransition="transform 1000ms ease-in-out"
            containerClass={`carousel-container gap-3 ${margin}`}
            itemClass="carousel-item1 gap-3"
            customLeftArrow={
                <CustomLeftArrow
                    direction="left"
                />
            }
            customRightArrow={
                <CustomLeftArrow
                    direction="right"
                />
            }
            removeArrowOnDeviceType={["desktop", "mobile"]}
            dotListClass="custom-dot-list-style"
        >
            {lengthIs.map((it, index) => (
                // Packages Img, Title, Subtitle
                type == 'pkg' ? (
                    <ContentLoader
                        key={index}
                        speed={2}
                        width='auto'
                        height={270}
                        viewBox="0 0 258 auto"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                        className='bg-warning1 p-0 gap-3'
                    >
                        <rect x="0" y="8" rx="3" ry="3" width="90%" height="150" />
                        <rect x="0" y="170" rx="3" ry="3" width="90%" height="10" />
                        <rect x="0" y="195" rx="3" ry="3" width="45%" height="10" />
                        {/* <circle cx="10" cy="200" r="10" /> */}

                        <rect x="0" y="235" rx="3" ry="3" width="40%" height="15" />
                        <rect x="0" y="255" rx="3" ry="3" width="25%" height="10" />
                        <rect x="60%" y="235" rx="14" ry="14" radius="100" width="30%" height="30" />
                    </ContentLoader>
                ) : type == 'country' ? (
                    <ContentLoader
                        key={index}
                        speed={2}
                        width='auto'
                        height={299}
                        viewBox="0 0 258 auto"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                        className='bg-warning1p-0 gap-3'
                    >
                        <rect x="0" y="8" rx="10" ry="10" width="90%" height="250" />
                    </ContentLoader>
                ) : type == 'category' ? (
                    <ContentLoader
                        key={index}
                        speed={2}
                        width='auto'
                        height={180}
                        viewBox="0 0 258 auto"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                        className='bg-warning1p-0 gap-3'
                    >
                        <rect x="0" y="8" rx="10" ry="10" width="90%" height="150" />
                        <rect x="0" y="170" rx="3" ry="3" width="90%" height="15" />
                    </ContentLoader>
                ) : (
                    <ContentLoader
                        key={index}
                        speed={2}
                        width='auto'
                        height={299}
                        viewBox="0 0 258 auto"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                        className='bg-warning1p-0 gap-3'
                    >
                        <rect x="0" y="8" rx="3" ry="3" width="90%" height="150" />
                        <rect x="0" y="170" rx="3" ry="3" width="90%" height="15" />

                        <rect x="0" y="195" rx="3" ry="3" width="40%" height="10" />
                        <rect x="75%" y="195" rx="3" ry="3" width="15%" height="10" />

                        <rect x="0" y="215" rx="3" ry="3" width="40%" height="10" />
                        <rect x="70%" y="215" rx="3" ry="3" width="20%" height="10" />
                    </ContentLoader>
                )
                // Vehicle Title and Subtitles(left right)
            ))}
        </Carousel>
    )
}

const SingleLoader = () => {
    return (
        <ContentLoader
        speed={2}
        width='auto'
        height={299}
        viewBox="0 0 258 auto"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        className='bg-warning1 p-0 gap-3'
    >
        <circle cx="50%" cy="58" r="40" />
        <rect x="29%" y="120" rx="3" ry="3" width="40%" height="14" />
        <rect x="29%" y="145" rx="3" ry="3" width="40%" height="10" />

        <rect x="0" y="195" rx="3" ry="3" width="40%" height="10" />
        <rect x="85%" y="195" rx="3" ry="3" width="15%" height="10" />

        <rect x="0" y="215" rx="3" ry="3" width="40%" height="10" />
        <rect x="80%" y="215" rx="3" ry="3" width="20%" height="10" />

        <rect x="0" y="255" rx="3" ry="3" width="100%" radius="50" height="35" />
    </ContentLoader>
    )
}

export { SingleLoader, Loader, }